<template>
    <div class="page organisation-kvk-request">
        <div class="container mx-auto">
            <calculation-tool-header/>
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <BackLinkButton :href="backRoute()"/>
                </div>

                <h1
                    class="text-center text-pso-dark text-2xl font-bold mb-10"
                >
                    {{ translate('organisation.kvk.title') }}
                </h1>
                <div id="show-kvk-data">
                    <div class="rounded-xl mb-10 p-8 bg-gray-050">
                        <div class="relative w-full h-full p-4">
                            <div class="absolute top-4 right-4">
                                <EditIcon @click="toggleEditing"/>
                            </div>
                            <div class="mt-12">
                                <div class="flex mb-4">
                                    <div class="w-1/3">
                                        <span class="text-md text-pso-dark">
                                            {{ translate('organisation.kvk.kvk') }}
                                        </span>
                                    </div>
                                    <div class="w-2/3">
                                        <TextInput
                                            v-if="editing"
                                            id="kvk"
                                            :model-value="organisation.kvk"
                                            name="kvk"
                                            :errors="errors['kvk'] ? errors['kvk'][0] : null ?? null"
                                            placeholder="Enter Chamber of Commerce number"
                                            class="rounded-xl border-gray-200"
                                            @update:modelValue="(value) => organisation.kvk = value"
                                        />
                                        <span
                                            v-else
                                            class="text-lg text-pso-dark"
                                        >
                                            {{ organisation.kvk }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                    <div class="w-1/3">
                                        <span class="text-md text-pso-dark">
                                            {{ translate('organisation.kvk.company_name') }}
                                        </span>
                                    </div>
                                    <div class="w-2/3">
                                        <TextInput
                                            v-if="editing"
                                            id="company_name"
                                            :model-value="organisation.company_name"
                                            name="company_name"
                                            :errors="errors['company_name'] ? errors['company_name'][0] : null ?? null"
                                            placeholder="Enter company name"
                                            class="rounded-xl border-gray-200"
                                            @update:modelValue="(value) => organisation.company_name = value"
                                        />
                                        <span
                                            v-else
                                            class="text-lg text-pso-dark"
                                        >
                                            {{ organisation.company_name }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                    <div class="w-1/3">
                                        <span class="text-md text-pso-dark">
                                            {{ translate('organisation.kvk.address') }}
                                        </span>
                                    </div>
                                    <div class="w-2/3">
                                        <span
                                            v-if="editing"
                                            class="flex flex-wrap"
                                        >
                                            <TextInput
                                                id="street_name"
                                                :model-value="organisation.street_name"
                                                name="street_name"
                                                :errors="errors['street_name'] ? errors['street_name'][0] : null ?? null"
                                                placeholder="Enter street name"
                                                class="rounded-xl border-gray-200"
                                                @update:modelValue="(value) => organisation.street_name = value"
                                            />
                                            <TextInput
                                                id="street_number"
                                                :model-value="organisation.street_number"
                                                name="street_number"
                                                :errors="errors['street_number'] ? errors['street_number'][0] : null ?? null"
                                                placeholder="Enter street number"
                                                class="rounded-xl border-gray-200"
                                                @update:modelValue="(value) => organisation.street_number = value"
                                            />
                                            <TextInput
                                                id="addition"
                                                :model-value="organisation.addition"
                                                name="addition"
                                                :errors="errors['addition'] ? errors['addition'][0] : null ?? null"
                                                placeholder="Enter addition"
                                                class="rounded-xl border-gray-200"
                                                @update:modelValue="(value) => organisation.addition = value"
                                            />
                                            <TextInput
                                                id="post_code"
                                                :model-value="organisation.post_code"
                                                name="post_code"
                                                :errors="errors['post_code'] ? errors['post_code'][0] : null ?? null"
                                                placeholder="Enter post code"
                                                class="rounded-xl border-gray-200"
                                                @update:modelValue="(value) => organisation.post_code = value"
                                            />
                                            <TextInput
                                                id="city"
                                                :model-value="organisation.city"
                                                name="city"
                                                :errors="errors['city'] ? errors['city'][0] : null ?? null"
                                                placeholder="Enter city"
                                                class="rounded-xl border-gray-200"
                                                @update:modelValue="(value) => organisation.city = value"
                                            />
                                        </span>
                                        <span
                                            v-else
                                            class="text-lg text-pso-dark"
                                        >
                                            {{
                                                [
                                                    organisation.street_name,
                                                    organisation.street_number,
                                                    organisation.addition,
                                                    organisation.post_code,
                                                    organisation.city
                                                ].join(" ")
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                    <div class="w-1/3">
                                        <span class="text-md text-pso-dark">
                                            {{ translate('organisation.kvk.sbi_codes') }}
                                        </span>
                                    </div>
                                    <div class="w-2/3">
                                        <div  v-if="editing">
                                            <div
                                                v-for="(item, index) in organisation.sbi_codes"

                                                :key="index"
                                                class="mb-2"
                                            >
                                                <div class="flex items-center space-x-2">
                                                    <TextInput
                                                        :id="'sbi_key_' + index"
                                                        :name="'sbi_codes_key' + index"
                                                        :model-value="item.key"
                                                        type="text"
                                                        :placeholder="translate('organisation.edit.enter_sbi_code_key')"
                                                        class="rounded-xl border-gray-200 flex-1"
                                                        @update:modelValue="(value) => organisation.sbi_codes[index].key = value"
                                                    />
                                                    <TextInput
                                                        :id="'sbi_value_' + index"
                                                        :name="'sbi_codes_value' + index"
                                                        :model-value="item.value"
                                                        type="text"
                                                        :placeholder="translate('organisation.edit.enter_sbi_code_value')"
                                                        class="rounded-xl border-gray-200 flex-1"
                                                        @update:modelValue="(value) => organisation.sbi_codes[index].value = value"
                                                    />
                                                    <button
                                                        class="bg-white text-red-500  px-2 py-1 rounded-xl flex items-center"
                                                        @click="removeSbiCode(index)"
                                                    >
                                                        <img :src="iconDelete" alt="delete" class="w-4 h-4 hover:cursor-pointer">

                                                    </button>

                                                </div>
                                            </div>
                                            <div v-if="errors['sbi_codes']" class="text-red-500 text-sm">
                                                <div v-if="Array.isArray(errors['sbi_codes'])">
                                                    <div v-for="(error, index) in errors['sbi_codes']" :key="index">
                                                        {{ error }}
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    {{ errors['sbi_codes'] }}
                                                </div>
                                            </div>
                                            <button
                                                class="border-blue-800 bg-blue-1000 inline-block py-3 px-8 rounded-xl font-medium text-white"
                                                @click="addSbiCode"
                                                >
                                                {{ translate('organisation.edit.add_sbi_code') }}
                                            </button>
                                        </div>
                                        <div
                                            v-else
                                        >
                                            <ul class="list-none m-0 p-0">
                                                <li
                                                    v-for="(item, index) in organisation.sbi_codes"
                                                    :key="index"
                                                    class="flex items-center"
                                                >
                                                    <span class="mr-4 text-lg text-pso-dark">
                                                        {{ item.key }}
                                                    </span>
                                                    <span class="text-md text-pso-dark font-light">
                                                        {{ item.value }}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                    <div class="w-4/12">
                                        <span class="text-md text-pso-dark">
                                            {{ translate('organisation.summary.details.branch') }}
                                        </span>
                                    </div>
                                    <div class="w-8/12">
                                        <SelectInput
                                            v-if="editing"
                                            id="branch"
                                            :model-value="organisation.branch_id"
                                            name="branchName"
                                            :errors="errors['branch_id'] ? errors['branch_id'][0] : null ?? null"
                                            placeholder="Enter branch name"
                                            :options="branches"
                                            @update:modelValue="(value) => organisation.branch_id = value"
                                        />
                                        <span
                                            v-else
                                            class="text-lg text-pso-dark"
                                        >
                                            {{ organisation.branch_name }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    v-if="editing"
                                    class="flex align-items-center gap-4"
                                >
                                    <BaseButton
                                        class="flex-grow-0"
                                        @click="saveChanges"
                                    >
                                        {{ translate('organisation.edit.save_button_text') }}
                                    </BaseButton>
                                    <button @click="toggleEditing">
                                        {{ translate('organisation.edit.cancel_button_text') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="more-entities">
                        <p
                            class="mb-6 text-lg text-pso-dark"
                        >
                            {{ translate('organisation.kvk.more_entities_question.text') }}
                        </p>

                        <div class="more-entities-question-wrapper">
                            <div class="questions flex items-center justify-content-center w-1/3 mx-auto mb-6">
                                <div
                                    class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-l mr-4"
                                    :class="addMoreEntities === '1' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                                >
                                    <label
                                        for="confirmationYes"
                                        class="text-md text-black items-center mb-0 pl-2 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                                    >
                                        {{ translate('organisation.kvk.more_entities_question.yes') }}
                                    </label>
                                    <input
                                        id="confirmationYes"
                                        v-model="addMoreEntities"
                                        type="radio"
                                        class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                                        name="confirmation"
                                        value="1"
                                        @change="moreEntitiesAnswerChange()"
                                    >
                                </div>

                                <div
                                    class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-l"
                                    :class="addMoreEntities === '0' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                                >
                                    <label
                                        for="confirmationNo"
                                        class="text-md text-black items-center mb-0 pl-2 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                                    >
                                        {{ translate('organisation.kvk.more_entities_question.no') }}
                                    </label>
                                    <input
                                        id="confirmationNo"
                                        v-model="addMoreEntities"
                                        type="radio"
                                        class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer"
                                        name="confirmation"
                                        value="0"
                                        @change="moreEntitiesAnswerChange()"
                                    >
                                </div>
                            </div>
                            <div class="actions-bottom">
                                <div class="action-button text-center">
                                    <button
                                        :class="[
                                            'inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange',
                                            addMoreEntities !== '' ? '' : 'opacity-50 cursor-not-allowed'
                                        ]"
                                        @click="nextStep()"
                                    >
                                        {{ translate('organisation.kvk.button_text_next_step') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import Organisation from "../../../../Organisation/Organisation";
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import {useStore} from 'vuex';
import {useConfirm} from "primevue/useconfirm";
import translation from "@Mixins/Translation";
import CalculationToolHeader from "../../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import BackLinkButton from "../../../../../Components/Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import SelectInput from "@FormInput/SelectInput/Vue/SelectInput.vue";
import TextInput from "../../../../../Components/FormInput/TextInput/Vue/TextInput.vue";
import {computed, onBeforeMount, onMounted, provide, ref, watch} from 'vue';

import {getSteps} from "../../../../_config/Steps";
import {EditIcon} from "@/js/Components/Icons/index.js";
import BaseButton from "@FormInput/BaseButton.vue";
import iconDelete from '@/images/icon_delete.svg';

const {translate} = translation.methods;
const confirm = useConfirm();

const props = defineProps({
    calculationToolIdProp: {
        type: String,
        default: null,
    },
    organisationIdProp: {
        type: String,
        default: '',
    },
});

const store = useStore();

// Reactive state variables
const organisation = ref({
    id: null,
    kvk: '',
    branch_id: null,
    branch_name: '',
    company_name: '',
    street_name: '',
    street_number: '',
    post_code: '',
    city: '',
    logo: null,
    logo_full_path: null,
    is_main: false,
    sbi_codes: [],
});
const addMoreEntities = ref('');
const calculationToolName = ref('');
const errors = ref([]);
const moreEntitiesUrl = ref(null);
let branch = ref(null);
const editing = ref(false);
const branches = ref([]);


// Fetch the organisation data from the Vuex store
const fetchedOrganisation = computed(() => {
    return store.getters["organisation/organisation"];
});
getBranches();

onBeforeMount(async () => {
    await CalculationTool.get(props.calculationToolIdProp);
    await Organisation.get(props.organisationIdProp);
    organisation.value = fetchedOrganisation.value;
    organisation.value.sbi_codes = sbiCodesToEdit();
    organisation.value.branch_name = fetchedOrganisation.value.branches?.name;
});

function toggleEditing() {
    if (editing.value) {
        editing.value = false;
    } else {
        editing.value = true;
    }
}

function getBranches() {
    Organisation.getBranches()
        .then((response) => {
            response.map((branch) => {
                branches.value.push({
                    value: branch.id,
                    label: branch.name,
                });
            });
        });
}

// Methods
const backRoute = () => {
    return route('organisation.list', {calculationTool: props.calculationToolIdProp});
};

const moreEntitiesAnswerChange = () => {
    let newStep = (addMoreEntities.value == 1) ? getSteps().determine_child_entities : getSteps().organisation_summary;
    CalculationTool.updateStep({calculationTool: props.calculationToolIdProp, step: newStep})
        .then((response) => {
            if (response.status === 200) {
                moreEntitiesUrl.value = (addMoreEntities.value === '1')
                    ? route('organisation.child-organisation-validation-checks', {
                        calculationTool: props.calculationToolIdProp,
                        organisationId: props.organisationIdProp
                    })
                    : route('organisation.summary', {
                        calculationTool: props.calculationToolIdProp,
                    });
            }
        }).catch((error) => {
        errors.value = error.response.data.errors;
    });
};

const nextStep = () => {
    if (addMoreEntities.value === '') {
        return;
    }

    if (moreEntitiesUrl.value) {
        window.location.href =moreEntitiesUrl.value;
    }
};

function sbiCodesToEdit() {
    const sbiCodesString = organisation.value.sbi_codes;

    // Check if sbiCodesString is an object and not an array
    if (typeof sbiCodesString === 'object' && !Array.isArray(sbiCodesString)) {
        // Convert the object to an array of key-value pairs
        const sbiCodesArray = Object.entries(sbiCodesString).map(([key, value]) => ({
            key: key,
            value: value
        }));

        return sbiCodesArray;
    } else {
        console.error('sbi_codes is not in the expected format.');
        return null; // or handle the error as you see fit
    }
}


function sbiCodesToArray(sbiCodesToEdit) {
    try {
        // Check if parsed result is an array
        if (Array.isArray(sbiCodesToEdit)) {
            // Convert the array of objects to the desired object format
            const result = sbiCodesToEdit.reduce((accumulator, item) => {
                if (item.key) {
                    accumulator[item.key] = item.value;
                }
                return accumulator;
            }, {});

            return result;
        } else {
            console.error('Parsed data is not an array:', sbiCodesToEdit);
            return {}; // Return an empty object if the parsed data is not an array
        }
    } catch (error) {
        console.error('Invalid JSON string:', error);
        return {}; // Return an empty object if parsing fails
    }
}


const addSbiCode = () => {
    if (!Array.isArray(organisation.value.sbi_codes)) {
        if (typeof organisation.value.sbi_codes === 'object' && organisation.value.sbi_codes !== null) {
            organisation.value.sbi_codes = [organisation.value.sbi_codes];
        } else {
            organisation.value.sbi_codes = [];
        }
    }

    organisation.value.sbi_codes.push({ key: '', value: '' });
};

function removeSbiCode(index) {
    if (Array.isArray(organisation.value.sbi_codes)) {
        // Check if the index is valid
        if (index >= 0 && index < organisation.value.sbi_codes.length) {
            organisation.value.sbi_codes.splice(index, 1);
        } else {
            console.error('Index out of bounds:', index);
        }
    } else {
        console.error('sbi_codes is not an array.');
    }
}


function saveChanges() {
    let formData = {};

    let sbiCodesBackup = organisation.value.sbi_codes;
    organisation.value.sbi_codes = sbiCodesToArray(organisation.value.sbi_codes);
    formData = {};
    // Use the auditor values to determine keys, as we don't want all the data in the organisation object
    Object.keys(organisation.value).forEach((key) => {
        formData[key] = organisation.value[key] ?? null;
    });

    Organisation.updateOrganisation(organisation.value.id, formData)
        .then((data) => {
            organisation.value = data.data;
            organisation.value.sbi_codes = sbiCodesToEdit();

            for (const branch of branches.value) {
                if (branch.value == organisation.value.branch_id) {
                    organisation.value.branch_name = branch.label ?? '';
                    break;
                }
            }
            editing.value = false;
            errors.value = {};
        })
        .catch((error) => {
            if (error.response.data && error.response.data.errors) {
                errors.value = error.response.data.errors;
                console.log(errors.value)
                organisation.value.sbi_codes = sbiCodesBackup;
            } else {
                console.error(error);
            }
        });
}
</script>
