<template>
    <div class="non-aspirant-intro">
        <div
            v-if="show === 'intro'"
            class="intro-section"
        >
            <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                {{ translate('checklist.aspirant.intro.title') }}
            </h2>
            <p
                class="text-pso-dark mb-10"
            >
                {{ translate('checklist.aspirant.intro.paragraph_1_first_part') }}
                <a
                    class="text-pso-primary underline"
                    href="mailto:info@pso-nederland.nl"
                >
                    info@pso-nederland.nl
                </a>.<br>
                {{ translate('checklist.aspirant.intro.paragraph_1_second_part') }}<br>
                {{ translate('checklist.aspirant.intro.paragraph_1_third_part') }}
            </p>
            <p
                class="text-pso-dark mb-20"
            >
                <strong>{{ translate('checklist.aspirant.intro.paragraph_2_title') }}</strong><br>
                {{ translate('checklist.aspirant.intro.paragraph_2') }}
            </p>
            <div class="action-button text-center">
                <button
                    class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                    dusk="intro-aspirant-button"
                    @click.prevent="goFurther"
                >
                    {{ translate('checklist.aspirant.intro.button') }}
                </button>
            </div>
        </div>
        <div
            v-if="show === 'requirements'"
            class="requirements-section"
        >
            <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                {{ translate('checklist.aspirant.requirements.title') }}
            </h2>
            <div class="flex items-center mb-12">
                <img
                    :src="iconCheckboxBlue"
                    alt="open"
                    class="w-4"
                >
                <p class="pl-5 text-lg text-pso-dark">
                    {{ translate('checklist.aspirant.requirements.check_1') }}
                </p>
            </div>
            <div class="flex items-center mb-12">
                <img
                    :src="iconCheckboxBlue"
                    alt="open"
                    class="w-4"
                >
                <p class="pl-5 text-lg text-pso-dark">
                    {{ translate('checklist.aspirant.requirements.check_2') }}
                </p>
            </div>
            <div class="flex items-center mb-12">
                <img
                    :src="iconCheckboxBlue"
                    alt="open"
                    class="w-4"
                >
                <p class="pl-5 text-lg text-pso-dark">
                    {{ translate('checklist.aspirant.requirements.check_3') }}
                </p>
            </div>
            <div class="flex items-center mb-12">
                <img
                    :src="iconCheckboxBlue"
                    alt="open"
                    class="w-4"
                >
                <p class="pl-5 text-lg text-pso-dark">
                    {{ translate('checklist.aspirant.requirements.check_4') }}
                </p>
            </div>
            <div class="flex items-center mb-12">
                <img
                    :src="iconCheckboxBlue"
                    alt="open"
                    class="w-4"
                >
                <p class="pl-5 text-lg text-pso-dark">
                    {{ translate('checklist.aspirant.requirements.check_5') }}
                </p>
            </div>
            <div class="flex items-center mb-12">
                <img
                    :src="iconCheckboxBlue"
                    alt="open"
                    class="w-4"
                >
                <p class="pl-5 text-lg text-pso-dark">
                    {{ translate('checklist.aspirant.requirements.check_6') }}
                </p>
            </div>
            <div class="flex items-center mb-12">
                <img
                    :src="iconCheckboxBlue"
                    alt="open"
                    class="w-4"
                >
                <p class="pl-5 text-lg text-pso-dark">
                    {{ translate('checklist.aspirant.requirements.check_7') }}
                </p>
            </div>
            <div class="flex items-center pt-2 pb-2 mb-12 text-md">
                <input
                    :id="checkbox_bottom"
                    v-model="checkbox_bottom"
                    class="align-text-bottom mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                    name="checkbox_bottom"
                    type="checkbox"
                    value="1"
                >
                <label
                    :for="checkbox_bottom"
                >
                    {{ translate('checklist.aspirant.requirements.checkbox_bottom') }}
                </label>
            </div>
            <div class="action-button text-center">
                <a
                    :class="checkbox_bottom ? '' : 'opacity-50 cursor-not-allowed'"
                    :href="nextPageUrl()"
                    class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                    dusk="requirements-aspirant-button"
                >
                    {{ translate('checklist.aspirant.requirements.button') }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>

import CalculationTool from '../../../CalculationTool/CalculationTool';
import {mapGetters} from 'vuex';

export default {
    data() {
        return {
            show: 'intro',
            checkbox_bottom: false,
            iconCheckboxBlue: new URL('/resources/images/check_blue.svg', import.meta.url).href,
        };
    },
    computed: {
        ...mapGetters('calculation_tool', ['calculation_tool', 'score']),
    },
    methods: {
        nextPageUrl() {
            return route('calculation-tool-checklist.form', { calculationTool: this.calculation_tool.id });
        },
        goFurther() {
            this.show = 'requirements';
        },
    },
};
</script>
