const Hours = [
    '40',
    '39',
    '38',
    '37',
    '36',
    '35',
    '34',
    '33',
    '32'
]

export function getCaoHours() {
    return Hours;
}
