<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow p-12">
                <aspirant-intro
                    v-if="score.pso?.level === 'aspirant'"
                />
                <non-aspirant-intro
                    v-else
                />
            </div>
        </div>
    </div>
</template>

<script>
import CalculationTool from '../../../CalculationTool/CalculationTool';
import {mapGetters} from 'vuex';
import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import AspirantIntro from '../Parts/AspirantIntro.vue';
import NonAspirantIntro from '../Parts/NonAspirantIntro.vue';

export default {
    components: {CalculationToolHeader, AspirantIntro, NonAspirantIntro},
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('calculation_tool', ['calculation_tool', 'score']),
    },
    created() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationTool.getScore(this.calculationToolIdProp);
    },
    methods: {},
};
</script>
