import _ from "lodash";
import {createApp} from 'vue'

import ResetPassword from "./Vue/ResetPassword.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {ResetPassword}
    }
    return createApp(_.merge(options, vueOptions));
}
