<template>
    <div>
        <div
            v-show="show"
            class="w-100 relative border border-red-500 bg-red-500 bg-opacity-20 pt-6 pl-6 pr-6 pb-28 rounded-lg text-red-700"
        >
            <p
                v-for="(message) in alertMessages"
                :key="message"
                class="text-red-700 mb-8 w-100 pr-6 last-of-type:mb-0 font-normal"
            >
                {{ message }}
            </p>

            <button
                aria-label="Close"
                class="ml-auto inline-flex hover:cursor-pointer mt-1.5 absolute right-7 top-5"
                @click.prevent="hideAlert()"
            >
                <img
                    :src="iconClose"
                    alt="close"
                    class="h-3 w-3"
                >
            </button>

            <div class="divide-y divide-dashed divide-red-700 border-dashed  border-teal-600 bg-teal-400">
                <div
                    v-if="tableShow === 'rows' || tableShow === 'duplicates_and_rows'"
                    :class="tableShow === 'duplicates' || tableShow === 'duplicates_and_rows' ? 'mb-10' : ''"
                >
                    <table
                        aria-label="Table with errors"
                        class="table text-red-700 font-normal mt-4 table-auto w-full"
                    >
                        <thead class="border-b border-red-700 leading-6">
                            <tr>
                                <th class="text-red-700 font-normal text-left">
                                    {{ translate('calculation_tool_data.excel_upload_validations.errors_table_row') }}
                                </th>
                                <th class="text-red-700 font-normal text-left pl-1">
                                    {{ translate('calculation_tool_data.excel_upload_validations.errors_table_column') }}
                                </th>
                                <th class="text-red-700 font-normal text-left pl-1">
                                    {{ translate('calculation_tool_data.excel_upload_validations.errors_table_message') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item) in tableDataRows"
                                :key="item.message"
                                class="leading-10"
                            >
                                <td class="text-red-700 font-normal text-left">
                                    {{ item.row_number }}
                                </td>
                                <td class="text-red-700 font-normal text-left text-lg pl-1">
                                    {{ translate('calculation_tool_data.excel_upload_validations.' + item.column) }}
                                </td>
                                <td class="text-red-700 font-normal text-left text-lg pl-1">
                                    {{ translate('calculation_tool_data.excel_upload_validations.' + item.message) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="tableShow === 'duplicates' || tableShow === 'duplicates_and_rows'"
                    :class="tableShow === 'rows' || tableShow === 'duplicates_and_rows' ? 'pt-6' : ''"
                >
                    <p
                        v-for="(message) in alertSecondMessages"
                        :key="message"
                        class="text-red-700 mb-8 w-100 pr-6 last-of-type:mb-0 font-normal"
                    >
                        {{ message }}
                    </p>

                    <table
                        aria-label="Table with errors"
                        class="table text-red-700 font-normal mt-10 table-auto w-full"
                    >
                        <thead class="border-b border-red-700 leading-6">
                            <tr>
                                <th class="text-red-700 font-normal text-left pl-1">
                                    {{ translate('calculation_tool_data.excel_upload_validations.errors_table_row') }}
                                </th>
                                <th class="text-red-700 font-normal text-left pl-1">
                                    {{ translate('calculation_tool_data.excel_upload_validations.errors_table_message') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item) in tableDataDuplicates"
                                :key="item.message"
                                class="leading-10"
                            >
                                <td class="text-red-700 font-normal text-left pl-1">
                                    {{ item.row_number }}
                                </td>
                                <td class="text-red-700 font-normal text-left pl-1">
                                    {{ translate('calculation_tool_data.excel_upload_validations.' + item.message) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <button
                v-if="btnMessage !== ''"
                aria-label="Close"
                class="ml-auto flex hover:cursor-pointer mt-1.5 absolute right-6 bottom-6 rounded-xl font-medium bg-white p-4 text-gray-800"
                @click.prevent="actionButton()"
            >
                <img
                    :src="iconRefresh"
                    alt="refresh"
                    class="h-4 w-4 mt-1 mr-2"
                >
                {{ btnMessage }}
            </button>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        idProp: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            show: false,
            alertMessages: '',
            alertSecondMessages: '',
            btnMessage: '',
            btnAction: '',
            iconClose: new URL('/resources/images/icon_times.svg', import.meta.url).href,
            iconRefresh: new URL('/resources/images/icon_refresh.svg', import.meta.url).href,
            tableData: {},
            tableDataDuplicates: {},
            tableDataRows: {},
            tableShow: ''
        }
    },
    created() {
        window.mitt.on('showErrorAlert', (data) => this.toggleAlert(data))
    },
    methods: {
        toggleAlert(data) {
            if (this.idProp === data.id) {
                this.show = !this.show;
                this.alertMessages = data.messages;
                this.btnMessage = data.buttonMessage;
                this.btnAction = data.buttonAction;
                this.tableData = data.tableData ?? '';
                this.tableShow = data.tableShow ?? '';
                this.alertSecondMessages = data.secondMessages ?? '';

                if (this.tableData !== '') {
                    this.processTableData();
                }
            } else {
                this.show = false;
            }
        },
        hideAlert() {
            this.show = false;
            window.mitt.emit("updateUploadStatus", {status: ''});
        },
        actionButton() {
            if (this.btnAction === 'show-upload') {
                this.show = false;
                window.mitt.emit("updateUploadStatus", {status: ''});
            }
        },
        processTableData() {
            this.tableDataDuplicates = {};
            this.tableDataRows = {};

            const messageKeysForDuplicatedItems = [
                'target_group_check_if_exists_in_file',
                'target_group_check_if_exists_in_db',
                'target_group_check_if_exists_in_db_in_file',
                'purchase_check_if_exists_in_file',
                'purchase_check_if_exists_in_db',
                'purchase_check_if_exists_not_exist',
                'purchase_check_if_exists_in_db_in_file',
                'no_certification_exists_for_the_provided_kvk',
            ];

            let groupedData = {};

            // Group by message
            for (const key in this.tableData) {
                const entry = this.tableData[key];
                const messageKey = entry.message;

                if (!groupedData[messageKey]) {
                    groupedData[messageKey] = [];
                }

                groupedData[messageKey].push(entry.row_number);
            }

            // Process the grouped data
            for (const messageKey in groupedData) {
                const rowsString = groupedData[messageKey].join(' + ');

                if (messageKeysForDuplicatedItems.includes(messageKey)) {
                    this.tableDataDuplicates[messageKey] = {
                        row_number: rowsString,
                        message: messageKey,
                    };
                } else {
                    this.tableDataRows[messageKey] = {
                        row_number: rowsString,
                        column: (this.tableData.find(entry => entry.message === messageKey) || {}).column,
                        message: messageKey,
                    };
                }
            }
        }
    }
}
</script>
