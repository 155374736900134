import axios from 'axios';

export default {
    namespaced: true,
    state: {
        organisation: [],
        organisations: [],
        organisation_children: []
    },
    getters: {
        organisation(state) {
            return state.organisation;
        },
        organisations(state) {
            return state.organisations;
        }
    },
    mutations: {
        organisation(state, data) {
            state.organisation = data;
        },
        organisations(state, data) {
            state.organisations = data;
        }
    },
    actions: {
        create({}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + '/organisations', data.payload)
                .then((response) => response);
        },
        updateLogo({}, data) {
            return axios.post(
                '/api/organisations/' + data.organisationId + '/update-logo',
                data.payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            ).then((response) => response);
        },
        updateBranch({}, data) {
            return axios.patch(
                '/api/organisations/' + data.organisationId + '/update-branch',
                {branch: data.payload},
            ).then((response) => response);
        },
        get({commit}, payload) {
            return axios.get('/api/organisations/' + payload.id, payload)
                .then((response) => response.data)
                .then((responseData) => commit('organisation', responseData.data));
        },
        index({commit}, payload) {
            return axios.get('/api/organisations/', {params: payload})
                .then((response) => response.data)
                .then((responseData) => commit('organisations', responseData.data));
        },
        remove({}, payload) {
            return axios.delete('/api/calculation-tools/' + payload.calculationToolId + '/organisations/' + payload.organisationId)
                .then((response) => response);
        },
        replaceMain({}, payload) {
            return axios.patch('/api/calculation-tools/' + payload.calculationToolId + '/organisations/replace-main/' + payload.organisationId)
                .then((response) => response);
        },
        addToCalculationTool({}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + '/organisations/' + data.organisationId + '/add-to-calculation-tool', { forceMain: data.forceMain })
                .then((response) => response);
        },
        update({}, data) {
            return axios.patch('/api/organisations/' + data.organisationId + '/update-details/', data.payload)
                .then((response) => response);
        },
        updateContact({}, data) {
            return axios.patch('/api/organisations/' + data.organisationId, data.payload)
                .then((response) => response);
        },
        updateByAuditor({}, data) {
            return axios.patch('/api/organisations/auditor/' + data.organisationId, data.payload)
                .then((response) => response);
        },
        getBranches({}) {
            return axios.get('/api/branches')
                .then((response) => response.data);
        }
    }
};
