<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="wizard-top">
                    <app-back-button
                        @click.prevent="goBack()"
                    />
                </div>

                <div
                    v-if="previousSections.length"
                    class="previous-sections my-10"
                >
                    <TransitionGroup
                        name="bounce"
                    >
                        <div
                            v-for="(previousSection) in previousSections"
                            :key="previousSection.id"
                            class="previous-section-item flex pr-1 pl-1 pt-5 pb-5 leading-4 first:border-t border-b border-gray-400"
                        >
                            <span class="w-full pr-1">{{ previousSection.name }}</span>
                            <img
                                :src="iconCircleCheckbox"
                                alt="open"
                                class="w-4"
                            >
                        </div>
                    </TransitionGroup>
                </div>

                <Transition name="slide-fade">
                    <div
                        v-if="showCurrentSection"
                        class="current-section mb-10"
                    >
                        <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                            {{ currentSection.name }}
                        </h2>

                        <div
                            v-if="checkBoxTop.show"
                            class="flex items-center mb-10"
                        >
                            <input
                                id="checkBoxTop"
                                v-model="checkBoxTop.checked"
                                class="align-text-bottom mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                                name="checkBoxTop"
                                type="checkbox"
                                value="1"
                                @change="checkIfNextStepPossible()"
                            >
                            <label for="checkBoxTop">
                                {{ checkBoxTop.text }}
                            </label>
                        </div>

                        <div
                            v-for="(question, key) in formItems"
                            :key="question.id"
                            class="mb-10 text-pso-dark"
                        >
                            <div v-html="question.text"></div>
                            <textarea
                                v-model.trim="formItems[key].response"
                                :name="question.id"
                                :placeholder="question.placeholder"
                                class="mt-2 pt-3 pb-3 pl-6 pr-6 w-full h-40 border-gray-200 text-lg rounded-xl placeholder:text-gray-600"
                                @keyup="checkIfNextStepPossible()"
                            />
                        </div>

                        <div
                            v-if="checkBoxBottom.show"
                            class="flex items-center mb-10"
                        >
                            <input
                                id="checkBoxBottom"
                                v-model="checkBoxBottom.checked"
                                class="align-text-bottom mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                                name="checkBoxBottom"
                                type="checkbox"
                                value="1"
                                @change="checkIfNextStepPossible()"
                            >
                            <label for="checkBoxBottom">
                                {{ checkBoxBottom.text }}
                            </label>
                        </div>
                    </div>
                </Transition>

                <div class="action-button text-center">
                    <button
                        :class="allRequiredFieldsAreFilled ? '' : 'opacity-50 cursor-not-allowed'"
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        dusk="checklist-save-button"
                        @click="saveAndGoNext()"
                    >
                        {{ pageBottomButton }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import CalculationTool from '../../../CalculationTool/CalculationTool';
import Checklist from "../../Checklist";
import {mapGetters} from "vuex";
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import _ from 'lodash';

export default {
    components: {CalculationToolHeader, 'app-back-button': BackLinkButton},
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            formItems: [],
            checkBoxTop: {
                show: false,
                checked: false,
                text: '',
            },
            checkBoxBottom: {
                show: false,
                checked: false,
                text: '',
            },
            sections: {},
            showCurrentSection: true,
            currentSection: {},
            previousSections: [],
            isFirstSection: true,
            isLastSection: false,
            allRequiredFieldsAreFilled: false,
            pageBottomButton: '',
            iconCircleCheckbox: new URL('/resources/images/icon_circle_checkbox.svg', import.meta.url).href,
        };
    },
    computed: {
        ...mapGetters('calculation_tool', {score: 'score'}),
        ...mapGetters('checklist', {formQuestionsWithResponses: 'form_questions_with_responses'}),
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationTool.getScore(this.calculationToolIdProp)
            .then(() => {
                Checklist.getFormQuestions({
                    'calculationToolId': this.calculationToolIdProp,
                    'level': this.score.pso.level,
                }).then(() => {
                    this.displayNextSection();
                });
            });
    },
    methods: {
        displayNextSection() {
            if (this.formQuestionsWithResponses.length === 0) {
                window.location = route('calculation-tool-application.index', {calculationTool: this.calculationToolIdProp});
            }
            if (this.isLastSection !== true) {
                this.showCurrentSection = false;
            }
            this.currentSection = this.formQuestionsWithResponses.shift();
            this.resetCheckboxes();
            this.prepareCheckboxes();
            this.prepareQuestions();
            this.setPageBottomButtonText();
            if (this.previousSections.length > 0) {
                this.isFirstSection = false;
            }
            this.checkIfNextStepPossible();
            setTimeout(() => this.showCurrentSection = true, 500);
        },
        prepareQuestions() {
            this.formItems = this.currentSection.questions.map(question => ({
                id: question.id,
                text: question.text,
                placeholder: question.placeholder,
                response: question?.response?.body ?? null,
            }));
        },
        resetCheckboxes() {
            const defaultCheckboxState = {
                show: false,
                text: '',
                checked: false,
            }

            this.checkBoxTop = Object.assign({}, defaultCheckboxState);
            this.checkBoxBottom = Object.assign({}, defaultCheckboxState);
        },
        prepareCheckboxes() {
            if (this.currentSection.checkbox_top_text) {
                this.checkBoxTop.show = true;
                this.checkBoxTop.text = this.currentSection?.checkbox_top_text;
                this.checkBoxTop.checked = this.currentSection?.checkbox_top_checked;
            }
            if (this.currentSection.checkbox_bottom_text) {
                this.checkBoxBottom.show = true;
                this.checkBoxBottom.text = this.currentSection?.checkbox_bottom_text;
                this.checkBoxBottom.checked = this.currentSection?.checkbox_bottom_checked;
            }
        },
        checkIfNextStepPossible() {
            this.allRequiredFieldsAreFilled = !this.formItems.some((item) => _.isEmpty(item.response));

            if ((this.checkBoxTop.show && !this.checkBoxTop.checked) || (this.checkBoxBottom.show && !this.checkBoxBottom.checked)) {
                this.allRequiredFieldsAreFilled = false;
            }
        },
        saveAndGoNext() {
            if (this.allRequiredFieldsAreFilled === true) {
                let formData = this.formItems.map(question => (
                    {
                        section_id: this.currentSection.id,
                        question_id: question.id,
                        body: question.response,
                    }
                ));
                Checklist.storeResponses({calculationToolId: this.calculationToolIdProp, data: formData})
                    .then((response) => {
                        this.updateCurrentSectionAfterSave(response.data.data);

                        this.updatePreviousSections();
                        this.displayNextSection();

                        if (this.formQuestionsWithResponses.length === 1) {
                            this.isLastSection = true;
                        }
                    })
                    .catch((error) => {
                        if (error) {
                            if ([401, 404].includes(error.response.status)) {
                                this.failed = error.response.data.message;
                            }
                            this.$setErrorsFromResponse(error.response.data);
                        }
                    });
            }
        },
        updateCurrentSectionAfterSave(response) {
            this.currentSection = response;
            if (this.currentSection.checkbox_bottom_text) {
                this.currentSection.checkbox_bottom_checked = true;
            }
            if (this.currentSection.checkbox_top_text) {
                this.currentSection.checkbox_top_checked = true;
            }
        },
        updatePreviousSections() {
            this.previousSections.push(this.currentSection);
        },
        showPreviousSection() {
            const previousSection = this.previousSections.pop();
            if (this.previousSections.length === 0) {
                this.isFirstSection = true;
            }
            this.isLastSection = false;
            this.formQuestionsWithResponses.unshift(this.currentSection);
            this.formQuestionsWithResponses.unshift(previousSection);
            this.displayNextSection();
        },
        goBack() {
            if (this.isFirstSection) {
                window.location = route('calculation-tool-checklist.index', { calculationTool: this.calculationToolIdProp });
            } else {
                this.showPreviousSection();
            }
        },
        setPageBottomButtonText() {
            this.pageBottomButton = this.isLastSection ?
                this.translate('checklist.form.page_button_text_last_section') :
                this.translate('checklist.form.page_button_text');
        },
    }
};
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

.bounce-enter-active {
    animation: bounce-in 1s;
}

.bounce-leave-active {
    animation: bounce-in 1s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
</style>
