<template>
    <div class="container mx-auto">
        <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto mb-5 mt-5">
            <div class="page-header text-pso-primary font-bold text-3xl">
                {{ translate('auth.forgot_reset_password.reset_your_password') }}
            </div>
        </div>

        <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto rounded-xl bg-white mb-5">
            <div class="password-reset-email-container pr-8 pb-8 pl-8 pt-8">
                <div
                    v-if="errors.status"
                    class="alert alert-success"
                    role="alert"
                >
                    {{ errors.status }}
                </div>

                <form
                    class="form"
                    @submit.prevent="sendResetPassword"
                >
                    <slot />

                    <div class="row mb-4">
                        <p>
                            {{ translate('auth.forgot_reset_password.enter_new_password') }}
                        </p>
                    </div>

                    <div class="row mb-3">
                        <label
                            class="col-md-4 col-form-label text-md-end mb-2 first-letter:uppercase block"
                            for="password"
                        >{{ translate('auth.forgot_reset_password.password') }}</label>

                        <div class="col-md-6">
                            <input
                                id="password"
                                v-model="resetForm.password"
                                :placeholder="translate('auth.forgot_reset_password.password')"
                                autofocus
                                class="form-input px-8 py-3 rounded-xl w-full border-gray-200 placeholder:italic placeholder:lowercase placeholder:text-gray-600"
                                name="password"
                                type="password"
                            >
                            <span
                                v-if="errors.password"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.password"
                            />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label
                            class="col-md-4 col-form-label text-md-end mb-2 first-letter:uppercase block"
                            for="password-confirmation"
                        >{{ translate('auth.forgot_reset_password.confirm_password') }}</label>

                        <div class="col-md-6">
                            <input
                                id="password-confirmation"
                                v-model="resetForm.password_confirmation"
                                :placeholder="translate('auth.forgot_reset_password.confirm_password')"
                                autofocus
                                class="form-input px-8 py-3 rounded-xl w-full border-gray-200 placeholder:italic placeholder:lowercase placeholder:text-gray-600"
                                name="password_confirmation"
                                type="password"
                            >
                            <span
                                v-if="errors.password_confirmation"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.password_confirmation"
                            />
                        </div>
                    </div>

                    <div class="row mb-0">
                        <div class="col-md-12 text-center">
                            <button
                                class="inline-block mt-4 mb-3 px-12 py-2 rounded-xl bg-pso-orange text-white"
                                type="submit"
                            >
                                {{ translate('auth.forgot_reset_password.reset_password') }}
                            </button>
                        </div>
                        <span
                            v-if="errors.token"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.token"
                        />
                        <span
                            v-if="errors.email"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.email"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import User from "../../../../User/User";

export default {

    props: {
        resetData: {
            type: Object,
            default() {
                return {
                    email: '',
                    token: ''
                };
            }
        }
    },

    data() {
        return {
            resetForm: {},
            failed: false,
            success: false,
            errors: {}
        };
    },

    created() {
        this.resetForm.email = this.resetData.email;
        this.resetForm.token = this.resetData.token;
    },

    methods: {
        sendResetPassword() {
            let formData = User.buildResetPasswordForm(this.resetForm);
            User.resetPasswordSave(formData)
                .then(() => {
                    this.errors = {};
                    let dialogData = {
                        body: this.translate('auth.forgot_reset_password.reset_success'),
                        buttons: [{
                            text: this.translate('auth.forgot_reset_password.success_modal_button_text'),
                            redirect: this.getLoginRoute(),
                        }]
                    }

                    window.mitt.emit("dialogMessage", dialogData);
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        this.$setErrorsFromResponse(error.response.data);
                    }
                });
        },

        getLoginRoute() {
            return route('login.show-login-form');
        }
    }
};
</script>
