<template>
    <div>
        <div
            class="w-full relative"
        >
            <div class="mb-8">
                <CheckboxInput
                    id="auditor_confirm_correctly_filled_in"
                    :model-value="confirmCorrectlyFilledInValue ?? false"
                    name="confirm_correctly_filled_in"
                    :label="translate('auditor.input.checkbox_label')"

                    @update:modelValue="(value) => $emit('update:confirmCorrectlyFilledInValue', value)"
                />

                <span
                    v-if="errors && errors.confirm_correctly_filled_in"
                    class="text-sm text-red-500"
                    role="alert"
                    v-text="errors.confirm_correctly_filled_in"
                />
            </div>
            <div
                v-if="confirmCorrectlyFilledInValue"
                class="question-list"
            >
                <h1 class="font-bold text-lg mb-8">
                    {{ translate('auditor.questions_and_explanations') }}
                </h1>
                <p>
                    <!-- @TODO: Add translation for this, currently unknown -->
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.?
                </p>
                <AuditorComment
                    id="auditor_first_explanation"
                    :model-value="firstExplanationValue"
                    name="first_explanation"
                    :rows="5"
                    class="border-gray-200 rounded-xl mt-2"
                    :errors="errors.first_explanation"

                    @update:modelValue="(value) => $emit('update:firstExplanationValue', value)"
                />


                <div class="mt-4">
                    <p>
                        <!-- @TODO: Add translation for this, currently unknown -->
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.?
                    </p>
                    <div
                        class="my-2"
                    >
                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center mr-4"
                        >
                            <RadioInput
                                :items="[
                                    {
                                        id: 'auditor_choice_yes',
                                        value: 'yes',
                                        label: translate('auditor.input.radio_button.yes'),
                                    },
                                    {
                                        id: 'auditor_choice_no',
                                        value: 'no',
                                        label: translate('auditor.input.radio_button.no'),
                                    },
                                ]"
                                name="choice"
                                class="flex justify-start"
                                :checked-item-value="choiceValue"

                                @update:modelValue="(value) => $emit('update:choiceValue', value)"
                            />
                        </div>
                        <span
                            v-if="errors && errors.choice"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.choice"
                        />

                        <AuditorComment
                            id="auditor_second_explanation"
                            :model-value="secondExplanationValue"
                            name="second_explanation"
                            :rows="5"
                            class="border-gray-200 rounded-xl mt-2"
                            :errors="errors.second_explanation"

                            @update:modelValue="(value) => $emit('update:secondExplanationValue', value)"
                        />

                        <span
                            v-if="errors && errors.second_explanation"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.second_explanation"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// @TODO: add saving capabilities..

import AuditorComment from "../../../../FormInput/Auditor/AuditorComment/Vue/AuditorComment.vue";
import RadioInput from "../../../../FormInput/RadioInput/Vue/RadioInput.vue";
import CheckboxInput from "../../../../FormInput/CheckboxInput/Vue/CheckboxInput.vue";

export default {
    components: {CheckboxInput, RadioInput, AuditorComment},
    props: {
        confirmCorrectlyFilledInValue: {
            type: Boolean,
            default: null,
        },
        firstExplanationValue: {
            type: String,
            default: null
        },
        choiceValue: {
            type: [Number, String],
            default: null
        },
        secondExplanationValue: {
            type: String,
            default: null
        },
        errors: {
            type: [Object, Array],
            default: null,
        }
    },
}
</script>
