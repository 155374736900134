import Base from "../Base";
import store from "../../Store/store";

class Result extends Base {
    static getComponents() {
        return {
        };
    }

    static show(advisorId) {
        return store.dispatch('advisor/show', advisorId);
    }

    static index() {
        return store.dispatch('advisor/index');
    }
}

export default Result;
