<template>
    <main class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button :href="backRoute()" />
                </div>

                <h1
                    class="text-center text-pso-dark text-2xl font-bold mb-10"
                >
                    {{ translate('organisation.child_organisation.validation_checks.title') }}
                </h1>

                <p
                    class="text-lg text-pso-dark"
                >
                    {{ translate('organisation.child_organisation.validation_checks.paragraph') }}
                </p>
                <div class="flex items-center pt-12">
                    <svg
                        width="16"
                        height="13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.0176 0L16 1.98239L5.66373 12.3187L0 6.65493L1.98239 4.67254L5.66373 8.35388L14.0176 0Z"
                            fill="#009BDE"
                        />
                    </svg>
                    <p class="pl-5 text-lg text-pso-dark">
                        {{ translate('organisation.child_organisation.validation_checks.check_1') }}
                    </p>
                </div>
                <div class="flex items-center pt-12">
                    <svg
                        width="16"
                        height="13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.0176 0L16 1.98239L5.66373 12.3187L0 6.65493L1.98239 4.67254L5.66373 8.35388L14.0176 0Z"
                            fill="#009BDE"
                        />
                    </svg>
                    <p class="pl-5 text-lg text-pso-dark">
                        {{ translate('organisation.child_organisation.validation_checks.check_2') }}
                    </p>
                </div>
                <div class="flex items-center pt-12">
                    <svg
                        width="21"
                        height="13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.0176 0L16 1.98239L5.66373 12.3187L0 6.65493L1.98239 4.67254L5.66373 8.35388L14.0176 0Z"
                            fill="#009BDE"
                        />
                    </svg>
                    <p class="pl-5 text-lg text-pso-dark">
                        {{ translate('organisation.child_organisation.validation_checks.check_3') }}
                    </p>
                </div>
                <div class="flex items-center pt-12">
                    <svg
                        width="16"
                        height="13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.0176 0L16 1.98239L5.66373 12.3187L0 6.65493L1.98239 4.67254L5.66373 8.35388L14.0176 0Z"
                            fill="#009BDE"
                        />
                    </svg>
                    <p class="pl-5 text-lg text-pso-dark">
                        {{ translate('organisation.child_organisation.validation_checks.check_4') }}
                    </p>
                </div>
                <div class="flex items-center pt-12 pb-12">
                    <svg
                        width="16"
                        height="13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.0176 0L16 1.98239L5.66373 12.3187L0 6.65493L1.98239 4.67254L5.66373 8.35388L14.0176 0Z"
                            fill="#009BDE"
                        />
                    </svg>
                    <p class="pl-5 text-lg text-pso-dark">
                        {{ translate('organisation.child_organisation.validation_checks.check_5') }}
                    </p>
                </div>
                <div>
                    <div class="flex items-center justify-content-center w-full mx-auto mb-12 items-stretch">
                        <div
                            class="w-1/2 radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-l mr-4"
                            :class="meetConditions === '1' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="meetConditionsYes"
                                class="text-lg text-pso-dark items-center mb-0 pl-0 pt-6 pb-6 pr-6 w-full hover:cursor-pointer"
                            >
                                {{ translate('organisation.child_organisation.validation_checks.button_meets_conditions') }}
                            </label>
                            <input
                                id="meetConditionsYes"
                                v-model="meetConditions"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-6 ml-6 cursor-pointer "
                                name="confirmation"
                                value="1"
                                @change="childOrganisationsMeetTheConditions()"
                            >
                        </div>

                        <div
                            class="w-1/2 radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-l"
                            :class="meetConditions === '0' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="meetConditionsNo"
                                class="text-lg text-pso-dark items-center mb-0 pl-0 pt-6 pb-6 pr-6 w-full hover:cursor-pointer"
                            >
                                {{ translate('organisation.child_organisation.validation_checks.button_doesnt_meets_conditions') }}
                            </label>
                            <input
                                id="meetConditionsNo"
                                v-model="meetConditions"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-6 ml-6 cursor-pointer"
                                name="confirmation"
                                value="0"
                                @change="childOrganisationsDontMeetTheConditions()"
                            >
                        </div>
                    </div>
                    <div class="actions-bottom">
                        <div class="action-button text-center">
                            <button
                                class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                                :disabled="meetConditions === null"
                                :class="meetConditions !== null ? '' : 'opacity-50 cursor-not-allowed'"
                                @click.prevent="goToNextStep()"
                            >
                                {{ translate('organisation.child_organisation.validation_checks.button_next_step') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import CalculationToolHeader from "../../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import BackLinkButton from "../../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import {getSteps} from "../../../../_config/Steps"

export default {
    components: {CalculationToolHeader, 'app-back-button': BackLinkButton},
    props: {
        calculationToolIdProp: {
            type: String,
            default: null,
        },
        organisationIdProp: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            "meetConditions": null,
            "nextStepURL": '#',
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
    },
    methods: {
        backRoute() {
            return route('organisation.detail', {calculationTool: this.calculationToolIdProp, organisationId: this.organisationIdProp});
        },

        childOrganisationsMeetTheConditions() {
            this.nextStepURL = route('organisation.child-organisation-list', {calculationTool: this.calculationToolIdProp, organisationId: this.organisationIdProp});
        },

        childOrganisationsDontMeetTheConditions() {
            this.nextStepURL = route('organisation.summary', {calculationTool: this.calculationToolIdProp});
        },
        goToNextStep()
        {
            if (+this.meetConditions === 0) {
                CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().organisation_summary})
                    .catch((error) => {
                        console.log(error.response.data.data.message);
                    });
            }

            window.location = this.nextStepURL;
        }
    },
};
</script>
