<template>
    <div class="w-full">
        <input
            :id="id"
            :value="formatDisplayedValue(modelValue)"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :class="['form-input px-6 py-3 w-full text-pso-dark border-gray-200 text-lg placeholder:lowercase placeholder:text-gray-600 rounded-xl', {
                'border-red-500': errors,
                'bg-gray-050 text-pso-dark': disabled,
            }]"
            :type="hasDecimals ? 'text' : 'number'"
            :step="stepInterval"

            @input="($event) => formatOnInput($event)"
            @change="($event) => formatOnChange($event)"
        >
        <div v-if="errors" class="mb-2">
            <span
                dusk="{{ duskErrorName }}"
                class="text-sm text-red-500"
                role="alert"
                v-text="errors"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [Number, String],
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        errors: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null
        },
        stepInterval: {
            type: String,
            default: '1',
        },
        hasDecimals: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        forcePositive: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:modelValue': [Number, String]
    },
    methods: {
        formatOnInput($event) {
            if ($event.target.value) {
                this.$emit('update:modelValue', $event.target.value);
            } else {
                this.$emit('update:modelValue', null);
            }
        },
        formatOnChange($event) {
            if ($event.target.value) {
                const value = $event.target.value;
                if (this.hasDecimals) {
                    let numberValue = parseFloat(value.replace(',', '.'))
                    if (this.forcePositive && numberValue < 0) {
                        numberValue = 0.00;
                    }
                    numberValue = numberValue.toFixed(2);
                    this.$emit('update:modelValue', numberValue ?? $event.target.value);
                } else {
                    this.$emit('update:modelValue', value);
                }
            } else {
                this.$emit('update:modelValue', null);
            }
        },
        formatDisplayedValue(value) {
            if (this.hasDecimals && value) {
                return value.toString().replace('.', ',');
            }

            return this.modelValue;
        }
    }
}
</script>

<style lang="scss" scoped>
/* Hide up/down arrows on number input field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
</style>
