<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button @click.prevent="previousStep()" />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('calculation_tool_data.result.title') }}
                </h2>

                <section class="score-bar-section mb-10 border-b-2 border-solid border-blue-400 pb-10">
                    <div>
                        <span class="text-lg mr-1">
                            {{ translate('calculation_tool_data.result.first_paragraph') }}
                        </span>
                        <Popper
                            :arrow="true"
                            placement="top"
                        >
                            <div class="ml-1">
                                <img
                                    :src="iconInfo"
                                    alt="info"
                                    class="hover:cursor-pointer"
                                >
                            </div>
                            <template #content>
                                {{ translate('calculation_tool_data.result.preliminary_score_tooltip') }}
                            </template>
                        </Popper>
                    </div>
                    <h3 class="text-xl mt-4 font-bold text-pso-primary">
                        {{ scoreBarHeading }}
                    </h3>

                    <p class="mt-4 text-md">
                        {{ scoreBarParagraph }}
                    </p>

                    <div class="pr-14 pl-14 pb-10 mt-24">
                        <score-bar
                            :percentage-prop="scoringStats.scorePercentage"
                        />
                    </div>
                </section>

                <section class="scoring-information mb-10 border-b-2 border-solid border-blue-400 pb-10">
                    <div
                        v-if="scoringStats.totalEmployees < scoringStats.totalGroupCount"
                        class="col-md-8 offset-md-4 border p-6 rounded-xl border-red-500 border-l-red-700 border-l-8 mb-6"
                        dusk="target-group-size-warning"
                    >
                        <p class="font-bold">
                            {{ translate('calculation_tool_data.result.warning.paragraph_1') }}
                        </p>
                        <p>
                            {{ translate('calculation_tool_data.result.warning.paragraph_2') }}
                        </p>
                        <p>
                            {{ translate('calculation_tool_data.result.warning.paragraph_3') }}
                        </p>
                        <p>
                            {{ translate('calculation_tool_data.result.warning.paragraph_4') }}
                        </p>
                        <p>
                            {{ translate('calculation_tool_data.result.warning.paragraph_5') }}
                        </p>
                        <p>
                            {{ translate('calculation_tool_data.result.warning.paragraph_6') }}
                        </p>
                    </div>
                    <h3 class="mb-6 text-xl font-bold text-pso-primary">
                        {{ translate('calculation_tool_data.result.how_its_calculated.section_heading') }}
                    </h3>
                    <div class="columns flex">
                        <div class="left-column w-1/2 pr-6">
                            <p class="text-lg mb-6">
                                {{ translate('calculation_tool_data.result.how_its_calculated.left_column.heading') }}
                            </p>

                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.how_its_calculated.left_column.paragraph_one') }}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        class="w-full text-md text-pso-dark"
                                        dusk="howItsCalculatedTotalEmployees"
                                    >
                                        {{ scoringStats.companySizeEmployee.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.how_its_calculated.left_column.paragraph_two') }}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        class="w-full text-md text-pso-dark"
                                        dusk="howItsCalculatedTotalGroupCount"
                                    >
                                        {{ scoringStats.totalGroupCount.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.how_its_calculated.left_column.paragraph_three') }}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        class="w-full text-md text-pso-dark"
                                        dusk="howItsCalculatedEmployeesScore"
                                    >
                                        {{ scoringStats.calculatedEmployeesScore?.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                    </span>
                                </div>
                            </div>

                            <div class="scoring-chart-wrapper flex items-center">
                                <div class="scoring-chart relative w-1/2 text-center">
                                    <Doughnut
                                        :data="chartDataScoringStats"
                                        :options="chartOptions"
                                    />
                                    <div
                                        class="doughnut-middle-container absolute top-[85px] left-1/2 flex flex-col justify-center items-center w-[150px] h-[150px] rounded-full outline-0 bg-gray-70 -translate-x-1/2 -z-1 "
                                    >
                                        <span
                                            class="block font-bold text-pso-primary text-md text-center"
                                            dusk="howItsCalculatedDoughnutMiddle"
                                        >
                                            {{ scoringStats.scorePercentage.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                        </span>
                                    </div>
                                </div>
                                <div class="scoring-chart-legend w-1/2 pl-4">
                                    <div class="legend-item flex mb-2">
                                        <div class="w-2.5 h-2.5 mr-2 bg-pso-primary" />
                                        <span class="block max-w-[130px] text-xs text-gray-600">
                                            {{ translate('calculation_tool_data.result.how_its_calculated.left_column.graph.legend_one') }}
                                        </span>
                                    </div>
                                    <div class="legend-item flex">
                                        <div class="w-2.5 h-2.5 mr-2 bg-pso-tertiary" />
                                        <span class="block max-w-[130px] text-xs text-gray-600">
                                            {{ translate('calculation_tool_data.result.how_its_calculated.left_column.graph.legend_two') }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-column w-1/2 pl-6">
                            <p class="text-lg">
                                {{ translate('calculation_tool_data.result.how_its_calculated.right_column.heading') }}
                            </p>
                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.how_its_calculated.right_column.paragraph_one') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark">
                                        {{ scoringStats.directSocialContribution.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.how_its_calculated.right_column.paragraph_two') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark">
                                        {{ scoringStats.indirectSocialContribution.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between mb-[100px]">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.how_its_calculated.right_column.paragraph_three') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark">
                                        {{ scoringStats.totalSocialContribution.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                    </span>
                                </div>
                            </div>

                            <h5 class="mb-2 text-md text-pso-primary">
                                {{ translate('calculation_tool_data.result.how_its_calculated.right_column.your_score') }}
                                {{ scoringStats.totalSocialContribution.toLocaleString('nl', {minimumFractionDigits: 2}) }}<strong class="text-md text-pso-primary">&percnt;</strong>
                            </h5>
                            <div class="flex items-center">
                                <div
                                    :class="scoringStats.directSocialContribution > 0 ? 'border-0 bg-pso-primary w-[150px]' : 'border border-dashed border-blue-1000'"
                                    class="mr-2 py-1 px-2"
                                >
                                    <span
                                        :class="scoringStats.directSocialContribution > 0 ? 'text-white' : 'text-gray-600'"
                                        class="block text-md text-center"
                                    >
                                        {{ scoringStats.directSocialContribution.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                    </span>
                                </div>
                                <div class="mr-2 text-md text-pso-primary">
                                    &plus;
                                </div>
                                <div
                                    :class="scoringStats.indirectSocialContribution > 0 ? 'border-0 bg-pso-tertiary w-[88px]' : 'border border-dashed border-blue-600'"
                                    class="py-1 px-2"
                                >
                                    <span
                                        :class="scoringStats.indirectSocialContribution > 0 ? 'text-white' : 'text-gray-600'"
                                        class="block text-md text-center"
                                    >
                                        {{ scoringStats.indirectSocialContribution.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="step-3-summary-section section border-b-2 border-solid border-blue-400 pb-10">
                    <h3 class="text-xl mt-10 font-bold text-pso-primary">
                        {{ translate('calculation_tool_data.result.result_block_3.title') }}
                    </h3>
                    <h4 class="mt-6 text-pso-dark text-lg">
                        {{ translate('calculation_tool_data.result.result_block_3.sub_title1') }}
                    </h4>
                    <app-show-more
                        :btn-show-more-text="translate('components.show-more-element.button.more_info')"
                        btn-toggle-hidden-part-dusk-prop="btn-toggle-hidden-part-1"
                        button-position="absolute"
                        class="pt-6"
                        top-position="-23px"
                    >
                        <div class="flex mb-6">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_1_1') }}
                            </p>
                            <span class="text-pso-dark text-md inline w-1/4 text-right">
                                {{ scoringStats.companySizeEmployee.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                            </span>
                        </div>
                        <div class="flex mb-6">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_1_2') }}
                            </p>
                            <span class="text-pso-dark text-md inline w-1/4 text-right">
                                {{ scoringStats.standardSizeClass }}
                            </span>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_1_3') }}
                            </p>
                            <span class="text-pso-dark text-md inline w-1/4 text-right">
                                {{ scoringStats.companySizeFTE.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                            </span>
                        </div>
                        <div class="flex mb-6">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_1_4') }}
                            </p>
                            <span class="text-pso-dark text-md inline w-1/4 text-right">
                                {{ scoringStats.companySizeFTESWEmployee.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                            </span>
                        </div>
                    </app-show-more>
                    <h4 class="text-pso-dark text-lg mb-6">
                        {{ translate('calculation_tool_data.result.result_block_3.sub_title2') }}
                    </h4>
                    <div class="flex mb-4">
                        <p class="text-pso-dark text-md inline w-3/4">
                            {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_1') }}
                        </p>
                        <span class="text-pso-dark text-md inline w-1/4 text-right">
                            {{ scoringStats.totalGroupCount.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                        </span>
                    </div>
                    <div class="flex mb-4">
                        <p class="text-pso-dark text-md inline w-3/4">
                            {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_2') }}
                        </p>
                        <div class="w-1/4 text-right flex">
                            <span class="w-[200px] inline">
                                <span
                                    :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.www)"
                                    class="loading-bar bg-pso-primary h-[16px] block"
                                />
                            </span>
                            <span class="text-pso-primary text-md inline text-right ml-4">
                                {{ scoringStats.startingPositionCount.www }}
                            </span>
                        </div>
                    </div>
                    <div class="flex mb-4">
                        <p class="text-pso-dark text-md inline w-3/4">
                            {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_3') }}
                        </p>
                        <div class="w-1/4 text-right flex">
                            <span class="w-[200px] inline">
                                <span
                                    :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.wajong)"
                                    class="loading-bar bg-pso-primary h-[16px] block"
                                />
                            </span>
                            <span class="text-pso-primary text-md inline text-right ml-4">
                                {{ scoringStats.startingPositionCount.wajong }}
                            </span>
                        </div>
                    </div>
                    <div class="flex mb-4">
                        <p class="text-pso-dark text-md inline w-3/4">
                            {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_4') }}
                        </p>
                        <div class="w-1/4 text-right flex">
                            <span class="w-[200px] inline">
                                <span
                                    :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.ww)"
                                    class="loading-bar bg-pso-primary h-[16px] block"
                                />
                            </span>
                            <span class="text-pso-primary text-md inline text-right ml-4">
                                {{ scoringStats.startingPositionCount.ww }}
                            </span>
                        </div>
                    </div>
                    <app-show-more
                        :btn-show-more-text="translate('components.show-more-element.button.more_info')"
                        btn-toggle-hidden-part-dusk-prop="btn-toggle-hidden-part-2"
                        button-position="absolute"
                        top-position="-174px"
                    >
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_5') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.swIndicated)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.swIndicated }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_6') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.actWithoutWage)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.actWithoutWage }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_7') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.actWithWage)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.actWithWage }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_8') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.bblLevel1)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.bblLevel1 }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_9') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.bblLevel2)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.bblLevel2 }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_10') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.bblLevel3)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.bblLevel3 }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_11') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.vsoOrProWithoutRegistration)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.vsoOrProWithoutRegistration }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_12') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.vsoOrProWithRegistration)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.vsoOrProWithRegistration }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_13') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.wiw)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.wiw }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_14') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.hearingLoss)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.hearingLoss }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_15') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.youngWML)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.youngWML }}
                                </span>
                            </div>
                        </div>
                        <div class="flex">
                            <p class="text-pso-dark text-md inline w-3/4">
                                {{ translate('calculation_tool_data.result.result_block_3.paragraph_2_16') }}
                            </p>
                            <div class="w-1/4 text-right flex">
                                <span class="w-[200px] inline">
                                    <span
                                        :style="'width: '+calculatedWidth(scoringStats.startingPositionCount.other)"
                                        class="loading-bar bg-pso-primary h-[16px] block"
                                    />
                                </span>
                                <span class="text-pso-primary text-md inline text-right ml-4">
                                    {{ scoringStats.startingPositionCount.other }}
                                </span>
                            </div>
                        </div>
                    </app-show-more>
                </section>

                <section class="scoring-information pb-10">
                    <h3 class="text-xl mt-10 mb-6 font-bold text-pso-primary">
                        {{ translate('calculation_tool_data.result.other_certificates.section_heading') }}
                    </h3>
                    <div class="columns flex">
                        <div class="left-column w-1/2 pr-6">
                            <p class="text-lg mb-6">
                                {{ translate('calculation_tool_data.result.other_certificates.left_column.heading') }}
                            </p>

                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.other_certificates.left_column.paragraph_one') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark">
                                        {{ scoringStats.thirtyTotalEmployee.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.other_certificates.left_column.paragraph_two') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark">
                                        {{ scoringStats.thirtyPlusFTE.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.other_certificates.left_column.paragraph_three') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark font-bold">
                                        {{ scoringStats.thirtyPlusTotalWeight.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between">
                                <div class="w-9/12">
                                    <span class="text-md text-pso-primary">
                                        {{ translate('calculation_tool_data.result.other_certificates.left_column.paragraph_four') }}
                                    </span>
                                    <Popper
                                        :arrow="true"
                                        placement="top"
                                    >
                                        <div class="ml-1">
                                            <img
                                                :src="iconInfo"
                                                alt="info"
                                                class="hover:cursor-pointer"
                                            >
                                        </div>
                                        <template #content>
                                            {{ translate('calculation_tool_data.result.other_certificates.left_column.info_box') }}
                                        </template>
                                    </Popper>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-primary font-bold">
                                        {{ scoringStats.thirtyPlusFinalAssessment }}
                                    </span>
                                </div>
                            </div>

                            <div class="scoring-chart-wrapper flex items-center">
                                <div class="scoring-chart relative w-1/2 text-center">
                                    <Doughnut
                                        :data="chartDataOtherCertificates"
                                        :options="chartOptions"
                                    />
                                    <div
                                        class="doughnut-middle-container absolute top-[85px] left-1/2 flex flex-col justify-center items-center w-[150px] h-[150px] rounded-full outline-0 bg-gray-70 -translate-x-1/2 -z-1 "
                                    >
                                        <span class="block font-bold text-pso-primary text-md text-center">
                                            {{ scoringStats.thirtyPlusScore.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                        </span>
                                    </div>
                                </div>
                                <div class="scoring-chart-legend w-1/2 pl-4">
                                    <div class="legend-item flex mb-2">
                                        <div class="w-2.5 h-2.5 mr-2 bg-pso-primary" />
                                        <span class="block max-w-[130px] text-xs text-gray-600">
                                            {{ translate('calculation_tool_data.result.other_certificates.left_column.graph.legend_one') }}
                                        </span>
                                    </div>
                                    <div class="legend-item flex">
                                        <div class="w-2.5 h-2.5 mr-2 bg-pso-tertiary" />
                                        <span class="block max-w-[130px] text-xs text-gray-600">
                                            {{ translate('calculation_tool_data.result.other_certificates.left_column.graph.legend_two') }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-column w-1/2 pl-6">
                            <p class="text-lg mb-6">
                                {{ translate('calculation_tool_data.result.other_certificates.right_column.heading') }}
                            </p>

                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.other_certificates.right_column.paragraph_one') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark">
                                        {{ scoringStats.qbaTotalEmployee.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.other_certificates.right_column.paragraph_two') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark">
                                        {{ scoringStats.qbaWeightFTE25H.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between mb-4">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.other_certificates.right_column.paragraph_three') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-dark">
                                        {{ scoringStats.qbaWightFTE31H.toLocaleString('nl', {minimumFractionDigits: 2}) }}
                                    </span>
                                </div>
                            </div>
                            <div class="scoring-info-item flex justify-between">
                                <div class="w-11/12">
                                    <span class="text-md text-pso-dark">
                                        {{ translate('calculation_tool_data.result.other_certificates.right_column.paragraph_four') }}
                                    </span>
                                </div>
                                <div>
                                    <span class="w-full text-md text-pso-primary font-bold">
                                        {{ scoringStats.qbaScore.toLocaleString('nl', {minimumFractionDigits: 2}) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="action-button text-center">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white border-blue-800 bg-pso-primary"
                        dusk="next-page-button"
                        @click="goToTheNextStep()"
                    >
                        {{
                            translate('calculation_tool_data.result.button_go_next_step')
                        }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CalculationTool from '../../../CalculationTool/CalculationTool';
import CalculationToolData from "../../../CalculationToolData/CalculationToolData";
import CalculationToolHeader from '../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue';
import BackLinkButton from '../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue';
import ShowMoreElement from "../../../Templates/Parts/ShowMoreElement/Vue/ShowMoreElement.vue";
import Popper from 'vue3-popper';
import ScoreBar from "../../../Tools/Parts/ScoreBar/Vue/ScoreBar.vue";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Doughnut} from 'vue-chartjs';
import _ from "lodash";
import Score from "../../Score";
import {getSteps} from "../../../_config/Steps"

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
    components: {
        CalculationToolHeader,
        'app-back-button': BackLinkButton,
        'app-show-more': ShowMoreElement,
        Popper,
        ScoreBar,
        Doughnut,
    },

    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            iconInfo: new URL('/resources/images/icon_info.svg', import.meta.url).href,
            scoreBarHeading: this.translate('calculation_tool_data.result.aspirant_title'),
            scoreBarParagraph: this.translate('calculation_tool_data.result.aspirant_paragraph'),
            scoringStats: {
                scorePercentage: 0,
                totalEmployees: 0,
                totalGroupCount: 0,
                calculatedEmployeesScore: 0,
                directSocialContribution: 0.0,
                indirectSocialContribution: 0.0,
                totalSocialContribution: 0,
                thirtyTotalEmployee: 0,
                thirtyPlusFTE: 0,
                thirtyPlusScore: 0.0,
                thirtyPlusFinalAssessment: '',
                thirtyPlusTotalWeight: 0.0,
                thirtyPlusDVBTotalWeight: 0.0,
                qbaTotalEmployee: 0.0,
                qbaWeightFTE25H: 0.0,
                qbaWightFTE31H: 0.0,
                qbaScore: 0.0,
                companySizeFTE: 0,
                standardSizeClass: '0',
                companySizeEmployee: 0,
                companySizeFTESWEmployee: 0,
                startingPositionCount: {
                    swIndicated: 0,
                    actWithoutWage: 0,
                    actWithWage: 0,
                    www: 0,
                    wajong: 0,
                    bblLevel1: 0,
                    bblLevel2: 0,
                    bblLevel3: 0,
                    vsoOrProWithoutRegistration: 0,
                    vsoOrProWithRegistration: 0,
                    ww: 0,
                    wiw: 0,
                    hearingLoss: 0,
                    youngWML: 0,
                    other: 0
                }
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: false,
                    },
                    subtitle: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                cutout: 40,
            },
            chartDataScoringStats: {
                datasets: [
                    {
                        labels: [],
                        backgroundColor: [
                            '#015D9F',
                            '#0CA3D4',
                        ],
                        data: [
                            5, 95
                        ],
                        offset: [0, 60],
                        borderWidth: [8, 0],
                        borderJoinStyle: 'mitel',
                        borderColor: [
                            '#015D9F',
                            '#0CA3D4',
                        ],
                        hoverRadius: [],
                        hoverBorderWidth: [4, 0],
                    }
                ],
            },
            chartDataOtherCertificates: {
                datasets: [
                    {
                        labels: [],
                        backgroundColor: [
                            '#015D9F',
                            '#0CA3D4',
                        ],
                        data: [
                            3, 97
                        ],
                        offset: [0, 60],
                        borderWidth: [8, 0],
                        borderJoinStyle: 'mitel',
                        borderColor: [
                            '#015D9F',
                            '#0CA3D4',
                        ],
                        hoverRadius: [],
                        hoverBorderWidth: [4, 0],
                    }
                ],
            },
        };
    },

    computed: {
        ...mapGetters('calculation_tool', ['calculation_tool', 'score']),
        ...mapGetters('calculation_tool_data', ['calculation_tool_data']),
        ...mapGetters('score', ['norms']),
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationTool.getScore(this.calculationToolIdProp);
        CalculationToolData.get(this.calculationToolIdProp);
        Score.getNorms(this.calculationToolIdProp);
        Score.getScoringStats(this.calculationToolIdProp)
            .then((response) => {
                this.scoringStats = response;

                if (this.scoringStats.scorePercentage < this.norms.basis_norm) {
                    this.scoreBarHeading = this.translate('calculation_tool_data.result.aspirant_title');
                    this.scoreBarParagraph = this.translate('calculation_tool_data.result.aspirant_paragraph');
                } else if (this.scoringStats.scorePercentage >= this.norms.basis_norm && this.scoringStats.scorePercentage < this.norms.norm_trede_1) {
                    this.scoreBarHeading = this.translate('calculation_tool_data.result.basis_title');
                    this.scoreBarParagraph = this.translate('calculation_tool_data.result.basis_paragraph');
                } else if (this.scoringStats.scorePercentage >= this.norms.norm_trede_1 && this.scoringStats.scorePercentage < this.norms.norm_trede_2) {
                    this.scoreBarHeading = this.translate('calculation_tool_data.result.trede_1_title');
                    this.scoreBarParagraph = this.translate('calculation_tool_data.result.trede_1_paragraph');
                } else if (this.scoringStats.scorePercentage >= this.norms.norm_trede_2 && this.scoringStats.scorePercentage < this.norms.norm_trede_3) {
                    this.scoreBarHeading = this.translate('calculation_tool_data.result.trede_2_title');
                    this.scoreBarParagraph = this.translate('calculation_tool_data.result.trede_2_paragraph');
                } else if (this.scoringStats.scorePercentage >= this.norms.norm_trede_3) {
                    this.scoreBarHeading = this.translate('calculation_tool_data.result.trede_3_title');
                    this.scoreBarParagraph = this.translate('calculation_tool_data.result.trede_3_paragraph');
                }
            });
    },
    methods: {
        previousStep() {
            window.location = route('calculation-tool-data.purchase-data', { calculationTool: this.calculationToolIdProp });
        },
        calculatedWidth(val) {
            let biggestValue = _.max(_.values(this.scoringStats.startingPositionCount));
            return ((val * 100) / biggestValue) + '%';
        },
        goToTheNextStep() {
            CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().checklist})
                .then((response) => {
                    if (response.status === 200) {
                        window.location = route('calculation-tool-checklist.index', { calculationTool: this.calculationToolIdProp });
                    }
                }).catch((error) => {
                    console.log(error.response.data.data.message);
                });
        },
    }
};
</script>
