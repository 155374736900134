import Base from '../Base';
import intro from './Intro';
import checklistForm from './ChecklistForm';
import store from "../../Store/store";

class Checklist extends Base {
    static getComponents() {
        return {
            'checklist-intro': intro,
            'checklist-form': checklistForm,
        };
    }

    static getFormQuestions(payload) {
        return store.dispatch('checklist/getFormQuestions', payload);
    }

    static storeResponses(payload) {
        return store.dispatch('checklist/storeResponses', payload);
    }
}

export default Checklist;
