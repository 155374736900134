import {createApp} from 'vue';
import _ from "lodash";
import childOrganisationDetail from "./Vue/ChildOrganisationDetail.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {childOrganisationDetail: childOrganisationDetail},
    }
    return createApp(_.merge(options, vueOptions));
}
