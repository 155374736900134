<template>
    <div class="gray-section border border-gray-400 rounded-lg drop-shadow-lg mb-10 p-8">
        <h4 class="heading mb-6 text-pso-dark text-xl font-medium">
            {{ translate('calculation_tool_data.target_group_data.filled_in_data') }}
        </h4>
        <div class="w-2/3 inline-block">
            <div class="info-line flex items-center mb-4">
                <div class="w-[165px] text-pso-dark text-md">
                    {{ translate('calculation_tool_data.target_group_data.reference_year') }}
                </div>
                <div
                    class="text-pso-dark text-lg"
                    dusk="grey-dates"
                >
                    {{ calculationToolDataProp.startDate }}
                    t/m
                    {{ calculationToolDataProp.referenceDate }}
                </div>
            </div>
            <div class="info-line flex items-center">
                <div class="w-[165px] text-pso-dark text-md">
                    {{ translate('calculation_tool_data.target_group_data.cla_hours') }}
                </div>
                <div
                    class="text-pso-dark text-lg"
                    dusk="grey-cao"
                >
                    {{ calculationToolDataProp.cao }}
                </div>
            </div>
        </div>
        <div class="w-1/3 inline-block">
            <div class="info-line flex items-center mb-4">
                <div class="w-[165px] text-pso-dark text-md">
                    {{ translate('calculation_tool_data.target_group_data.total_employee') }}
                </div>
                <div
                    class="text-pso-dark text-lg"
                    dusk="grey-employee"
                >
                    {{
                        calculationToolDataProp.employee.toLocaleString('nl-NL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: false,
                        })
                    }}
                </div>
            </div>
            <div class="info-line flex items-center">
                <div class="w-[165px] text-pso-dark text-md">
                    {{ translate('calculation_tool_data.target_group_data.total_fte') }}
                </div>
                <div
                    class="text-pso-dark text-lg"
                    dusk="grey-fte"
                >
                    {{
                        calculationToolDataProp.fte.toLocaleString('nl-NL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: false,
                        })
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        calculationToolDataProp: {
            type: Object,
            required: true,
            default() {
                return {
                    startDate: '',
                    referenceDate: '',
                    cao: '',
                    employee: '',
                    fte: '',
                }
            }
        },
    },
};
</script>
