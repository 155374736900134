import axios from 'axios';

export default {
    namespaced: true,
    state: {
        checklist_data: [],
    },
    getters: {
        form_questions_with_responses(state) {
            return state.form_questions_with_responses;
        },
    },
    mutations: {
        form_questions_with_responses(state, data) {
            state.form_questions_with_responses = data;
        },
    },
    actions: {
        getFormQuestions({commit}, payload) {
            return axios.get('/api/calculation-tools/' + payload.calculationToolId + '/checklist/get-form-questions/' + payload.level)
                .then((response) => commit('form_questions_with_responses', response.data.data));
        },
        storeResponses({}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + '/checklist/', data.data)
                .then((response) => response);
        },
    }
};
