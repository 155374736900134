<template>
    <div class="page organisation-summary">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <BackLinkButton
                    href="#"
                    @click.prevent="previousStep()"
                />

                <h1 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('organisation.summary.page_title') }}
                </h1>
                <ul class="organisations-list mb-10">
                    <li
                        v-for="(organisation, index) in organisationsList"
                        :key="organisation.id"
                        class="flex justify-end mb-8"
                    >
                        <organisation-in-summary
                            :organisation-prop="organisation.data"
                            :main-organisation-prop="mainOrganisation.data"
                            :index-prop="index"
                            :calculation-tool-id-prop="calculationToolIdProp"
                            :current-step-prop="currentStepProp"
                        />
                    </li>
                </ul>

                <div class="more-entities-action-wrapper flex justify-end mb-10">
                    <a
                        class="flex items-center border-gray-400 border-[1px] rounded-l p-4 pl-5 bg-pso-dark"
                        href="#"
                        dusk="btn-add-more-children"
                        @click.prevent="addMoreChildOrganisationsRoute()"
                    >
                        <img
                            :src="iconPlusWhite"
                            class="mr-2 w-4 h-4"
                            alt="add"
                        >

                        <span class="text-white text-md font-medium inline-block">
                            {{ translate('organisation.summary.add_more_children_button_text') }}
                        </span>
                    </a>
                </div>

                <div class="next-step-action-wrapper flex justify-center">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        dusk="btn-next-step"
                        @click.prevent="goToTheNextStep()"
                    >
                        {{ translate('organisation.summary.go_to_next_step_button_text') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Organisation from '@Organisation/Organisation';
import CalculationTool from '@CalculationTool/CalculationTool';
import CalculationToolHeader from '@CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue';
import OrganisationInSummary from '@Organisation/Parts/OrganisationInSummary/Vue/OrganisationInSummary.vue';
import BackLinkButton from '@Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue';
import iconPlusWhite from '@images/icon_plus_white.svg'; // TODO replace the rest of the new URL() with this import functionality, reason: Vite deprecation
import {useStore} from 'vuex';
import {getSteps} from '@_config/Steps';
import {computed, onBeforeMount, ref, watch} from 'vue';

const store = useStore();

const props = defineProps({
    calculationToolIdProp: {
        type: Number,
        default: null,
    },
    currentStepProp: {
        type: Number,
        required: true,
    }
});

const calculationTool = ref(props.calculationToolIdProp);
const organisationsList = ref([]);
const logoUpload = ref(null);
const errors = ref([]);
const mainOrganisation = ref(null);

const receivedOrganisationsList = computed(() => {
    return store.getters["calculation_tool/organisations_list"];
});

watch(receivedOrganisationsList, value => {
    organisationsList.value = receivedOrganisationsList.value;
    mainOrganisation.value = organisationsList.value.filter(organisation => organisation.data.is_main === true)[0];
});

onBeforeMount(() => {
    CalculationTool.get(props.calculationToolIdProp);
    CalculationTool.getOrganisations(props.calculationToolIdProp);
});

function previousStep() {
    window.location = route('organisation.child-organisation-validation-checks', {calculationTool: props.calculationToolIdProp, organisationId: mainOrganisation.value.data.id});
}

function addMoreChildOrganisationsRoute() {
    window.location = route('organisation.child-organisation-validation-checks', {calculationTool: props.calculationToolIdProp, organisationId: mainOrganisation.value.data.id});
}

function goToTheNextStep()
{
    CalculationTool.updateStep({calculationTool: props.calculationToolIdProp, step: getSteps().data})
        .then((response) => {
            if (response.status === 200) {
                window.location = route('calculation-tool-data.index', {calculationTool: props.calculationToolIdProp});
            }
        }).catch((error) => {
            console.log(error.response.data.data.message);
        });
}
</script>
