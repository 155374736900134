<template>
    <div class="page organisation-kvk-request">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button :href="backRoute()" />
                </div>

                <h1
                    class="text-center text-pso-dark text-2xl font-bold mb-10"
                >
                    {{ translate('organisation.child_organisation.kvk.title') }}
                </h1>
                <div id="organisations-list">
                    <p
                        class="mb-10 text-lg text-pso-dark"
                    >
                        {{ translate('organisation.child_organisation.kvk.sub_title_for_list') }}
                    </p>

                    <div
                        v-for="(organisation) in organisations"
                        class="w-1/2 inline-block even:pr-2 odd:pl-2 mb-4"
                    >
                        <div
                            :class="selectedOrganisations.includes(organisation.id) ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                            class="radio-button-wrapper w-full flex flex-row-reverse justify-end border rounded-l"
                        >
                            <label
                                :for="'selected-organisation-'+organisation.id"
                                class="text-md text-pso-dark items-center mb-0 pl-2 pt-4 pb-4 pl-3 w-[455px] hover:cursor-pointer text-[16px] font-normal"
                            >
                                {{ organisation.company_name }}
                                <span class="w-full block text-md mt-3 text-pso-dark">
                                    <span class="mr-4 text-pso-dark">
                                        {{ translate('organisation.kvk.simple_field_label') }}
                                    </span>
                                    {{ organisation.kvk }}
                                </span>
                            </label>
                            <input
                                :id="'selected-organisation-'+organisation.id"
                                v-model="selectedOrganisations"
                                :value="organisation.id"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-[17px] align-top bg-no-repeat bg-center bg-contain float-left ml-4 cursor-pointer "
                                name="selectedOrganisations"
                                type="checkbox"
                            >
                        </div>
                    </div>
                </div>
                <div class="create-from-kvk w-[210px]">
                    <a
                        :href="createFromKvkRoute()"
                        class="flex items-center w-full border-gray-400 border-[1px] rounded-xl p-4 pl-5 bg-white"
                        dusk="btn-create-from-kvk"
                    >
                        <img
                            :src="iconPlus"
                            alt="plus"
                            class="mr-2"
                            dusk="chevron-toggle-timeline"
                        >
                        <span
                            class="text-gray-800 text-lg font-medium"
                        >
                            {{ translate('organisation.kvk.button_add_new') }}
                        </span>
                    </a>
                </div>
                <div class="action-button text-center mt-10">
                    <a
                        :class="selectedOrganisations.length > 0 ? 'bg-pso-orange cursor-pointer' : 'bg-pso-dark cursor-default'"
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white"
                        @click.prevent="nextRoute()"
                    >
                        {{ translate('organisation.index.continue') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Organisation from "../../../../Organisation/Organisation";
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import {mapGetters} from "vuex";
import BackLinkButton from "../../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import CalculationToolHeader from '../../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue';
import _ from "lodash";

export default {
    components: {CalculationToolHeader, 'app-back-button': BackLinkButton},
    computed: {
        ...mapGetters("organisation", ["organisations"]),
    },
    props: {
        calculationToolIdProp: {
            type: String,
            default: null,
        },
        organisationIdProp: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            selectedOrganisations: [],
            errors: {},
            iconPlus: new URL('/resources/images/icon_plus_gray.svg', import.meta.url).href,
        };
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        Organisation.index({filterOrganisations: this.organisationIdProp});
        CalculationTool.getOrganisations(this.calculationToolIdProp).then(() => {
            const mainOrganisation = this.organisations.filter(item => item.is_main !== true);
            this.selectedOrganisations = mainOrganisation.map(item => item.id);
        });
    },
    methods: {
        backRoute() {
            return route('organisation.detail', {
                calculationTool: this.calculationToolIdProp,
                organisationId: this.organisationIdProp
            });
        },
        createFromKvkRoute() {
            return route('organisation.child-organisation-create-from-kvk', {
                calculationTool: this.calculationToolIdProp,
                organisationId: this.organisationIdProp
            });
        },
        nextRoute() {
            if (this.selectedOrganisations.length > 0) {
                let errors = false;
                _.each(this.selectedOrganisations, (organisationId, key) => {
                    Organisation.addOrganisationToCalculationTool(this.calculationToolIdProp, organisationId)
                        .then(() => {
                            this.errors = {};
                        })
                        .catch((error) => {
                            if (error) {
                                this.errors = error.response.data.message;
                                errors = true;
                            }
                        }).then(() => {
                            if (this.selectedOrganisations.length - 1 === key) {
                                if (!errors) {
                                    //redirect to child organisations details
                                    window.location = route('organisation.child-organisation-detail', {
                                        calculationTool: this.calculationToolIdProp,
                                        organisationId: this.organisationIdProp
                                    });
                                }
                            }
                        });
                });
            }
        }
    }
};
</script>
