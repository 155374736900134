<template>
    <BaseDatePickerInput
        :id="id"
        auto-apply
        hide-offset-dates
        locale="nl"
        :model-type="modelType"
        text-input
        :enable-time-picker="false"
        :disabled="disabled"
        :format="format"
        :max-date="maxDate"
        :min-date="minDate"
        :model-value="modelValue"
        :month-picker="monthOnly"
        :placeholder="placeholder"
        
        @update:modelValue="(value) => $emit('update:modelValue', value)"
    />
</template>

<script>

import BaseDatePickerInput from "../../BaseDatePickerInput/Vue/BaseDatePickerInput.vue";

export default {
    components: {
        BaseDatePickerInput,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [Date, String],
            default: null,
        },
        format: {
            type: String,
            default: 'dd-MM-yyyy',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: String,
            default: null,
        },
        minDate: {
            type: Date,
            default: null,
        },
        maxDate: {
            type: Date,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        monthOnly: {
            type: Boolean,
            default: false,
        },
        modelType: {
            type: String,
            default: "dd-MM-yyyy"
        }
    },
    emits: {
        'update:modelValue': String
    }
}
</script>
