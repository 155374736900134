import axios from 'axios';

const BASE_URL = '/api/calculation-tools/';

export default {
    namespaced: true,
    state: {
        calculation_tool: [],
        calculation_tools_list: [],
        auditor_dashboard_data: [],
        organisations_list: [],
        certified_organisations_list: [],
        auditors_list: [],
        requested_auditors_list: [],
        additional_information: [],
        invoice_details: [],
        score: [],
    },
    getters: {
        calculation_tool(state) {
            return state.calculation_tool;
        },
        calculation_tools_list(state) {
            return state.calculation_tools_list;
        },
        auditor_dashboard_data(state) {
            return state.auditor_dashboard_data;
        },
        organisations_list(state) {
            return state.organisations_list;
        },
        certified_organisations_list(state) {
            return state.certified_organisations_list;
        },
        auditors_list(state) {
            return state.auditors;
        },
        requested_auditors_list(state) {
            return state.requested_auditors_list;
        },
        additional_information(state) {
            return state.additional_information;
        },
        invoice_details(state) {
            return state.invoice_details;
        },
        score(state) {
            return state.score;
        },
    },
    mutations: {
        calculation_tool(state, data) {
            state.calculation_tool = data;
        },
        calculation_tools_list(state, data) {
            state.calculation_tools_list = data;
        },
        auditor_dashboard_data(state, data) {
            state.auditor_dashboard_data = data;
        },
        organisations_list(state, data) {
            state.organisations_list = data;
        },
        certified_organisations_list(state, data) {
            state.certified_organisations_list = data;
        },
        auditors_list(state, data) {
            state.auditors = data;
        },
        requested_auditors_list(state, data) {
            state.requested_auditors_list = data;
        },
        additional_information(state, data) {
            state.additional_information = data;
        },
        invoice_details(state, data) {
            state.invoice_details = data;
        },
        score(state, data) {
            state.score = data;
        },
    },
    actions: {
        index({commit}) {
            return axios.get(BASE_URL)
                .then((response) => commit('calculation_tools_list', response.data));
        },
        getAuditorDashboardData({commit}) {
            return axios.get(BASE_URL + 'auditor-dashboard-data')
                .then((response) => commit('auditor_dashboard_data', response.data));
        },
        getPsoDashboardData({commit}) {
            return axios.get(BASE_URL + 'pso-dashboard-data')
                .then((response) => commit('auditor_dashboard_data', response.data));
        },
        store({}, payload) {
            return axios.post(BASE_URL, payload)
                .then((response) => response);
        },
        update({}, payload) {
            return axios.patch(BASE_URL + payload.id, payload)
                .then((response) => response);
        },
        activate({}, payload) {
            return axios.post(BASE_URL + payload.id + '/activate', payload)
                .then((response) => response);
        },
        decline({}, payload) {
            return axios.post(BASE_URL + payload.id + '/decline', payload)
        },
        startAudit({}, payload) {
            return axios.post(BASE_URL + payload.id + '/start-audit', payload)
                .then((response) => response);
        },
        delete({}, payload) {
            return axios.delete(BASE_URL + payload.id, payload)
                .then((response) => response);
        },
        copy({}, payload) {
            return axios.post(BASE_URL + payload.id + '/copy', payload)
                .then((response) => response);
        },
        get({commit}, payload) {
            return axios.get(BASE_URL + payload.id, payload)
                .then((response) => response.data)
                .then((response) => commit('calculation_tool', response.data));
        },
        getScore({commit}, payload) {
            return axios.get(BASE_URL + payload.id + '/score')
                .then((response) => response.data)
                .then((response) => commit('score', response.data));
        },
        getOrganisations({commit}, payload) {
            return axios.get(BASE_URL + payload.calculationToolId + '/organisations/', {
                params: payload.data,
            })
                .then((response) => commit('organisations_list', response.data.data));
        },
        getCertifiedOrganisations({commit}, payload) {

            return axios.get('/api/organisations/certified/' + payload.calculationToolId )
                .then((response) => commit('certified_organisations_list', response.data.data));
        },
        addOrganisationToStore({commit,state}, organisation) {
            let organisation_list = state.organisations_list;
            organisation_list[state.organisations_list.length] = organisation
            commit('organisations_list', organisation_list);
        },
        removeOrganisationFromStore({commit,state}, organisation_id) {
            let organisation_list = state.organisations_list;

            let filter_organisation_list = organisation_list.filter((o) => {
                return o.id !== organisation_id
            });

            commit('organisations_list', filter_organisation_list);
        },
        updateStep({}, payload) {
            return axios.post(BASE_URL + payload.calculationTool + '/update-current-step', payload)
                .then((response) => response);
        },
        notify({}, payload) {
            return axios.post(BASE_URL + payload.calculationTool + '/send-final-request-notification', payload)
                .then((response) => response);
        },
        getAuditors({commit}, payload) {
            return axios.get(BASE_URL + payload.calculationTool + '/auditors', )
                .then((response) => commit('auditors_list', response.data.data));
        },
        getRequestedAuditors({commit}, payload) {
            return axios.get(BASE_URL + payload.calculationTool + '/requested-auditors', )
                .then((response) => commit('requested_auditors_list', response.data.data));
        },
        getAdditionalInformation({commit}, payload) {
            return axios.get(BASE_URL + payload.calculationTool + '/additional-information', )
                .then((response) => commit('additional_information', response.data.data.additionalInformation));
        },
        getInvoiceDetails({commit}, payload) {
            return axios.get(BASE_URL + payload.calculationTool + '/invoice-details',{
                params: {
                    readonly: payload.data // Add the readonly parameter in query
                }
            })
                .then((response) => commit('invoice_details', response.data.data.invoiceDetails));
        },
        updateAdditionalInformation({commit}, payload) {
            return axios.post(BASE_URL + payload.calculationToolId + '/additional-information', payload.data )
                .then((response) => response);
        },
        updateInvoiceDetails({commit}, payload) {
            return axios.post(BASE_URL + payload.calculationToolId + '/invoice-details', payload.data )
                .then((response) => response);
        },
        addAuditorCompany({}, payload) {
            return axios.post(BASE_URL + payload.calculationToolId + '/auditor',  payload.data)
                .then((response) => response);
        },
        sendQuotationToAuditors({}, payload) {
            return axios.post(BASE_URL + payload.calculationToolId + '/auditors/send-quote',  payload.data)
                .then((response) => response);
        },
        validate({}, payload) {
            return axios.get(BASE_URL + payload.calculationTool + '/validate', payload)
                .then((response) => response);
        },
    }
};
