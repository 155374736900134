<template>
    <div>
        <NumberInput
            :id="mainId"
            :disabled="disabled"
            :model-value="mainValue"
            :name="mainName"
            :errors="mainErrors"
            :placeholder="placeholder"
            :step="step"
            :has-decimals="hasDecimals"
            :force-positive="forcePositive"
            :class="{
                'border-1 border-b-0 border-yellow-600 rounded-xl rounded-b-none': auditorFieldEnabled,
                'rounded-xl border-gray-200': !auditorFieldEnabled
            }"

            @update:modelValue="(value) => updateMainValue(value)"
        />
        <div v-if="auditorFieldEnabled">
            <AuditorComment
                v-if="auditorFieldEnabled"
                :id="auditorId"
                :model-value="auditorValue"
                :name="auditorName"
                :errors="auditorErrors"
                class="border-yellow-600 rounded-b-xl"

                @update:modelValue="(value) => $emit('update:auditorValue', value)"
            />
            <slot />
        </div>

        <div
            v-if="mainErrors || auditorErrors"
            class="text-red-500"
        >
            {{ mainErrors ?? auditorErrors ?? '' }}
        </div>
    </div>
</template>
<script>

import AuditorComment from "../../AuditorComment/Vue/AuditorComment.vue";
import NumberInput from "../../../NumberInput/Vue/NumberInput.vue";

export default {
    components: {
        NumberInput,
        AuditorComment,
    },
    props: {
        mainId: {
            type: String,
            required: true,
        },
        auditorId: {
            type: String,
            required: true,
        },
        mainName: {
            type: String,
            required: true,
        },
        auditorName: {
            type: String,
            required: true,
        },
        mainValue: {
            type: [Number, String],
            default: null
        },
        auditorValue: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null,
        },
        mainErrors: {
            type: String,
            default: null,
        },
        auditorErrors: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasDecimals: {
            type: Boolean,
            default: true,
        },
        forcePositive: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:mainValue': [Number, String],
        'update:auditorValue': String,
    },
    data() {
        return {
            initialValue: this.mainValue,
            auditorFieldEnabled: false,
        }
    },
    watch: {
        mainValue: {
            handler(data) {
                this.auditorFieldEnabled = (data !== this.initialValue);
            }
        },
    },
    created() {
        this.auditorFieldEnabled = (this.auditorValue);
    },
    methods: {
        updateMainValue(value) {
            this.$emit('update:mainValue', value);
        }
    }
}
</script>
