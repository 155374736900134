<template>
    <h2 class="text-xl font-medium text-pso-dark mt-9 mb-4">
        {{ translate('organisation.summary.details.invoice.invoice_details') }}
    </h2>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.invoice.company_name') }}
            </span>
        </div>
        <div>
            <span class="text-pso-dark text-lg">
                {{ invoiceDetails.company_name }}
            </span>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.invoice.address') }}
            </span>
        </div>
        <div>
            <span v-if="editing">
                <TextInputWithAuditor
                    v-model:main-value="invoiceDetails.street_name"
                    v-model:auditor-value="invoiceDetailsAuditor.street_name"
                    auditor-id="street_name_auditor"
                    auditor-name="street_name_auditor"
                    main-id="street_name"
                    main-name="street_name"
                    :main-errors="errors['invoice.street_name.value'] ? errors['invoice.street_name.value'][0] : null ?? null"
                    :auditor-errors="errors['invoice.street_name.auditor'] ? errors['invoice.street_name.auditor'][0] : null ?? null"
                    :placeholder="translate('organisation.summary.details.invoice.street_name')"
                />
                <NumberInputWithAuditor
                    v-model:main-value="invoiceDetails.house_number"
                    v-model:auditor-value="invoiceDetailsAuditor.house_number"
                    auditor-id="house_number_auditor"
                    auditor-name="house_number_auditor"
                    main-id="house_number"
                    main-name="house_number"
                    :has-decimals="false"
                    :main-errors="errors['invoice.house_number.value'] ? errors['invoice.house_number.value'][0] : null ?? null"
                    :auditor-errors="errors['invoice.house_number.auditor'] ? errors['invoice.house_number.auditor'][0] : null ?? null"
                    :placeholder="translate('organisation.summary.details.invoice.house_number')"
                />
                <TextInputWithAuditor
                    v-model:main-value="invoiceDetails.addition"
                    v-model:auditor-value="invoiceDetailsAuditor.addition"
                    auditor-id="addition_auditor"
                    auditor-name="addition_auditor"
                    main-id="addition"
                    main-name="addition"
                    :main-errors="errors['invoice.addition.value'] ? errors['invoice.addition.value'][0] : null ?? null"
                    :auditor-errors="errors['invoice.addition.auditor'] ? errors['invoice.addition.auditor'][0] : null ?? null"
                    :placeholder="translate('organisation.summary.details.invoice.addition')"
                />
                <TextInputWithAuditor
                    v-model:main-value="invoiceDetails.postal_code"
                    v-model:auditor-value="invoiceDetailsAuditor.postal_code"
                    auditor-id="postal_code_auditor"
                    auditor-name="postal_code_auditor"
                    main-id="postal_code"
                    main-name="postal_code"
                    :main-errors="errors['invoice.postal_code.value'] ? errors['invoice.postal_code.value'][0] : null ?? null"
                    :auditor-errors="errors['invoice.postal_code.auditor'] ? errors['invoice.postal_code.auditor'][0] : null ?? null"
                    :placeholder="translate('organisation.summary.details.invoice.postal_code')"
                />
                <TextInputWithAuditor
                    v-model:main-value="invoiceDetails.place_name"
                    v-model:auditor-value="invoiceDetailsAuditor.place_name"
                    auditor-id="place_name_auditor"
                    auditor-name="place_name_auditor"
                    main-id="place_name"
                    main-name="place_name"
                    :main-errors="errors['invoice.place_name.value'] ? errors['invoice.place_name.value'][0] : null ?? null"
                    :auditor-errors="errors['invoice.place_name.auditor'] ? errors['invoice.place_name.auditor'][0] : null ?? null"
                    :placeholder="translate('organisation.summary.details.invoice.city')"
                />
            </span>
            <SpanWithAuditor
                v-else
                :comment="invoiceDetailsAuditor.street_name || invoiceDetailsAuditor.house_number || invoiceDetailsAuditor.addition || invoiceDetailsAuditor.postal_code || invoiceDetailsAuditor.place_name"
                :original="organisation.original"
            >
                {{ invoiceDetails.street_name }} {{ invoiceDetails.house_number }}{{ invoiceDetailsAuditor.addition }} {{ invoiceDetails.postal_code }} {{ invoiceDetails.place_name }}
            </SpanWithAuditor>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.invoice.to_the_attention_of') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="invoiceDetails.receiver_name"
                v-model:auditor-value="invoiceDetailsAuditor.receiver_name"
                auditor-id="receiver_name_auditor"
                auditor-name="receiver_name_auditor"
                main-id="receiver_name"
                main-name="receiver_name"
                :main-errors="errors['invoice.receiver_name.value'] ? errors['invoice.receiver_name.value'][0] : null ?? null"
                :auditor-errors="errors['invoice.receiver_name.auditor'] ? errors['invoice.receiver_name.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="invoiceDetailsAuditor.receiver_name"
                :original="organisation.original"
            >
                {{ invoiceDetails.receiver_name }}
            </SpanWithAuditor>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.invoice.email_address') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="invoiceDetails.invoice_email"
                v-model:auditor-value="invoiceDetailsAuditor.invoice_email"
                auditor-id="invoice_email_auditor"
                auditor-name="invoice_email_auditor"
                main-id="invoice_email"
                main-name="invoice_email"
                :main-errors="errors['invoice.invoice_email.value'] ? errors['invoice.invoice_email.value'][0] : null ?? null"
                :auditor-errors="errors['invoice.invoice_email.auditor'] ? errors['invoice.invoice_email.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="invoiceDetailsAuditor.invoice_email"
                :original="organisation.original"
            >
                {{ invoiceDetails.invoice_email }}
            </SpanWithAuditor>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.invoice.mailbox') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="invoiceDetails.mailbox"
                v-model:auditor-value="invoiceDetailsAuditor.mailbox"
                auditor-id="mailbox_auditor"
                auditor-name="mailbox_auditor"
                main-id="mailbox"
                main-name="mailbox"
                :main-errors="errors['invoice.mailbox.value'] ? errors['invoice.mailbox.value'][0] : null ?? null"
                :auditor-errors="errors['invoice.mailbox.auditor'] ? errors['invoice.mailbox.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="invoiceDetailsAuditor.mailbox"
                :original="organisation.original"
            >
                {{ invoiceDetails.mailbox ?? '-' }}
            </SpanWithAuditor>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.invoice.postal_code') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="invoiceDetails.mailbox_postal_code"
                v-model:auditor-value="invoiceDetailsAuditor.mailbox_postal_code"
                auditor-id="mailbox_postal_code_auditor"
                auditor-name="mailbox_postal_code_auditor"
                main-id="mailbox_postal_code"
                main-name="mailbox_postal_code"
                :main-errors="errors['invoice.mailbox_postal_code.value'] ? errors['invoice.mailbox_postal_code.value'][0] : null ?? null"
                :auditor-errors="errors['invoice.mailbox_postal_code.auditor'] ? errors['invoice.mailbox_postal_code.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="invoiceDetailsAuditor.mailbox_postal_code"
                :original="organisation.original"
            >
                {{ invoiceDetails.mailbox_postal_code ?? '-' }}
            </SpanWithAuditor>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.invoice.place_name') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="invoiceDetails.mailbox_place"
                v-model:auditor-value="invoiceDetailsAuditor.mailbox_place"
                auditor-id="mailbox_place_auditor"
                auditor-name="mailbox_place_auditor"
                main-id="mailbox_place"
                main-name="mailbox_place"
                :main-errors="errors['invoice.mailbox_place.value'] ? errors['invoice.mailbox_place.value'][0] : null ?? null"
                :auditor-errors="errors['invoice.mailbox_place.auditor'] ? errors['invoice.mailbox_place.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="invoiceDetailsAuditor.mailbox_place"
                :original="organisation.original"
            >
                {{ invoiceDetails.mailbox_place ?? '-' }}
            </SpanWithAuditor>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.invoice.payment_reference') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="invoiceDetails.payment_reference"
                v-model:auditor-value="invoiceDetailsAuditor.payment_reference"
                auditor-id="payment_reference_auditor"
                auditor-name="payment_reference_auditor"
                main-id="payment_reference"
                main-name="payment_reference"
                :main-errors="errors['invoice.payment_reference.value'] ? errors['invoice.payment_reference.value'][0] : null ?? null"
                :auditor-errors="errors['invoice.payment_reference.auditor'] ? errors['invoice.payment_reference.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="invoiceDetailsAuditor.payment_reference"
                :original="organisation.original"
            >
                {{ invoiceDetails.payment_reference ?? '-' }}
            </SpanWithAuditor>
        </div>
    </div>
</template>

<script>
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import {mapGetters} from "vuex";
import TextInputWithAuditor from "../../../../FormInput/Auditor/TextInputWithAuditor/Vue/TextInputWithAuditor.vue";
import NumberInputWithAuditor from "../../../../FormInput/Auditor/NumberInputWithAuditor/Vue/NumberInputWithAuditor.vue";
import SpanWithAuditor from "../../../../../Inertia/Components/auditor/Partials/SpanWithAuditor.vue";

export default {
    components: {SpanWithAuditor, NumberInputWithAuditor, TextInputWithAuditor},
    inject: ['organisation', 'invoiceDetails', 'invoiceDetailsAuditor', 'errors'],
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        },
        editing: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        ...mapGetters("calculation_tool", {receivedInvoiceDetails: "invoice_details"}),
    },
    watch: {
        receivedInvoiceDetails() {
            if (this.receivedInvoiceDetails !== null) {

                this.invoiceDetailsAuditor = this.receivedInvoiceDetails.auditor ?? {};
                this.invoiceDetails = this.receivedInvoiceDetails.data ?? this.receivedInvoiceDetails;

                // If this is the first time the data is being fetched, store the original values
                if (!this.originalInvoiceDetailsData) {
                    this.originalInvoiceDetailsData = JSON.parse(JSON.stringify(this.invoiceDetails));
                }
                if (!this.originalInvoiceDetailsAuditor) {
                    this.originalInvoiceDetailsAuditor = JSON.parse(JSON.stringify(this.invoiceDetailsAuditor));
                }
            }
        }
    },
    created() {
        CalculationTool.getInvoiceDetails(this.calculationToolIdProp,this.readonly)
        window.mitt.on('reset-changes', () => {
            this.invoiceDetails = { ...this.originalInvoiceDetailsData };
            this.invoiceDetailsAuditor = { ...this.originalInvoiceDetailsAuditor };
        });
        window.mitt.on('saved-changes', () => {
            this.originalInvoiceDetailsData = { ...this.invoiceDetails };
            this.originalInvoiceDetailsAuditor  = { ...this.invoiceDetailsAuditor };
        });
    }
};
</script>
