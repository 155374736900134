<template>
    <input
        :id="id"
        :placeholder="placeholder"
        :type="type"
        :name="name"
        :class="[
            {'border-red-500': errors},
            $attrs.class
        ]"
        :autocomplete="name"
        :value="modelValue"
        class="$attrs.class"
        @input="($event) => onInput($event)"
    >
    <span
        v-if="errors"
        class="text-red-500"
    >
        {{ errors }}
    </span>
</template>
<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
        type: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        errors: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    emits: {
        'update:modelValue': [String, Number]
    },
    methods: {
        onInput($event) {
            this.$emit('update:modelValue', $event.target.value);
        }
    }
}
</script>
