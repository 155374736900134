<template>
    <div
        class="calculation-tool-wrapper flex justify-between"
        :class="{'flex-col': (typeof editFormIsOpen !== 'undefined' && editFormIsOpen) }"
    >
        <div
            v-show="editFormIsOpen"
            class="edit-calculation-tool-wrapper mb-6"
        >
            <div class="form-wrapper flex items-center ">
                <input
                    v-model="calculationToolNewName"
                    type="text"
                    class="form-input w-full max-w-[406px] mr-4 px-6 py-3 rounded-xl border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                    :class="{'border-red-500 w-full': errorMessage}"
                    name="edit-name"
                    autocomplete="edit-name"
                    dusk="input-edit-calculation-tool-name"
                >
                <button
                    class="bg-pso-primary text-white rounded-xl py-2 px-4 mr-4"
                    dusk="btn-save-new-calculation-tool-name"
                    @click="updateCalculationTool(calculationTool)"
                >
                    {{ translate('calculation_tool.list.edit_form.button_save_text') }}
                </button>
                <button
                    class="border-0 text-pso-tertiary bg-none underline py-2"
                    dusk="btn-close-edit-calculation-tool-name-form"
                    @click="toggleCalculationToolEditForm(calculationTool)"
                >
                    {{ translate('calculation_tool.list.edit_form.button_cancel_text') }}
                </button>
            </div>

            <span
                v-if="errorMessage"
                class="text-sm text-red-500"
                role="alert"
                v-text="errorMessage"
            />
        </div>

        <div class="calculation-tool-details mr-auto">
            <h3
                v-if="!editFormIsOpen"
                class="flex items-center mb-6 text-xl text-pso-dark font-medium"
            >
                {{ calculationTool.name }}

                <a
                    v-if="calculationTool.status !== 11"
                    class="edit-action flex ml-5 text-sm text-pso-primary leading-5"
                    href="#"
                    dusk="btn-open-edit-calculation-tool-name-form"
                    @click.prevent="toggleCalculationToolEditForm(calculationTool)"
                >
                    <img
                        :src="iconEdit"
                        alt="edit"
                        class="mr-2"
                    >
                    {{ translate('calculation_tool.list.index.items.details.txt_edit') }}
                </a>
            </h3>

            <div class="details-list">
                <div class="details-item flex justify-between items-center mb-4">
                    <span class="detail-name block w-[140px] mr-4 text-md text-pso-dark">
                        {{ translate('calculation_tool.list.index.items.details.score') }}
                    </span>
                    <span class="detail-value text-lg text-gray-800">
                        {{ scorePercentage }}
                    </span>
                </div>
                <div class="details-item flex justify-between items-center mb-4">
                    <span class="detail-name block w-[140px] mr-4 text-md text-pso-dark">
                        {{ translate('calculation_tool.list.index.items.details.status') }}
                    </span>
                    <span class="detail-value text-lg text-gray-800">
                        {{ calculationTool.status_name }}
                    </span>
                </div>
                <div class="details-item flex justify-between items-center mb-4">
                    <span class="detail-name block w-[140px] mr-4 text-md text-pso-dark">
                        {{ translate('calculation_tool.list.index.items.details.latest_update') }}
                    </span>
                    <span class="detail-value text-lg text-gray-800">
                        {{ calculationTool.updated_at }}
                    </span>
                </div>
            </div>
        </div>

        <div
            v-if="!editFormIsOpen"
            class="calculation-tool-links w-[215px]"
        >
            <button
                class="flex items-center w-full mb-2 border-pso-dark border rounded-l p-4 pl-5 bg-white"
                dusk="go-to-organisation"
                @click.prevent="goToTheOrganisationStep(calculationTool.id)"
                v-if="calculationTool.status !== 11"

            >
                <img
                    :src="iconPencil"
                    alt="details"
                    class="mr-2"
                >

                <span
                    class="text-pso-dark text-md font-medium"
                >
                    {{ translate('calculation_tool.list.index.items.details.button_view') }}
                </span>
            </button>
            <button
                class="flex items-center w-full mb-2 border-pso-dark border rounded-l p-4 pl-5 bg-white"
                dusk="btn-delete-calculation-tool"
                @click.prevent="confirmDeleteAction(calculationTool)"
            >
                <img
                    :src="iconDelete"
                    alt="delete"
                    class="mr-2"
                >

                <span
                    class="text-pso-dark text-md font-medium"
                >
                    {{ translate('calculation_tool.list.index.items.details.button_remove') }}
                </span>
            </button>
            <button
                v-if="calculationTool.status !== 11"
                class="flex items-center w-full border-pso-dark border rounded-l p-4 pl-5 bg-white"
                href="#"
                dusk="btn-copy-calculation-tool"
                @click.prevent="confirmCopyAction(calculationTool)"
            >
                <img
                    :src="iconCopy"
                    alt="copy"
                    class="mr-2"
                >

                <span
                    class="text-pso-dark text-md font-medium"
                >
                    {{ translate('calculation_tool.list.index.items.details.button_copy') }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import CalculationTool from '../../../CalculationTool';
import {mapGetters} from "vuex";
import Score from "../../../../Score/Score";
import {getSteps} from "../../../../_config/Steps";

export default {
    props: {
        calculationToolProp: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            calculationTool: null,
            calculationToolNewName: null,
            editFormIsOpen: false,
            actionConfirmed: false,
            errorMessage: '',
            iconCopy: new URL('/resources/images/icon_copy.svg', import.meta.url).href,
            iconDelete: new URL('/resources/images/icon_delete.svg', import.meta.url).href,
            iconEdit: new URL('/resources/images/icon_pencil_blue.svg', import.meta.url).href,
            iconPencil: new URL('/resources/images/icon_pencil.svg', import.meta.url).href,
            scorePercentage: this.translate('calculation_tool.list.index.items.details.no-info-yet')
        };
    },
    computed: {
        ...mapGetters("calculation_tool", ["calculation_tools_list"]),
    },
    watch: {
        calculationTool: function () {
            Score.getScoringStats(this.calculationTool.id)
                .then((response) => {
                    if(response !== null){
                        this.scorePercentage = response.scorePercentage.toLocaleString('nl', {minimumFractionDigits: 2}) + '%';
                    }
                });
        }
    },
    created() {
        this.calculationTool = this.calculationToolProp;
    },

    methods: {
        updateCalculationTool(calculationTool) {
            let formData = CalculationTool.buildUpdateCalculationToolForm({
                id: calculationTool.id,
                name: this.calculationToolNewName
            });
            CalculationTool.updateCalculationToolForm(formData)
                .then((data) => {
                    this.errorMessage = '';

                    const calculationToolRef = this.calculation_tools_list.find(calculationToolRef => calculationToolRef.id === calculationTool.id);

                    if (calculationToolRef) {
                        calculationToolRef.name = data.data.name;
                    }

                    this.toggleCalculationToolEditForm(calculationTool);
                })
                .catch((error) => {
                    if (error) {
                        this.errorMessage = error.response.data.message;
                    }
                });
        },

        toggleCalculationToolEditForm(calculationTool) {
            this.editFormIsOpen = !this.editFormIsOpen;
            this.calculationToolNewName = this.editFormIsOpen ? calculationTool.name : null;
        },

        copyCalculationTool(calculationTool) {
            CalculationTool.copyCalculationToolForm({id: calculationTool.id})
                .then((data) => {
                    this.errorMessage = '';
                    window.mitt.emit("addToCalculationToolList", data.data);
                })
                .catch((error) => {
                    if (error) {
                        this.showErrorMessage(this.translate('rules.application_error.summary'), error.response.data.message);
                    }
                });
        },

        confirmDeleteAction(calculationTool) {
            this.$confirm.require({
                header: this.translate('calculation_tool.list.delete.confirmation_dialog.title').replace(':name', calculationTool.name),
                message: this.translate('calculation_tool.list.delete.confirmation_dialog.message'),
                acceptLabel: this.translate('calculation_tool.list.delete.confirmation_dialog.confirm_button_text'),
                rejectLabel: this.translate('calculation_tool.list.delete.confirmation_dialog.cancel_button_text'),
                defaultFocus: false,
                accept: () => {
                    this.deleteCalculationTool(calculationTool, {
                        actionType: 'delete',
                        entityData: calculationTool,
                    });
                },
            });

        },

        confirmCopyAction(calculationTool) {
            this.$confirm.require({
                header: this.translate('calculation_tool.list.copy.confirmation_dialog.title').replace(':name', calculationTool.name),
                message: this.translate('calculation_tool.list.copy.confirmation_dialog.message'),
                acceptLabel: this.translate('calculation_tool.list.copy.confirmation_dialog.confirm_button_text'),
                rejectLabel: this.translate('calculation_tool.list.copy.confirmation_dialog.cancel_button_text'),
                defaultFocus: false,
                accept: () => {
                    this.copyCalculationTool(calculationTool);
                },
            });

        },

        deleteCalculationTool(calculationTool, actionData) {
            if (actionData.actionType !== 'delete' || calculationTool !== actionData.entityData) {
                return;
            }

            CalculationTool.deleteCalculationToolForm({id: calculationTool.id})
                .then((data) => {
                    this.errorMessage = '';
                    window.mitt.emit("removeFromCalculationToolList", data.data.id);
                })
                .catch((error) => {
                    if (error) {
                        this.errorMessage = error.response.data.message ?? error;
                    }
                });
        },

        goToTheOrganisationStep(calculationToolId) {
            CalculationTool.validate(calculationToolId).then((response) => {
                if (response.message === 'success') {
                    CalculationTool.updateStep({calculationTool: calculationToolId, step: getSteps().organisation})
                        .then((response) => {
                            if (response.status === 200) {
                                window.location = route('organisation.index', {calculationTool: calculationToolId});
                            }
                        }).catch((error) => {
                            console.log(error.response.data.data.message);
                        });
                }
            }).catch((error) => {
                if (error) {
                    if(422 === error.response.status){
                        this.$confirm.require({
                            header: 'Peildatum',
                            message: 'De gekozen peildatum ligt te ver in het verleden, deze mag maximaal 3 maanden (+1 dag) in het verleden liggen. Vul een nieuwe peildatum in om verder te gaan met de rekentool',
                            acceptLabel: 'Datum aanpassen',
                            rejectLabel: 'Terug',
                            defaultFocus: false,
                            accept: () => {
                                window.location = route('calculation-tool-data.reference-date-cao-hours', calculationToolId)
                            },
                        });

                    }
                }
            });
            
        },
    },
};
</script>
