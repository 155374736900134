<template>
    <div class="container mx-auto">
        <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto mb-5 mt-5">
            <div class="page-header text-pso-primary font-bold text-3xl">
                {{ translate('auth.login.page_title') }}
            </div>
        </div>
        <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto rounded-xl bg-white mb-5">
            <div class="login-form-container pr-8 pb-8 pl-8 pt-8">
                <form
                    id="login_form"
                    class="form"
                    @submit.prevent="loginUser"
                >
                    <slot />
                    <p class="mb-4 text-pso-dark">
                        {{ translate('auth.login.page_subtitle') }}
                    </p>
                    <div class="mb-5">
                        <label
                            for="email"
                            class="form-label block mb-3 text-pso-dark"
                        >{{ translate('auth.login.email') }}</label>

                        <div class="col-md-6">
                            <input
                                id="email"
                                v-model="loginForm.email"
                                type="email"
                                class="form-input px-8 py-3 rounded-xl w-full border-gray-200 placeholder:italic placeholder:lowercase placeholder:text-gray-600"
                                :class="{'border-red-500': errors.email}"
                                name="email"
                                autofocus
                                :placeholder="translate('auth.login.email').toLowerCase()"
                            >

                            <span
                                v-if="errors.email"
                                class="text-sm text-red-500"
                                dusk="email-validation"
                                role="alert"
                                v-html="errors.email !== verificationErrorKey ? translate(errors.email) : translate(verificationErrorKey, translateKeys)"
                            />
                        </div>
                    </div>

                    <div class="mb-5">
                        <label
                            for="password"
                            class="form-label block mb-3 text-pso-dark"
                        >{{ translate('auth.login.password') }}</label>

                        <div class="col-md-6">
                            <input
                                id="password"
                                v-model="loginForm.password"
                                type="password"
                                class="form-input px-8 py-3 rounded-xl w-full border-gray-200 placeholder:italic placeholder:lowercase placeholder:text-gray-600"
                                :class="{'border-red-500': errors.password || errors.email}"
                                name="password"
                                autocomplete="current-password"
                                :placeholder="translate('auth.login.password').toLowerCase()"
                            >

                            <span
                                v-if="errors.password"
                                class="text-sm text-red-500"
                                dusk="password-validation"
                                role="alert"
                                v-text="errors.password"
                            />
                        </div>
                    </div>

                    <div class="row mb-0">
                        <div class="col-md-8 offset-md-4">
                            <button
                                type="submit"
                                class="w-full mt-4 mb-3 px-12 py-4 rounded-xl bg-pso-orange text-white font-medium"
                            >
                                {{ translate('auth.login.confirm') }}
                            </button>

                            <div class="forgot-password-container">
                                <a
                                    dusk="forgot-password-link"
                                    class="text-pso-primary underline hover:no-underline"
                                    :href="forgotPasswordRoute()"
                                >
                                    {{ translate('auth.login.forgot_password') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="already-have-account rounded-b-xl bg-gray-050 text-pso-dark p-8">
                <p class="mb-4 text-pso-dark">
                    {{ translate('auth.login.dont_have_account') }}
                </p>
                <p>
                    <a
                        dusk="create-account-link"
                        class="text-pso-primary underline hover:no-underline"
                        :href="registerFirstStepRoute()"
                    >
                        {{ translate('auth.login.create_account') }}
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import User from "../../../../User/User";


export default {
    data() {
        return {
            loginForm: {},
            failed: null,
            success: null,
            errors: {},
            verificationErrorKey: 'auth.login.account_inactive',
            translateKeys: {
                'here': '<button class="text-blue-1000 underline hover:no-underline" onclick="resendVerification()">' + this.translate('auth.login.account_inactive_here') + '</button>'
            }
        }
    },
    created () {
        window.resendVerification = this.resendVerification.bind(this);
    },
    methods: {
        loginUser() {
            let formData = User.buildLoginForm(this.loginForm);
            User.login(formData)
                .then((data) => {
                    this.$cookies.set('jwt', data['token']);
                    window.location.href = route('dashboard');
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        } else if (error.response.status === 422) {
                            this.$setErrorsFromResponse(error.response.data);
                        } else {
                            this.showErrorMessage();
                        }
                    }
                });
        },
        registerFirstStepRoute() {
            return route('register.show-registration-first-step');
        },
        forgotPasswordRoute() {
            return route('password.request');
        },
        resendVerification() {
            let formData = User.buildLoginForm(this.loginForm);
            User.resendVerification(formData)
                .then(() => {
                    this.errors = {};

                    let dialogData = {
                        body: this.translate('auth.login.resend_verification_email_confirmation'),
                        buttons: [{
                            redirect: route('login.show-login-form'),
                        }]
                    };
                    window.mitt.emit("dialogMessage", dialogData);
                })
        }
    }
}
</script>
