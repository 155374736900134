import Base from "../Base";
import store from "../../Store/store";

class Auditor extends Base {
    static getComponents() {
        return {};
    }

    static cleanExtraInputsForm(data) {
        for (const key in data) {
            if (data.hasOwnProperty(key) && (data[key] === null || data[key] === '')) {
                delete data[key];
            }
        }

        return data;
    }

    static index() {
        return store.dispatch('auditor/index');
    }

    static getExtraInputs(calculationToolId, view) {
        return store.dispatch(
            'auditor/getExtraInputs',
            {
                calculationToolId: calculationToolId,
                view: view
            }
        );
    }

    static storeExtraInputs(calculationToolId, payload) {
        return store.dispatch('auditor/storeExtraInputs', {
            payload: payload,
            calculationToolId: calculationToolId,
        }).then((response) => {
            return response.data;
        });
    }
}

export default Auditor;
