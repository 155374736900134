<template>
    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.phone_number') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="organisation.phone_number"
                v-model:auditor-value="organisationAuditor.phone_number"
                auditor-id="phone_number_auditor"
                auditor-name="phone_number_auditor"
                main-id="phone_number"
                main-name="phone_number"
                :main-errors="errors['organisation.phone_number.value'] ? errors['organisation.phone_number.value'][0] : null ?? null"
                :auditor-errors="errors['organisation.phone_number.auditor'] ? errors['organisation.phone_number.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="organisationAuditor.phone_number"
                :original="organisation.original"
            >
                {{ organisation.phone_number ?? '-' }}
            </SpanWithAuditor>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.email_address') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="organisation.email"
                v-model:auditor-value="organisationAuditor.email"
                auditor-id="email_auditor"
                auditor-name="email_auditor"
                main-id="email"
                main-name="email"
                :main-errors="errors['organisation.email.value'] ? errors['organisation.email.value'][0] : null ?? null"
                :auditor-errors="errors['organisation.email.auditor'] ? errors['organisation.email.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="organisationAuditor.email"
                :original="organisation.original"
            >
                {{ organisation.email ?? '-' }}
            </SpanWithAuditor>
        </div>
    </div>

    <div class="detail-info flex align-items-center mb-4">
        <div class="mr-6">
            <span class="inline-block w-[105px] text-pso-dark text-md">
                {{ translate('organisation.summary.details.website') }}
            </span>
        </div>
        <div>
            <TextInputWithAuditor
                v-if="editing"
                v-model:main-value="organisation.website"
                v-model:auditor-value="organisationAuditor.website"
                auditor-id="website_auditor"
                auditor-name="website_auditor"
                main-id="website"
                main-name="website"
                :main-errors="errors['organisation.website.value'] ? errors['organisation.website.value'][0] : null ?? null"
                :auditor-errors="errors['organisation.website.auditor'] ? errors['organisation.website.auditor'][0] : null ?? null"
            />
            <SpanWithAuditor
                v-else
                :comment="organisationAuditor.website"
                :original="organisation.original"
            >
                {{ organisation.website ?? '-' }}
            </SpanWithAuditor>
        </div>
    </div>
</template>
<script>
import TextInputWithAuditor from "../../../../FormInput/Auditor/TextInputWithAuditor/Vue/TextInputWithAuditor.vue";
import SpanWithAuditor from "../../../../../Inertia/Components/auditor/Partials/SpanWithAuditor.vue";

export default {
    components: {SpanWithAuditor, TextInputWithAuditor},
    inject: ['organisation', 'organisationAuditor'],
    props: {
        editing: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: null,
        },
    },
}
</script>
