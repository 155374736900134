<template>
    <div>
        <MultiSelectInput
            :id="mainId"
            :options="options"
            :groups="groups"
            :group-label="groupLabel"
            :group-values="groupValues"
            :can-clear="canClear"
            :searchable="searchable"
            :model-value="mainValue"
            :name="mainName"
            :errors="mainErrors"
            :placeholder="placeholder"
            :class="{
                '!rounded-none !border-b-0 !rounded-t-xl': !seperated && auditorFieldEnabled,
                '!rounded-xl': seperated && auditorFieldEnabled,
                '!border-t-[1px] !border-pso-orange': auditorFieldEnabled,
                '!rounded-xl !border-gray-200': !auditorFieldEnabled
            }"

            :dusk="dusk"

            @update:modelValue="(value) => $emit('update:mainValue', value)"
        />
        <div v-if="auditorFieldEnabled">
            <span
                v-if="seperated"
                class="text-lg text-pso-dark inline-block mt-4 mb-2"
            >
                {{ translate('auditor.input.explanation') }}*
            </span>

            <AuditorComment
                v-if="auditorFieldEnabled"
                :id="auditorId"
                :model-value="auditorValue"
                :name="auditorName"
                :errors="auditorErrors"
                class="border-pso-orange rounded-b-lg"
                :class="{
                    'rounded-b-lg': !seperated,
                    'rounded-lg': seperated
                }"

                @update:modelValue="(value) => $emit('update:auditorValue', value)"
            />
            <slot />
        </div>
    </div>
</template>
<script>

import AuditorComment from "../../AuditorComment/Vue/AuditorComment.vue";
import MultiSelectInput from "../../../MultiSelectInput/Vue/MultiSelectInput.vue";

export default {
    components: {
        MultiSelectInput,
        AuditorComment,
    },
    props: {
        mainId: {
            type: String,
            required: true,
        },
        auditorId: {
            type: String,
            required: true,
        },
        mainName: {
            type: String,
            required: true,
        },
        auditorName: {
            type: String,
            required: true,
        },
        options: {
            type: [Array, Object, Function],
            required: false,
            default: () => ([])
        },
        mainValue: {
            type: [String, Number],
            default: null
        },
        auditorValue: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null,
        },
        mainErrors: {
            type: String,
            default: null,
        },
        auditorErrors: {
            type: String,
            default: null,
        },
        dusk: {
            type: String,
            default: null
        },
        seperated: {
            type: Boolean,
            default: false
        },
        canClear: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false
        },
        groups: {
            type: Boolean,
            default: false
        },
        groupLabel: {
            type: String,
            default: null
        },
        groupValues: {
            type: String,
            default: null
        }
    },
    emits: {
        'update:mainValue': String,
        'update:auditorValue': String,
    },
    data() {
        return {
            initialValue: this.mainValue,
            auditorFieldEnabled: false,
        }
    },
    watch: {
        mainValue: {
            handler(data) {
                this.auditorFieldEnabled = (data !== this.initialValue);
            }
        }
    },
    created() {
        this.auditorFieldEnabled = (this.auditorValue);
    },
}
</script>
