<template>
    <div class="radio-item">
        <input
            :id="id"
            type="radio"
            :name="name"
            :value="value"
            class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            :checked="checked"

            @change="($event) => $emit('update:modelValue', $event.target.value)"
        >
        <label
            v-if="label"
            :for="id"
            class="mr-4"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [Boolean, String, Number],
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
        checked: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
