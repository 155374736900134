import axios from 'axios';

export default {
    namespaced: true,
    state: {
        comments: [],
    },
    getters: {
        comments(state) {
            return state.comments;
        }
    },
    mutations: {
        comments(state, data) {
            state.comments = data;
        }
    },
    actions: {
        getComments({commit}, data) {
            return axios.get('/api/pso/calculation-tool/' + data.calculationToolId + '/view/' + data.view + '/comments')
                .then((response) => commit('comments', response.data.data));
        },
        storeComments({}, data) {
            return axios.post('/api/pso/calculation-tool/' + data.calculationToolId + '/comments', data.payload);
        },
    }
};
