import {createApp} from 'vue';
import _ from "lodash";
import referenceDateCaoHours from "./Vue/ReferenceDateCaoHours.vue";
import store from "../../../Store/store";

export default (selector, options) => {
    let vueOptions = {
        store,
        el: selector,
        components: {referenceDateCaoHours: referenceDateCaoHours},
    };

    return createApp(_.merge(options, vueOptions));
}
