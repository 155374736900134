import {createApp} from 'vue';
import _ from "lodash";
import childOrganisationCreateFromKvk from "./Vue/ChildOrganisationCreateFromKvk.vue";
export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {childOrganisationCreateFromKvk: childOrganisationCreateFromKvk},
    }

    return createApp(_.merge(options, vueOptions));
}