<template>
    <div>
        <textarea
            :id="id"
            type="text"
            :class="[
                'resize-y form-input px-6 py-3 my-2 w-full rounded-xl border-pso-secondary text-lg placeholder:lowercase placeholder:text-gray-600',
                {'border-red-500': errors}
            ]"
            :name="name"
            :placeholder="translate('auditor.input.explanation')"
            :value="modelValue"

            @input="($event) => onInput($event)"
        />
        <div v-if="errors" class="mb-2">
            <span
                dusk="{{ duskErrorName }}"
                class="text-sm text-red-500"
                role="alert"
                v-text="errors"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        errors: {
            type: String,
            default: null,
        },
    },
    emits: {
        'update:modelValue': String
    },
    methods: {
        onInput($event) {
            this.$emit('update:modelValue', $event.target.value);
        }
    }
}

</script>
