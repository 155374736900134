import axios from 'axios';

const BASE_URL = '/api/';

export default {
    namespaced: true,
    state: {
        roles: [],
    },
    getters: {
        roles(state) {
            return state.roles;
        }
    },
    mutations: {
        roles(state, data) {
            state.roles = data;
        },
    },
    actions: {
        update({}, payload) {
            return axios.patch(BASE_URL + 'user/' + payload.id, payload.data)
                .then((response) => response);
        },
        register({}, payload) {
            return axios.post(BASE_URL + 'register', payload)
                .then((response) => response);
        },
        login({}, payload) {
            return axios.post(BASE_URL + 'login', payload)
                .then((response) => response);
        },
        getRoles({commit}) {
            return axios.get(BASE_URL + 'user/roles')
                //.then((response) => response.data)
                .then((response) => commit('roles', response.data));
        },
        sendForgotPasswordLink({}, payload) {
            return axios.post(BASE_URL + 'forgot-password', payload)
                .then((response) => response);
        },
        resendVerification({}, payload) {
            return axios.post(BASE_URL + 'resend-verification', payload)
                .then((response) => response);
        },
        saveResetPassword({}, payload) {
            return axios.post(BASE_URL + 'reset-password', payload)
                .then((response) => response);
        },
        saveSelectedTwoFactorAuth({}, payload) {
            return axios.post(BASE_URL + 'auth/2fa/save', payload)
                .then((response) => response);
        },
        checkTwoFactorAuthCode({}, payload) {
            return axios.post(BASE_URL + 'auth/2fa/check', payload)
                .then((response) => response);
        },
    }
};
