<template>
    <div :class="['organisation-wrapper relative w-full', organisation.is_main ? 'is-main' : 'ml-100 pl-[100px]']">
        <h2 class="mb-2 text-xl font-medium text-pso-dark">
            {{ elementTitle }}
            <span v-if="organisation.is_main === false">{{ index }}</span>
        </h2>

        <div class="organisation-box bg-gray-050 rounded shadow p-6 z-1">
            <div class="box-content flex">
                <div class="flex items-start">
                    <div
                        :class="[
                            'logo-wrapper flex items-center justify-center mr-6 cursor-pointer',
                            organisation.logo ? 'has-logo' : 'no-logo bg-gray-200',
                            organisation.is_main ? 'w-[100px] h-[100px]' : 'w-[80px] h-[80px]',
                        ]"
                    >
                        <div>
                            <img
                                v-if="organisation.logo"
                                :alt="`${organisation.company_name} Logo`"
                                :src="organisation.logo_base64"
                                class="logo-image inline-block m-0 w-[100px]"
                                @click.prevent="openUploadFileDialog"
                            >

                            <div
                                v-if="!organisation.logo"
                                class="flex w-full h-full items-center justify-center"
                            >
                                <button
                                    class="text-center bg-none m-0 pt-2 pb-4 px-1 border-0"
                                    @click.prevent="openUploadFileDialog"
                                >
                                    <span class="inline-block mb-2 text-xs text-gray-600 text-center">
                                        {{ translate('organisation.summary.upload_logo_message') }}
                                    </span>
                                    <img
                                        :src="iconUpload"
                                        alt="upload"
                                        class="block mx-auto"
                                    >
                                </button>
                            </div>

                            <input
                                ref="logoUploadInput"
                                class="hidden"
                                name="logoUploadInput"
                                type="file"
                                @change="uploadLogoImage($event)"
                            >
                        </div>
                    </div>
                    <div class="company-details">
                        <div class="company-name">
                            <div class="detail-info flex align-items-center mb-4">
                                <div class="mr-6">
                                    <span class="inline-block w-[105px] text-pso-dark text-md">
                                        {{ translate('organisation.summary.details.company_name') }}
                                    </span>
                                </div>
                                <div>
                                    <TextInput
                                        v-if="editing"
                                        v-model:model-value="organisation.company_name"
                                        main-id="company_name"
                                        main-name="company_name"
                                        :errors="errors['company_name'] ? errors['company_name'][0] : null"
                                        class="rounded-xl border-gray-200 w-1/2"
                                    />
                                    <span v-else class="text-pso-dark text-lg">
                                        {{ organisation.company_name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Transition name="slide-fade">
                            <div
                                v-show="isExpanded"
                                class="company-more-details"
                            >
                                <div class="detail-info flex align-items-center mb-4">
                                    <div class="mr-6">
                                        <span class="inline-block w-[105px] text-pso-dark text-md">
                                            {{ translate('organisation.summary.details.kvk') }}
                                        </span>
                                    </div>
                                    <div>
                                        <TextInput
                                            v-if="editing"
                                            v-model:model-value="organisation.kvk"
                                            main-id="company_name"
                                            main-name="company_name"
                                            :errors="errors['kvk'] ? errors['kvk'][0] : null"
                                            class="rounded-xl border-gray-200 w-1/2"
                                        />
                                        
                                        <span v-else class="text-pso-dark text-lg">
                                            {{ organisation.kvk }}
                                        </span>
                                    </div>
                                </div>

                                <div class="detail-info flex align-items-center mb-4">
                                    <div class="mr-6">
                                        <span class="inline-block w-[105px] text-pso-dark text-md">
                                            {{ translate('organisation.summary.details.address') }}
                                        </span>
                                    </div>
                                    <div>
                                        <TextInput
                                            v-if="editing"
                                            v-model:model-value="organisation.street_name"
                                            main-id="street_name"
                                            main-name="street_name"
                                            :errors="errors['street_name'] ? errors['street_name'][0] : null"
                                            class="rounded-xl border-gray-200 w-1/2"
                                        />
                                        <TextInput
                                            v-if="editing"
                                            v-model:model-value="organisation.street_number"
                                            main-id="street_number"
                                            main-name="street_number"
                                            :errors="errors['street_number'] ? errors['street_number'][0] : null"
                                            class="rounded-xl border-gray-200 w-1/2"
                                        />
                                        <TextInput
                                            v-if="editing"
                                            v-model:model-value="organisation.addition"
                                            placeholder="Toevoeging"
                                            main-id="addition"
                                            main-name="addition"
                                            :errors="errors['addition'] ? errors['addition'][0] : null"
                                            class="rounded-xl border-gray-200 w-1/2"
                                        />
                                        <TextInput
                                            v-if="editing"
                                            v-model:model-value="organisation.post_code"
                                            main-id="post_code"
                                            main-name="post_code"
                                            :errors="errors['post_code'] ? errors['post_code'][0] : null"
                                            class="rounded-xl border-gray-200 w-1/2"
                                        />
                                        <TextInput
                                            v-if="editing"
                                            v-model:model-value="organisation.city"
                                            main-id="city"
                                            main-name="city"
                                            :errors="errors['city'] ? errors['city'][0] : null"
                                            class="rounded-xl border-gray-200 w-1/2"
                                        />
                                        <span v-else class="text-pso-dark text-lg">
                                            {{
                                                [
                                                    organisation.street_name,
                                                    organisation.street_number,
                                                    organisation.addition,
                                                    organisation.post_code,
                                                    organisation.city
                                                ].join(" ")
                                            }}
                                        </span>
                                    </div>
                                </div>

                                <div class="detail-info flex align-items-center mb-4">
                                    <div class="mr-6">
                                        <span class="inline-block w-[105px] text-pso-dark text-md">
                                            {{ translate('organisation.summary.details.sbi_codes') }}
                                        </span>
                                    </div>
                                    <div>
                                        <div  v-if="editing">
                                            <div
                                                v-for="(item, index) in organisation.sbi_codes"

                                                :key="index"
                                                class="mb-2"
                                            >
                                                <div class="flex items-center space-x-2">
                                                    <TextInput
                                                        :id="'sbi_key_' + index"
                                                        :name="'sbi_codes_key' + index"
                                                        :model-value="item.key"
                                                        type="text"
                                                        :placeholder="translate('organisation.edit.enter_sbi_code_key')"
                                                        class="rounded-xl border-gray-200 flex-1"
                                                        @update:modelValue="(value) => organisation.sbi_codes[index].key = value"
                                                    />
                                                    <TextInput
                                                        :id="'sbi_value_' + index"
                                                        :name="'sbi_codes_value' + index"
                                                        :model-value="item.value"
                                                        type="text"
                                                        :placeholder="translate('organisation.edit.enter_sbi_code_value')"
                                                        class="rounded-xl border-gray-200 flex-1"
                                                        @update:modelValue="(value) => organisation.sbi_codes[index].value = value"
                                                    />
                                                    <button
                                                        class="bg-white text-red-500  px-2 py-1 rounded-xl flex items-center"
                                                        @click="removeSbiCode(index)"
                                                    >
                                                        <img :src="iconDelete" alt="delete" class="w-4 h-4 hover:cursor-pointer">

                                                    </button>

                                                </div>
                                            </div>
                                            <div v-if="errors['sbi_codes']" class="text-red-500 text-sm">
                                                <div v-if="Array.isArray(errors['sbi_codes'])">
                                                    <div v-for="(error, index) in errors['sbi_codes']" :key="index">
                                                        {{ error }}
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    {{ errors['sbi_codes'] }}
                                                </div>
                                            </div>
                                            <button
                                                class="border-blue-800 bg-blue-1000 inline-block py-3 px-8 rounded-xl font-medium text-white"
                                                @click="addSbiCode"
                                            >
                                                {{ translate('organisation.edit.add_sbi_code') }}
                                            </button>
                                        </div>
                                        <div
                                            v-else
                                        >
                                            <ul class="list-none m-0 p-0">
                                                <li
                                                    v-for="(item, index) in organisation.sbi_codes"
                                                    :key="index"
                                                    class="flex items-center"
                                                >
                                                    <span class="mr-4 text-lg text-pso-dark">
                                                        {{ item.key }}
                                                    </span>
                                                    <span class="text-md text-pso-dark font-light">
                                                        {{ item.value }}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="detail-info flex align-items-center mb-4">
                                    <div class="mr-6">
                                        <span class="inline-block w-[105px] text-pso-dark text-md">
                                            {{ translate('organisation.summary.details.branch') }}
                                        </span>
                                    </div>
                                    <div>
                                        <SelectInput
                                            v-if="editing"
                                            v-model:model-value="organisation.branch_id"
                                            main-id="branch_id"
                                            main-name="branch_id"
                                            :options="branches"
                                            :errors="errors['organisation.branch_id.value'] ? errors['organisation.branch_id.value'][0] : null ?? null"
                                        />
                                        <span v-else class="text-pso-dark text-lg">
                                            {{ organisation.branch_name }}
                                        </span>
                                    </div>
                                </div>

                                <div v-if="props.currentStepProp >= 6">
                                    <contact-details-in-summary />

                                    <div v-if="organisation.is_main === true">
                                        <invoice-details-in-summary :calculation-tool-id-prop="props.calculationToolIdProp" />
                                        <additional-information-in-summary :calculation-tool-id-prop="props.calculationToolIdProp" />
                                    </div>
                                </div>

                                <div
                                    v-if="organisation.is_main === false"
                                    class="change-main-organisation"
                                >
                                    <button
                                        class="inline-block py-2 px-4 rounded-xl bg-white border-[1px] border-gray-400 text-gray-800 text-md font-medium"
                                        @click="confirmReplaceMainOrganisation()"
                                    >
                                        {{ translate('organisation.summary.replace_main_organisation.button_text') }}
                                    </button>
                                </div>
                            </div>
                        </Transition>
                        <div
                            v-if="editing"
                            class="flex align-items-center gap-4"
                        >
                            <BaseButton
                                class="flex-grow-0"
                                @click="saveChanges"
                            >
                                {{ translate('organisation.edit.save_button_text') }}
                            </BaseButton>
                        </div>
                    </div>
                </div>

                <div class="flex flex-row flex-1 flex-grow justify-end items-end w-1/4 min-h-full h-full">

                    <div
                        v-if="organisation.is_main === false"
                        class="delete"
                        dusk="btn-remove-child-organisation"
                        @click.prevent="confirmRemoveAction(organisation)"
                    >
                        <img
                            :src="iconDelete"
                            alt="delete"
                            class="w-4 hover:cursor-pointer"
                        >
                    </div>
                    <div class="delete ml-4">
                        <EditIcon @click="toggleEditing" />
                    </div>
                </div>
            </div>
            <div class="more-info flex justify-end">
                <button
                    class="flex items-center p-0 bg-none"
                    dusk="btn-toggle-more-info"
                    @click="toggleMoreInfo()"
                >
                    <span class="mr-2 text-pso-dark text-lg inline-block">
                        {{ toggleMoreInfoText }}
                    </span>
                    <img
                        :src="iconChevronUp"
                        alt="plus"
                        :class="['mr-2 chevron icon hover:cursor-pointer', {'rotate-180': !isExpanded}]"
                        dusk="chevron-toggle-timeline"
                    >
                </button>
            </div>
        </div>
        <div v-if="organisation.is_main === false">
            <div class="connecting-pipe-horizontal absolute z-0 left-[60px] top-[50%] w-10 h-[2px] bg-gray-050" />
            <div class="connecting-pipe-vertical absolute z-0 left-[60px] -top-[50%] w-[2px] h-[150%] bg-gray-050" />
        </div>
    </div>
</template>

<script setup>
import Organisation from '@Organisation/Organisation';
import CalculationTool from "@CalculationTool/CalculationTool";
import ContactDetailsInSummary from "@Organisation/Parts/ContactDetailsInSummary/Vue/ContactDetailsInSummary.vue";
import InvoiceDetailsInSummary from "@Organisation/Parts/InvoiceDetailsInSummary/Vue/InvoiceDetailsInSummary.vue";
import AdditionalInformationInSummary from "@Organisation/Parts/AdditionalInformationInSummary/Vue/AdditionalInformationInSummary.vue";
import {onBeforeMount, onMounted, provide, ref} from "vue";
import translation from "@Mixins/Translation";
import {useConfirm} from "primevue/useconfirm";
import TextInput from '../../../../FormInput/TextInput/Vue/TextInput.vue';
import EditIcon from '../../../../Icons/EditIcon.vue';
import SelectInput from '../../../../FormInput/SelectInput/Vue/SelectInput.vue';
import BaseButton from '../../../../FormInput/BaseButton.vue';
const { translate } = translation.methods;
const confirm = useConfirm();

const props = defineProps({
    organisationProp: {
        type: Object,
        required: true
    },
    indexProp: {
        type: Number,
        required: true
    },
    calculationToolIdProp: {
        type: Number,
        required: true
    },
    currentStepProp: {
        type: Number,
        required: true,
    },
    mainOrganisationProp: {
        type: Object,
        default: null,
    }
});

const index = ref(0);
const defaultOrganisation = {
    id: null,
    kvk: '',
    branch_id: null,
    branch_name: '',
    company_name: '',
    street_name: '',
    street_number: '',
    post_code: '',
    city: '',
    logo: null,
    logo_full_path: null,
    logo_base64: null,
    is_main: false,
    sbi_codes: [],
};

const organisation = ref({ ...defaultOrganisation });
const organisationMain = ref({ ...defaultOrganisation });
const elementTitle = ref('');
const isExpanded = ref(false);
const toggleMoreInfoText = ref(translate('organisation.summary.more_info'));
const errors = ref({});
const logoUpload = ref(null);
const iconDelete = ref(new URL('/resources/images/icon_delete_blue.svg', import.meta.url).href);
const iconUpload = ref(new URL('/resources/images/icon_upload_circled_v2.svg', import.meta.url).href);
const iconChevronUp = ref(new URL('/resources/images/icon_chevron_up.svg', import.meta.url).href);
const logoUploadInput = ref(null);
const branches = ref([]);

const editing = ref(false);

const organisationAuditor = ref({});
const invoiceDetails = ref({});
const invoiceDetailsAuditor = ref({});
const additionalInformationAuditor = ref({});
const additionalInformationData = ref({});

provide('invoiceDetails', invoiceDetails);
provide('invoiceDetailsAuditor', invoiceDetailsAuditor);
provide('organisation', organisation);
provide('organisationMain', organisationMain);
provide('organisationAuditor', organisationAuditor);
provide('additionalInformationData', additionalInformationData);
provide('additionalInformationAuditor', additionalInformationAuditor);
provide('errors', errors);

onBeforeMount(() => {
    organisation.value = props.organisationProp;
    organisationMain.value = props.mainOrganisationProp;
    index.value = props.indexProp;
    organisation.value.sbi_codes = sbiCodesToEdit();

    elementTitle.value = organisation.is_main === true
        ? translate('organisation.summary.main_organisation_label')
        : translate('organisation.summary.child_organisation_label');
});

onMounted(() => {
    logoUploadInput.value.focus();
});

getBranches();

function getBranches() {
    Organisation.getBranches()
        .then((response) => {
            response.map((branch) => {
                branches.value.push({
                    value: branch.id,
                    label: branch.name,
                });
            });
        });
}


function toggleEditing() {
    editing.value = !editing.value;
}



function sbiCodesToEdit() {
    let sbiCodesString = organisation.value.sbi_codes;
    if (typeof sbiCodesString === 'string') {
        try {
            sbiCodesString = JSON.parse(sbiCodesString);
        } catch (error) {
            console.error('Invalid JSON string:', error);
            return null; // Handle parsing error, e.g., return null or an empty array
        }
    }
    // Check if sbiCodesString is an object and not an array
    if (typeof sbiCodesString === 'object' && !Array.isArray(sbiCodesString)) {
        // Convert the object to an array of key-value pairs
        const sbiCodesArray = Object.entries(sbiCodesString).map(([key, value]) => ({
            key: key,
            value: value
        }));

        return sbiCodesArray;
    } else {
        console.error('sbi_codes is not in the expected format.');
        return null; // or handle the error as you see fit
    }
}


function sbiCodesToArray(sbiCodesToEdit) {
    try {
        // Check if parsed result is an array
        if (Array.isArray(sbiCodesToEdit)) {
            // Convert the array of objects to the desired object format
            const result = sbiCodesToEdit.reduce((accumulator, item) => {
                if (item.key) {
                    accumulator[item.key] = item.value;
                }
                return accumulator;
            }, {});

            return result;
        } else {
            console.error('Parsed data is not an array:', sbiCodesToEdit);
            return {}; // Return an empty object if the parsed data is not an array
        }
    } catch (error) {
        console.error('Invalid JSON string:', error);
        return {}; // Return an empty object if parsing fails
    }
}


const addSbiCode = () => {
    if (!Array.isArray(organisation.value.sbi_codes)) {
        if (typeof organisation.value.sbi_codes === 'object' && organisation.value.sbi_codes !== null) {
            organisation.value.sbi_codes = [organisation.value.sbi_codes];
        } else {
            organisation.value.sbi_codes = [];
        }
    }

    organisation.value.sbi_codes.push({ key: '', value: '' });
};

function removeSbiCode(index) {
    if (Array.isArray(organisation.value.sbi_codes)) {
        // Check if the index is valid
        if (index >= 0 && index < organisation.value.sbi_codes.length) {
            organisation.value.sbi_codes.splice(index, 1);
        } else {
            console.error('Index out of bounds:', index);
        }
    } else {
        console.error('sbi_codes is not an array.');
    }
}

function toggleMoreInfo() {
    isExpanded.value = !isExpanded.value;
    toggleMoreInfoText.value = isExpanded.value
        ? translate('organisation.summary.less_info')
        : translate('organisation.summary.more_info');
}

function openUploadFileDialog() {
    logoUploadInput.value.click();
}

function uploadLogoImage($event) {
    let formData = new FormData();
    formData.append("logo", $event.target.files[0]);
    formData.append("_method", 'PATCH');

    Organisation.updateOrganisationLogo(organisation.value.id, formData)
        .then((data) => {
            organisation.value.logo_full_path = data.data.logo;
            organisation.value.logo_base64 = data.data.logo_base64;
            organisation.value.logo = true;
            organisation.value.sbi_codes = sbiCodesToEdit();
        })
        .catch((error) => {
            if (error) {
                errors.value = error.response.data.message;
            }
        });
}

const confirmReplaceMainOrganisation = () => {
    confirm.require({
        header: translate('organisation.summary.replace_main_organisation.confirmation_dialog.title'),
        message: translate('organisation.summary.replace_main_organisation.confirmation_dialog.question').replace(':name', organisationMain.value.company_name),
        acceptLabel: translate('organisation.summary.replace_main_organisation.confirmation_dialog.confirm_button_text'),
        rejectLabel: translate('organisation.summary.replace_main_organisation.confirmation_dialog.cancel_button_text'),
        defaultFocus: false,
        accept: () => {
            replaceMainOrganisation(organisation.value, {
                actionType: 'replaceMain',
                entityData: organisation.value,
            });
        },
    });
}

function replaceMainOrganisation(organisation, actionData) {
    if (actionData.actionType !== 'replaceMain' || organisation !== actionData.entityData) {
        return;
    }

    Organisation.replaceMain(props.calculationToolIdProp, organisation.id).finally(() => {
        // @TODO Refactor this so that we don't refresh the page
        location.reload();
    });
}

const confirmRemoveAction = () => {
    confirm.require({
        header: translate('organisation.remove.confirmation_dialog.title'),
        message: translate('organisation.remove.confirmation_dialog.message').replace(':name', organisation.value.company_name),
        acceptLabel: translate('organisation.remove.confirmation_dialog.confirm_button_text'),
        rejectLabel: translate('organisation.remove.confirmation_dialog.cancel_button_text'),
        defaultFocus: false,
        accept: () => {
            removeOrganisation(organisation.value, {
                actionType: 'remove',
                entityData: organisation.value,
            });
        },
    })
}

function removeOrganisation(organisation, actionData) {
    if (actionData.actionType !== 'remove' || organisation !== actionData.entityData) {
        return;
    }

    Organisation.remove(props.calculationToolIdProp, organisation.id).finally(() => {
        CalculationTool.removeOrganisationFromStore(organisation.id);
    });
}

function saveChanges()
{
    let sbiCodesBackup = organisation.value.sbi_codes;
    organisation.value.sbi_codes = sbiCodesToArray(organisation.value.sbi_codes);
    Organisation.updateOrganisation(organisation.value.id, organisation.value)
        .then((data) => {
            organisation.value = data.data;
            organisation.value.sbi_codes = sbiCodesToEdit();

            for (const branch of branches.value) {
                if (branch.value == organisation.value.branch_id) {
                    organisation.value.branch_name = branch.label ?? '';
                    break;
                }
            }
            editing.value = false;
            errors.value = {};
        })
        .catch((error) => {
            if (error.response.data && error.response.data.errors) {
                errors.value = error.response.data.errors;
                organisation.value.sbi_codes = sbiCodesBackup;
            } else {
                console.error(error);
            }
        });
}
</script>
