const Steps = {
    list: 1,
    organisation: 2,
    determine_organisation: 2.1,
    determine_child_entities: 2.2,
    organisation_summary: 2.3,
    data: 3,
    reference_date: 3.1,
    company_size: 3.2,
    target_group_data: 3.3,
    purchases: 3.4,
    score: 4,
    checklist: 5,
    application: 6,
    contact_details: 6.1,
    additional_information: 6.2,
    application_summary: 6.3,
    final_request: 6.4,
    quote_request: 6.5,
    final_application: 6.6,
}

export function getSteps() {
    return Steps;
}
