<template>
    <div>
        <div v-if="inScoreView !== true">
            <label for="themeSwitcherOne" class="themeSwitcherTwo mt-4 shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-lg p-0.5 bg-pso-light">
                <input
                    id="themeSwitcherOne"
                    type="checkbox"
                    name="themeSwitcherOne"
                    class="sr-only"

                    @change="onChange"
                >
                <span
                    class="flex items-center space-x-[6px] rounded-xl py-2 px-[18px]"
                    :class="{
                        'text-white bg-pso-primary drop-shadow-md': !inAuditorView,
                        'text-pso-tertiary bg-pso-light': inAuditorView
                    }"
                >
                    {{ translate('auditor.switch_button.original') }}
                </span>
                <span
                    class="flex items-center space-x-[6px] rounded-xl py-2 px-[18px]"
                    :class="{
                        'text-white bg-pso-primary drop-shadow-md': inAuditorView,
                        'text-pso-tertiary bg-pso-light': !inAuditorView
                    }"
                >
                    {{ translate('auditor.switch_button.auditor') }}
                </span>
            </label>
        </div>
        <div v-if="inScoreView === true">
            <label for="themeSwitcherOne" class="themeSwitcherTwo mt-4 shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-lg p-0.5 bg-gray-100">
                <span
                    @click="changeScore('original')"
                    class="flex items-center space-x-[6px] rounded-xl py-2 px-[18px]"
                    :class="{
                        'text-white bg-pso-primary drop-shadow-md': selectedScore === 'original',
                        'text-pso-tertiary bg-pso-light': selectedScore !== 'original'
                    }"
                >Originele
                </span>
                <span
                    @click="changeScore('pre-sample')"
                    class="flex items-center space-x-[6px] rounded-xl py-2 px-[18px]"
                    :class="{
                        'text-white bg-pso-primary drop-shadow-md': selectedScore === 'pre-sample',
                        'text-pso-tertiary bg-pso-light': selectedScore !== 'pre-sample'
                    }"
                >Auditor voor steekproef
                </span>
                <span
                    @click="changeScore('post-sample')"
                    class="flex items-center space-x-[6px] rounded-xl py-2 px-[18px]"
                    :class="{
                        'text-white bg-pso-primary drop-shadow-md': selectedScore === 'post-sample',
                        'text-pso-tertiary bg-pso-light': selectedScore !== 'post-sample'
                    }"
                >Auditor na steekproef
                </span>
            </label>

        </div>
    </div>
</template>
<script>
export default {
    props: {
        inAuditorViewInitial: {
            type: Boolean,
            default: true,
        },
        inScoreView: {
            type: Boolean,
            default: false,
        },
        selectedScore: {
            type: String,
            default: 'original',
        }
    },
    data() {
        return {
            inAuditorView: false,
        }
    },
    computed:  {
        isPsoStaff() {
            return this.$page.props.user.isPsoStaff;
        }
    },
    created() {
        this.inAuditorView = (route().current().split('.').slice(-1)[0] !== 'original');
    },
    methods: {
        onChange($event) {
            window.mitt.emit('auditorSwitchButtonChanged', $event.target.checked);
        },
        changeScore(view) {
            window.mitt.emit('changeScoreView', view);
        },
    }
};


</script>
