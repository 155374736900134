import _ from "lodash";
import {createApp} from 'vue'

import SelectTwoFactorAuthForm from "./Vue/SelectTwoFactorAuthForm.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {SelectTwoFactorAuthForm},
    }
    return createApp(_.merge(options, vueOptions));
}
