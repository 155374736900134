<template>
    <main class="page organisation-kvk-request">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button :href="backRoute()" />
                </div>

                <h1
                    class="text-center text-pso-dark text-2xl font-bold mb-10"
                >
                    {{ translate('organisation.kvk.child_title') }}
                </h1>

                <h2
                    class="text-center text-pso-dark text-xl font-medium mb-4"
                >
                    {{ translate('organisation.child_organisation.kvk.sub_title') }}
                </h2>
                <p
                    class="mb-5 text-lg text-pso-dark"
                >
                    {{ translate('organisation.child_organisation.kvk.paragraph') }}
                </p>

                <div
                    v-for="(organisation) in organisations_list"
                    :key="organisation.data.kvk"
                    class="mb-5 mt-5 inline-block w-full"
                >
                    <div class="rounded-xl p-8 bg-gray-050">
                        <div class="flex mb-4">
                            <div class="w-4/12">
                                <span class="text-md text-pso-dark">
                                    {{ translate('organisation.kvk.kvk') }}
                                </span>
                            </div>
                            <div class="w-7/12">
                                <span class="text-lg text-pso-dark">
                                    {{ organisation.data.kvk }}
                                </span>
                            </div>
                            <div class="w-1/12">
                                <a
                                    class="delete-action block ml-5 float-right"
                                    href="#"
                                    dusk="btn-remove-child-organisation"
                                    @click.prevent="confirmRemoveAction(organisation)"
                                >
                                    <img
                                        :src="iconDelete"
                                        alt="delete"
                                        class="mr-2 w-[15px]"
                                    >
                                </a>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <div class="w-4/12">
                                <span class="text-md text-pso-dark">
                                    {{ translate('organisation.kvk.company_name') }}
                                </span>
                            </div>
                            <div class="w-8/12">
                                <span class="text-lg text-pso-dark">
                                    {{ organisation.data.company_name }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <div class="w-4/12">
                                <span class="text-md text-pso-dark">
                                    {{ translate('organisation.kvk.address') }}
                                </span>
                            </div>
                            <div class="w-8/12">
                                <span class="text-lg text-pso-dark">
                                    {{
                                        [organisation.data.name, organisation.data.street_name, organisation.data.street_number, organisation.data.addition, organisation.data.post_code, organisation.data.city].join(" ")
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <div class="w-4/12">
                                <span class="text-md text-pso-dark">
                                    {{ translate('organisation.kvk.sbi_codes') }}
                                </span>
                            </div>
                            <div class="w-8/12">
                                <ul class="list-none m-0 p-0">
                                    <li
                                        v-for="(description, code) in sbiCodesToRead(organisation.data.sbi_codes)"
                                        :key="code"
                                        class="flex items-center"
                                    >
                                        <span class="mr-4 text-lg text-pso-dark">
                                            {{ code }}
                                        </span>
                                        <span class="text-md text-pso-dark font-light">
                                            {{ description }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <div class="w-4/12">
                                <span class="text-md text-pso-dark">
                                    {{ translate('organisation.summary.details.branch') }}
                                </span>
                            </div>
                            <div class="w-8/12">
                                <span class="text-lg text-pso-dark">
                                    {{ organisation.data.branch_name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="more-entities w-[404px]">
                    <div
                        v-if="newChildOrganisationButtonIsShown === true"
                    >
                        <a
                            class="flex items-center w-full border-gray-400 border-[1px] rounded-l p-4 pl-5 bg-pso-dark"
                            :href="createFromKvkRoute()"
                            dusk="btn-open-calculation-tool-form"
                        >
                            <img
                                :src="iconPlus"
                                alt="plus"
                                class="mr-2 chevron icon hover:cursor-pointer"
                                dusk="chevron-toggle-timeline"
                            >
                            <span
                                class="text-white text-lg font-medium"
                            >
                                {{ translate('organisation.child_organisation.kvk.create_button_text') }} deze
                            </span>
                        </a>
                    </div>
                </div>
                <div class="bottom mt-10">
                    <div class="action-button text-center">
                        <a
                            href="#"
                            :class="['inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange cursor-pointer', newChildOrganisationButtonIsShown === true ? '' : 'opacity-50 cursor-not-allowed']"
                            @click.prevent="nextRoute"
                        >
                            {{ translate('organisation.child_organisation.kvk.button_text_next_step') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Organisation from "../../../../Organisation/Organisation";
import CalculationToolHeader from "../../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import {mapGetters} from "vuex";
import BackLinkButton from "../../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import {getSteps} from "../../../../_config/Steps"
import iconPlus from '@images/icon_plus_gray.svg';
import iconDelete from '@images/icon_delete.svg';

export default {
    components: {CalculationToolHeader, 'app-back-button': BackLinkButton},
    props: {
        calculationToolIdProp: {
            type: String,
            default: null,
        },
        organisationIdProp: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            addMoreEntities: '',
            calculationToolName: '',
            moreEntitiesUrl: null,
            failed: null,
            availableBranches: [],
            branch: null,
            newChildOrganisationButtonIsShown: true,
            newChildOrganisationFormOpen: false,
            newChildOrganisationForm: {
                'kvk_number': null,
            },
            warningUnknownKVK: null,
            errors: {},
            requestDataForm: {},
            iconPlus: iconPlus,
            iconDelete: iconDelete,
        }
    },
    computed: {
        ...mapGetters("calculation_tool", ["organisations_list"]),
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationTool.getOrganisations(this.calculationToolIdProp, { only_children: true });
    },
    methods: {
        sbiCodesToRead(sbiCodes) {
            const cleanedSbiCodes = sbiCodes.replace(/[{}"]/g, '');

            const keyValuePairs = cleanedSbiCodes
                .split(',').map(pair => pair.split(':').map(item => item.trim()));

            return Object.fromEntries(keyValuePairs);
        },

        createFromKvkRoute() {
            return route('organisation.child-organisation-create-from-kvk', {
                calculationTool: this.calculationToolIdProp,
                organisationId: this.organisationIdProp
            });
        },

        backRoute() {
            return route('organisation.child-organisation-list', {
                calculationTool: this.calculationToolIdProp,
                organisationId: this.organisationIdProp
            });
        },

        nextRoute() {
            if (this.newChildOrganisationButtonIsShown === true) {
                CalculationTool.updateStep({ calculationTool: this.calculationToolIdProp, step: getSteps().organisation_summary })
                    .then((response) => {
                        if (response.status === 200) {
                            window.location.href = route('organisation.summary', {calculationTool: this.calculationToolIdProp});
                        }
                    }).catch((error) => {
                        console.log(error.response.data.data.message);
                    });
            }
        },

        resetInput() {
            this.errors = {}
        },

        confirmRemoveAction(organisation) {
            this.$confirm.require({
                header: this.translate('organisation.remove.confirmation_dialog.title'),
                message: this.translate('organisation.remove.confirmation_dialog.message').replace(':name', organisation.data.company_name),
                acceptLabel: this.translate('organisation.remove.confirmation_dialog.confirm_button_text'),
                rejectLabel: this.translate('organisation.remove.confirmation_dialog.cancel_button_text'),
                defaultFocus: false,
                accept: () => {
                    this.removeOrganisation(organisation, {
                        actionType: 'remove',
                        entityData: organisation,
                    });
                },
            });
        },

        removeOrganisation(organisation, actionData) {
            if (actionData.actionType !== 'remove' || organisation !== actionData.entityData) {
                return;
            }

            Organisation.remove(this.calculationToolIdProp, organisation.id).finally(() => {
                CalculationTool.removeOrganisationFromStore(organisation.id);
            });
        },
    },
};
</script>
