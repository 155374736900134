import _ from "lodash";
import { createApp } from 'vue'

import TwoFactorAuthEnterCodeForm from "./Vue/TwoFactorAuthEnterCodeForm.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {TwoFactorAuthEnterCodeForm},
    }
    return createApp(_.merge(options, vueOptions));
}
