<template>
    <main class="page organisation-kvk-request">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button :href="backRoute()" />
                </div>

                <h1
                    class="text-center text-pso-dark text-2xl font-bold mb-10"
                >
                    {{ translate('organisation.child_organisation.kvk.title') }}
                </h1>
                <div id="kvk-request">
                    <h2
                        class="text-center text-pso-dark text-xl font-medium mb-4"
                    >
                        {{ translate('organisation.child_organisation.kvk.sub_title') }}
                    </h2>
                    <p
                        class="mb-10 text-lg text-pso-dark"
                    >
                        {{ translate('organisation.child_organisation.kvk.paragraph') }}
                    </p>

                    <form
                        id="kvk_request_form"
                        class="form"
                        @submit.prevent="submitRequest"
                    >
                        <slot />

                        <div class="mb-8 w-1/2">
                            <label
                                for="kvk_number"
                                class="block mb-2 text-lg text-gray-800 text-italic"
                            >
                                {{ translate('organisation.child_organisation.kvk.field_label') }}
                            </label>
                            <input
                                id="kvk_number"
                                v-model="requestDataForm.kvk_number"
                                type="text"
                                class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                :class="{'border-red-500': errors.kvk_number, 'text-gray-600': warningUnknownKVK, 'bg-gray-100': warningUnknownKVK}"
                                name="kvk_number"
                                :placeholder="translate('organisation.kvk.field_placeholder')"
                                autocomplete="kvk_number"
                                :disabled="warningUnknownKVK === true"
                            >
                            <span
                                v-if="errors.kvk_number"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.kvk_number"
                            />
                        </div>
                        <div class="mb-8 w-1/2">
                            <label
                                for="kvk_number"
                                class="block mb-2 text-lg text-gray-800 text-italic"
                            >
                                {{ translate('organisation.branch.field_label') }}
                            </label>
                            <SelectInput
                                id="branch"
                                v-model="requestDataForm.branch"
                                :errors="errors.branch"
                                :name="branch"
                                :placeholder="translate('organisation.branch.placeholder')"
                                :available-options="availableBranches"
                                :options="availableBranches">
                            </SelectInput>
                        </div>

                        <div
                            v-if="showSubmitButton"
                            class="col-md-8 offset-md-4 text-center"
                        >
                            <button
                                type="submit"
                                class="mt-4 mb-8 px-4 py-2 rounded-xl bg-pso-orange text-white font-medium"
                            >
                                {{ translate('organisation.kvk.button_text_retrieve') }}
                            </button>
                        </div>
                        <div
                            v-if="warningUnknownKVK"
                            class="col-md-8 offset-md-4 border p-6 rounded-xl border-pso-orange border-l-8"
                        >
                            <p class="text-xl mb-4 text-black">
                                {{ translate('organisation.kvk.unknown_kvk_text') }}
                            </p>
                            <div class="text-right">
                                <a
                                    class="inline-block border border-pso-dark text-lg text-pso-dark rounded-xl px-4 py-2 mb-2"
                                    :href="getAddManuallyKvkBasicProfileLink()"
                                >
                                    {{ translate('organisation.kvk.button_create_manually') }}
                                </a>
                                <br>
                                <button
                                    class="inline-block border border-pso-dark text-lg text-pso-dark rounded-xl px-4 py-2"
                                    @click="tryKvkAgain()"
                                >
                                    {{ translate('organisation.kvk.button_try_kvk_again') }}
                                </button>
                            </div>
                        </div>
                        <div
                            v-if="kvkConnectionError"
                            class="col-md-8 offset-md-4 border p-6 rounded-xl border-red-500 border-l-red-700 border-l-8"
                        >
                            <p class="text-xl text-black">
                                {{ translate('organisation.kvk.kvk_connection_error') }}
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

import Organisation from "../../../../Organisation/Organisation";
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import CalculationToolHeader from "../../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import BackLinkButton from "../../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import SelectInput from "../../../../FormInput/SelectInput/Vue/SelectInput.vue";

export default {
    components: {SelectInput, CalculationToolHeader, 'app-back-button': BackLinkButton},
    props: {
        calculationToolIdProp: {
            type: String,
            default: null,
        },
        organisationIdProp: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            requestDataForm: {},
            organisationId: null,
            moreEntitiesRedirect: null,
            noMoreEntitiesRedirect: null,
            availableBranches: [],
            branch: null,
            failed: null,
            success: null,
            warningUnknownKVK: false,
            kvkConnectionError: false,
            errors: {}
        };
    },
    computed: {
        showSubmitButton: function() {
            return (this.warningUnknownKVK === false && this.kvkConnectionError === false)
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        this.moreEntitiesRedirect = '#';
        this.noMoreEntitiesRedirect = '#';
        this.getBranches();
    },
    methods: {
        getBranches() {
            Organisation.getBranches()
                .then((response) => {
                    response.map((branch) => {
                        this.availableBranches.push({
                            value: branch.id,
                            label: branch.name,
                        });
                    });
                });
        },
        submitRequest() {
            let formData = Organisation.buildCreateOrganisationFromKvkForm(this.requestDataForm);
            Organisation.createOrganisation(this.calculationToolIdProp, formData)
                .then((data) => {
                    this.errors = {};
                    //get the child organisation id
                    if(typeof data.data.organisation.id !== 'undefined'){
                        //redirect to child organisation details
                        window.location.href = route('organisation.child-organisation-detail', {calculationTool: this.calculationToolIdProp, organisationId: data.data.organisation.id});
                    }
                    throw 'Organisation ID is missing';
                })
                .catch((error) => {
                    if (error) {
                        if ([400, 401, 404, 422].includes(error.response.status)) {
                            if(typeof error.response.data.unknownKVK != "undefined" && error.response.data.unknownKVK){
                                this.warningUnknownKVK = true;
                                return;
                            }
                            this.$setErrorsFromResponse(error.response.data);
                        }
                        if (error.response.status === 500) {
                            this.kvkConnectionError = true;
                        }
                    }
                });
        },

        backRoute() {
            return route('organisation.detail', {calculationTool: this.calculationToolIdProp, organisationId: this.organisationIdProp});
        },

        getAddManuallyKvkBasicProfileLink() {
            let kvkGet = "?kvk_number=" + this.requestDataForm.kvk_number;
            let branchGet = "&branch_id=" + this.requestDataForm.branch

            return route('organisation.create-manually', {calculationTool: this.calculationToolIdProp,  organisationId: this.organisationIdProp }) + kvkGet + branchGet;
        },
        tryKvkAgain() {
            this.warningUnknownKVK = false;
        }
    }
};
</script>
