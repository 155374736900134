import store from "../Store/store";
import Router from "./Router";
import Translate from "../Components/Mixins/Translation";
import Toast from "../Components/Mixins/Toast"
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import VueCookies from 'vue3-cookies'
import Sentry from '@/js/Services/Sentry';
import { ZiggyVue } from 'ziggy-js/dist/vue.m';

class Base {
    static getTranslations() {
        store.commit('translations', window.dictionary || {});
    }

    static buildRoute(name, params = {}) {
        return Router.build(name, params);
    }

    // use this method if you want to extract payload from jwt token
    static parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    static render(selector, options = {}) {
        let components = this.getComponents();

        const app = components[selector](`#${selector}`, options);

        Sentry.init(app);

        app.config.globalProperties.$setErrorsFromResponse = function (errorResponse) {
            if (this.errors === undefined) {
                return;
            }

            this.errors = {};

            if (errorResponse.errors === undefined) {
                return;
            }

            let errorFields = Object.keys(errorResponse.errors);

            errorFields.map(field => {
                this.errors[field] = errorResponse.errors[field][0];
                if(typeof this.errors[field] === 'object' && this.errors[field] !== null) {
                    let errorSubFields = Object.keys(this.errors[field]);
                    errorSubFields.map(subField => {
                        this.errors[field] = this.errors[field][subField];
                    });
                }
            });
        };

        app.use(store);
        app.use(VueCookies);
        app.use(PrimeVue);
        app.use(ConfirmationService);
        app.use(ToastService);
        app.use(ZiggyVue);

        app.mixin(Translate)
        app.mixin(Toast);

        app.mount("#" + selector);

        return app;
    }
}

export default Base;
