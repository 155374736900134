<template>
    <div
        v-show="show"
        class="modal-window"
        role="alert"
    >
        <div class="edit-employee-modal w-600px bg-none mx-auto position-fixed top-0 mt-auto">
            <div
                :class="editingMultiple === true ? 'has-multiple' : ''"
                class="edit-employee-modal-content-wrapper bg-pso-primary py-12 px-8 rounded-xl shadow"
            >
                <header>
                    <h4 class="edit-employee-modal-heading text-white font-medium text-2xl mb-4">
                        {{ translate('calculation_tool_data.target_group_data.edit.edit_form_modal.title') }}
                    </h4>
                </header>

                <div
                    v-if="initialized"
                    class="edit-employee-form-wrapper"
                >
                    <div class="mb-6">
                        <label
                            class="block mb-2 text-lg text-white"
                            dusk="label-personnel-number"
                            for="personnel_number_label"
                        >
                            {{ translate('calculation_tool_data.target_group_data.personnel_number_label') }}
                            <Popper
                                :arrow="true"
                                placement="top"
                            >
                                <div class="ml-1">
                                    <img
                                        :src="iconInfo"
                                        alt="info"
                                    >
                                </div>
                                <template #content>
                                    {{
                                        translate('calculation_tool_data.target_group_data.personnel_number_info')
                                    }}
                                </template>
                            </Popper>
                        </label>

                        <TextInput
                            id="personnel_number"
                            v-model:model-value="form.personnel_number"
                            name="personnel_number"
                            :errors="errors.personnel_number"
                            :placeholder="translate('calculation_tool_data.target_group_data.personnel_number_placeholder')"
                        />

                        <span
                            v-if="errors.personnel_number"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.personnel_number"
                        />
                    </div>
                    <div class="mb-6">
                        <label
                            class="block mb-2 text-lg text-white"
                            for="branch_label"
                        >
                            {{ translate('calculation_tool_data.target_group_data.branch_label') }}
                        </label>

                        <TextInput
                            id="branch_label"
                            v-model:model-value="form.branch_label"
                            name="branch_label"
                            :errors="errors.branch_label"
                            :placeholder="translate('calculation_tool_data.target_group_data.branch_placeholder')"
                        />

                        <span
                            v-if="errors.branch"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.branch"
                        />
                    </div>
                    <div class="mb-6">
                        <div
                            :class="{ 'pb-6': errors.starting_position_id }"
                            class="pb-2"
                            dusk="modal-edit-tge-label-starting-position"
                        >
                            <span class="text-lg text-white inline-block">
                                {{
                                    translate('calculation_tool_data.target_group_data.starting_position_label')
                                }}
                            </span>
                            <Popper
                                :arrow="true"
                                placement="top"
                            >
                                <div class="ml-1">
                                    <img
                                        :src="iconInfo"
                                        alt="info"
                                    >
                                </div>
                                <template #content>
                                    {{
                                        translate('calculation_tool_data.target_group_data.starting_position_info')
                                    }}
                                    <a
                                        class="text-blue-1000"
                                        :href="links.manual_pso"
                                        target="_blank"
                                    >
                                        {{
                                            translate('calculation_tool_data.target_group_data.starting_position_info_link_text')
                                        }}
                                    </a>
                                </template>
                            </Popper>
                        </div>
                        <div>
                            <MultiSelectInput
                                id="starting_position_id"
                                v-model:model-value="form.starting_position_id"
                                name="starting_position_id"
                                :options="startingPositionOptions"
                                :errors="errors.starting_position_id"
                                :placeholder="translate('calculation_tool_data.target_group_data.starting_position_placeholder')"
                                dusk="modal-edit-tge-btn-multiselect-starting-position"
                            />

                            <span
                                v-if="errors.starting_position_id"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.starting_position_id"
                            />
                        </div>
                    </div>
                    <div
                        v-show="showSwGroupSizeInput"
                        class="mb-6"
                    >
                        <label
                            class="block mb-2 text-lg text-white"
                            for="modal_sw_group_size"
                        >
                            {{ translate('calculation_tool_data.target_group_data.sw_group_size_label') }}
                        </label>

                        <TextInput
                            id="modal_sw_group_size"
                            v-model="form.sw_group_size"
                            disabled
                            name="sw_group_size"
                            :errors="errors.sw_group_size"
                            :placeholder="translate('calculation_tool_data.target_group_data.sw_group_size_placeholder')"
                        />

                        <span
                            v-if="errors.sw_group_size"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.sw_group_size"
                        />
                    </div>
                    <div class="mb-6">
                        <div
                            :class="{ 'pb-6': errors.contract_type_id }"
                            class="pb-2"
                            dusk="modal-edit-tge-label-contract"
                        >
                            <span class="text-lg text-white inline-block">
                                {{ translate('calculation_tool_data.target_group_data.contract_label') }}
                            </span>
                            <Popper
                                :arrow="true"
                                placement="top"
                            >
                                <div class="ml-1">
                                    <img
                                        :src="iconInfo"
                                        alt="info"
                                    >
                                </div>
                                <template #content>
                                    {{ translate('calculation_tool_data.target_group_data.contract_info') }}
                                    <a
                                        class="text-blue-1000"
                                        :href="links.manual_pso"
                                        target="_blank"
                                    >
                                        {{ translate('calculation_tool_data.target_group_data.contract_info_link_text') }}
                                    </a>
                                </template>
                            </Popper>
                        </div>
                        <div>
                            <MultiSelectInput
                                id="contract_type_id"
                                v-model:model-value="form.contract_type_id"
                                name="contract_type_id"
                                :options="contractTypeOptions"
                                :errors="errors.contract_type_id"
                                :placeholder="translate('calculation_tool_data.target_group_data.contract_placeholder')"
                                dusk="modal-edit-tge-btn-multiselect-contract-type"
                            />
                        </div>
                    </div>
                    <div class="mb-6">
                        <label
                            class="block mb-2 text-lg text-white"
                            dusk="modal-edit-tge-label-hours-per-week"
                            for="hours_per_week_label"
                        >
                            {{ translate('calculation_tool_data.target_group_data.hours_per_week_label') }}
                            <Popper
                                :arrow="true"
                                placement="top"
                            >
                                <div class="ml-1">
                                    <img
                                        :src="iconInfo"
                                        alt="info"
                                    >
                                </div>
                                <template #content>
                                    <ul class="list-disc">
                                        <li>
                                            {{
                                                translate('calculation_tool_data.target_group_data.hours_per_week_info_1')
                                            }}
                                        </li>
                                        <li>
                                            {{
                                                translate('calculation_tool_data.target_group_data.hours_per_week_info_2')
                                            }}
                                        </li>
                                        <li>
                                            {{
                                                translate('calculation_tool_data.target_group_data.hours_per_week_info_3')
                                            }}
                                        </li>
                                    </ul>
                                </template>
                            </Popper>
                        </label>

                        <NumberInput
                            id="hours_per_week"
                            v-model:model-value="form.hours_per_week"
                            name="hours_per_week"
                            :errors="errors.hours_per_week"
                            :placeholder="translate('calculation_tool_data.target_group_data.hours_per_week_placeholder')"
                        />

                        <span
                            v-if="errors.hours_per_week"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.hours_per_week"
                        />
                    </div>
                    <div class="mb-6">
                        <div
                            :class="{ 'pb-6': errors.date_in_service }"
                            class="pb-2"
                            dusk="modal-edit-tge-label-date-in-service"
                        >
                            <span class="text-lg text-white">
                                {{ translate('calculation_tool_data.target_group_data.date_in_service_label') }}
                            </span>
                            <Popper
                                :arrow="true"
                                placement="top"
                            >
                                <div class="ml-1">
                                    <img
                                        :src="iconInfo"
                                        alt="info"
                                    >
                                </div>
                                <template #content>
                                    <div>
                                        {{
                                            translate('calculation_tool_data.target_group_data.date_in_service_info')
                                        }}
                                    </div>
                                </template>
                            </Popper>
                        </div>
                        <div>
                            <DatePickerInput
                                id="date_in_service"
                                v-model:model-value="form.date_in_service"
                                name="date_in_service"
                                :max-date="new Date()"
                                :placeholder="translate('calculation_tool_data.target_group_data.date_in_service_placeholder')"
                                dusk="modal-edit-tge-btn-datepicker-date-in-service"
                            />

                            <span
                                v-if="errors.date_in_service"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.date_in_service"
                            />
                        </div>
                    </div>
                    <div class="mb-6">
                        <div
                            :class="{ 'pb-6': errors.date_out_of_service }"
                            class="pb-2"
                            dusk="modal-edit-tge-label-date-out-of-service"
                        >
                            <span class="text-lg text-white">
                                {{
                                    translate('calculation_tool_data.target_group_data.date_out_of_service_label')
                                }}
                            </span>
                            <Popper
                                :arrow="true"
                                placement="top"
                            >
                                <div class="ml-1">
                                    <img
                                        :src="iconInfo"
                                        alt="info"
                                    >
                                </div>
                                <template #content>
                                    <div>
                                        {{
                                            translate('calculation_tool_data.target_group_data.date_out_of_service_info')
                                        }}
                                    </div>
                                </template>
                            </Popper>
                        </div>
                        <div>
                            <DatePickerInput
                                id="date_out_of_service"
                                v-model:model-value="form.date_out_of_service"
                                name="date_out_of_service"
                                :min-date="formatDate(form.date_in_service, 'DD-MM-YYYY', 'YYYY-MM-DD')"
                                :max-date="null"
                                :placeholder="translate('calculation_tool_data.target_group_data.date_out_of_service_placeholder')"
                                dusk="modal-edit-tge-btn-datepicker-date-out-of-service"
                            />

                            <span
                                v-if="errors.date_out_of_service"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.date_out_of_service"
                            />
                        </div>
                    </div>
                    <div
                        v-show="showSchoolLeavingInput"
                        class="mb-6"
                    >
                        <div
                            :class="{ 'pb-6': errors.date_out_of_service }"
                            class="pb-2"
                            dusk="label-date-out-of-service"
                        >
                            <span class="text-lg text-white">
                                {{
                                    translate('calculation_tool_data.target_group_data.school_leaving_date_label')
                                }}
                            </span>
                        </div>
                        <div class="w-1/2">
                            <DatePickerInput
                                id="school_leaving_date"
                                v-model:model-value="form.school_leaving_date"
                                name="school_leaving_date"
                                :max-date="new Date()"
                                :placeholder="translate('calculation_tool_data.target_group_data.school_leaving_date_placeholder')"
                                dusk="btn-datepicker-school-leaving-date"
                            />

                            <span
                                v-if="errors.school_leaving_date"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.school_leaving_date"
                            />
                        </div>
                    </div>
                </div>

                <footer class="confirmation-modal-footer flex items-center justify-start">
                    <button
                        class="inline-block text-white font-medium py-3 px-6 rounded-xl cursor-pointer bg-pso-orange"
                        :class="allRequiredAreFilled ? '' : 'opacity-50 cursor-not-allowed'"
                        dusk="modal-edit-tge-btn-modal-confirm-action"
                        @click="saveChanges()"
                    >
                        {{ translate('calculation_tool_data.target_group_data.edit.edit_form_modal.save_button') }}
                    </button>

                    <button
                        class="inline-block text-pso-tertiary underline py-3 px-6 cursor-pointer"
                        dusk="modal-edit-tge-btn-modal-cancel-action"
                        @click="cancelEditing()"
                    >
                        {{ translate('calculation_tool_data.target_group_data.edit.edit_form_modal.cancel_button') }}
                    </button>
                </footer>
            </div>
            <div class="edit-employee-modal-shadow" />
        </div>
    </div>
</template>

<script>
import CalculationToolData from '../../../CalculationToolData';
import { mapGetters } from 'vuex';
import Popper from 'vue3-popper';
import { nl } from 'date-fns/locale';
import Links from "../../../../_config/Links.js"
import TextInput from "../../../../FormInput/TextInput/Vue/TextInput.vue";
import MultiSelectInput from "../../../../FormInput/MultiSelectInput/Vue/MultiSelectInput.vue";
import NumberInput from "../../../../FormInput/NumberInput/Vue/NumberInput.vue";
import DatePickerInput from "../../../../FormInput/DatePickerInput/Vue/DatePickerInput.vue";
import iconInfo from '@images/icon_info.svg';
import dayjs from 'dayjs';

const DEFAULT_FORM_VALUES = {
    targetGroupEmployeeId: null,
    personnel_number: null,
    branch: '',
    starting_position_id: null,
    contract_type_id: null,
    hours_per_week: null,
    date_in_service: '',
    date_out_of_service: '',
    sw_group_size: 1,
    school_leaving_date: null,
};

export default {
    components: {
        DatePickerInput,
        NumberInput,
        MultiSelectInput,
        TextInput,
        Popper,
    },
    props: {
        selectedItemsToEdit: {
            type: Object,
            default: {}
        },
    },
    setup() {
        return {
            nl,
        };
    },
    data() {
        return {
            links: Links,
            show: false,
            editingMultiple: false,
            allRequiredAreFilled: false,
            showSwGroupSizeInput: false,
            showSchoolLeavingInput: false,
            itemsToEdit: {},
            currentlyEditing: {},
            form: Object.assign({}, DEFAULT_FORM_VALUES),
            calculationToolData: {},
            contractTypeOptions: {},
            contractTypeOptionsFullList: {},
            errors: {},
            iconInfo: iconInfo,
            initialized: false,
            validateRequiredFieldsInputs: [
                'personnel_number_label',
                'sw_group_size',
                'hours_per_week',
                'date_in_service',
                'date_out_of_service',
                'school_leaving_date',
            ],
            validateStartingPositionInputs: [
                'starting_position_id'
            ]
        };
    },
    computed: {
        ...mapGetters('target_group', ['contract_type']),
        ...mapGetters('target_group', { startingPositionOptions: 'starting_position' }),
        ...mapGetters("user", ["roles"]),
    },
    watch: {
        form: {
            deep: true,
            handler() {
                this.validateStartingPosition();
                this.validateRequiredFields();
            }
        },
    },
    created() {
        window.mitt.on('showEmployeeEditModal', this.onShowEditModal);
    },
    unmounted() {
        window.mitt.off('showEmployeeEditModal', this.onShowEditModal);
    },
    methods: {
        onShowEditModal(modalData) {
            this.contractTypeOptions = Object.assign({}, this.contract_type);
            this.contractTypeOptionsFullList = Object.assign({}, this.contract_type);
            this.itemsToEdit = modalData.selectedItemsToEdit;
            this.calculationToolId = modalData.calculationToolId;
            this.calculationToolData = modalData.calculationToolData;
            this.initialized = true;
            this.showModal();
        },

        formatDate(date, originalFormat, newFormat) {
            return dayjs(date, originalFormat).format(newFormat);
        },

        showModal() {
            this.editingMultiple = this.itemsToEdit.length > 1;
            this.editEmployee();
            this.show = true;
        },

        hideModal() {
            CalculationToolData.getTargetGroupEmployeeList(this.calculationToolId);
            this.editingMultiple = false;
            this.currentlyEditing = {};
            this.form = {};
            this.errors = {};
            this.show = false;
        },

        validateRequiredFields() {
            let requiredKeys = ['personnel_number', 'starting_position_id', 'contract_type_id', 'date_in_service', 'hours_per_week'];
            this.showSwGroupSizeInput = false;
            this.showSchoolLeavingInput = false;
            if (this.calculationToolData.showSwInputForStartingPositionId === parseInt(this.form.starting_position_id)) {
                requiredKeys.push('sw_group_size');
                this.showSwGroupSizeInput = true;
            }
            if (this.calculationToolData.showSchoolLeavingInputMandatory === parseInt(this.form.starting_position_id)) {
                requiredKeys.push('school_leaving_date');
            }
            if (
                Array.isArray(this.calculationToolData.showSchoolLeavingInputForStartingPositionIds) &&
                this.calculationToolData.showSchoolLeavingInputForStartingPositionIds.includes(parseInt(this.form.starting_position_id))
            ) {
                this.showSchoolLeavingInput = true;
                if (
                    Array.isArray(this.calculationToolData.schoolLeavingInputMandatoryStartingPositionIds) &&
                    this.calculationToolData.schoolLeavingInputMandatoryStartingPositionIds.includes(parseInt(this.form.starting_position_id))
                ) {
                    requiredKeys.push('school_leaving_date');
                }
            }
            this.allRequiredAreFilled = !requiredKeys.some(function (key) {
                return !Boolean(this.form[key]);
            }.bind(this));
        },

        validateStartingPosition() {
            this.validateRequiredFields();
            this.contractTypeOptionsFullList = Object.assign({}, this.contract_type);
        },

        saveChanges() {
            this.validateRequiredFields();
            if (this.allRequiredAreFilled === false) {
                return;
            }

            CalculationToolData.updateTargetGroupEmployee(this.form)
                .then(() => {
                    this.editEmployee();
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        let personnelNumberErrors = ((((error || {}).response || {}).data || {}).errors || {}).personnel_number;
                        if (personnelNumberErrors && personnelNumberErrors.includes('trigger_not_unique_personnel_number')) {
                            delete error.response.data.errors.personnel_number;
                            let dialogData = {
                                header: this.translate('calculation_tool_data.target_group_data.modal_unique_personnel_number_title'),
                                body: this.translate('calculation_tool_data.target_group_data.modal_unique_personnel_number_body'),
                                buttons: [{
                                    text: this.translate('calculation_tool_data.target_group_data.modal_unique_personnel_number_button'),
                                    redirect: '#personnel_number_label',
                                    style: '!text-gray-600 border border-gray-600 !bg-white',
                                }],
                                closable: true,
                            }

                            window.mitt.emit("dialogMessage", dialogData);
                        }
                    }
                    if (error.response) {
                        this.$setErrorsFromResponse(error.response.data);
                    }
                });
        },

        cancelEditing() {
            this.allRequiredAreFilled = false;
            this.editEmployee();
        },

        editEmployee() {
            if (this.itemsToEdit.length === 0) {
                this.hideModal();

                return;
            }
            this.errors = {};
            this.currentlyEditing = this.itemsToEdit.shift();
            this.form = Object.assign({}, this.currentlyEditing);
            this.form.date_out_of_service = this.form.date_out_of_service ? this.form.date_out_of_service.replace(/(\d{2})-(\d{2})-(\d{4})/, "$1-$2-$3") : null;
            this.form.date_in_service = this.form.date_in_service.replace(/(\d{2})-(\d{2})-(\d{4})/, "$1-$2-$3");
            this.form.school_leaving_date = this.form.school_leaving_date ? this.form.school_leaving_date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$1-$2-$3") : null;

            this.validateRequiredFields();
        }
    }
};
</script>
