import axios from 'axios';
import _ from "lodash";

export default {
    namespaced: true,
    state: {
        auditors: [],
        extra_inputs: [],
    },
    getters: {
        auditors(state) {
            return state.auditors;
        },
        extra_inputs(state) {
            return state.extra_inputs;
        }
    },
    mutations: {
        auditors(state, data) {
            state.auditors = data;
        },
        extra_inputs(state, data) {
            state.extra_inputs = data;
        }
    },
    actions: {
        index({commit})  {
            return axios.get('/api/auditors/')
                .then((response) => commit('auditors', _.filter(response.data.data,function(item) {return item.company !== null; })));
        },
        getExtraInputs({commit}, data) {
            return axios.get('/api/auditors/calculation-tool/' + data.calculationToolId + '/view/' + data.view + '/extra-inputs')
                .then((response) => commit('extra_inputs', response.data.data));
        },
        storeExtraInputs({}, data) {
            return axios.post('/api/auditors/calculation-tool/' + data.calculationToolId + '/extra-inputs', data.payload);
        },
    }
};
