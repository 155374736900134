import Routes from "./_config/Routes";
import Translation from "./Mixins/Translation";

export default {
    build: (name, params = {}) => {
        if(!name) {
            throw new Error("Name property not found.");
        }

        let path = Translation.methods.translate(name.split('.').reduce((memo, item) => {
            return memo[item]
        }, Routes));


        if(path.includes("http") || path.includes("https")){
            return Object.keys(params).reduce((memo, item) => {
                memo = memo.replace(`{${item}}`, params[item]);
                return memo;
            }, path);
        }

        //home route - remove extra / from url
        if(path === "/"){
            path = "";
        }

        return import.meta.env.VITE_APP_URL + Object.keys(params).reduce((memo, item) => {
            memo = memo.replace(`{${item}}`, params[item]);
            return memo;
        }, path);
    }
}
