<template>
    <div class="radio-list">
        <RadioInputItem
            v-for="item in items"
            :id="item.id"
            :key="item.id"
            :name="name"
            :value="item.value"
            :label="item.label"
            :checked="checkedItemValue && checkedItemValue === item.value"

            @update:modelValue="(value) => $emit('update:modelValue', value)"
        />
    </div>
</template>
<script>

import RadioInputItem from "../../RadioInputItem/Vue/RadioInputItem.vue";

export default {
    components: {RadioInputItem},
    props: {
        name: {
            type: String,
            required: true,
        },
        items: {
            type: [Array, Object, Function],
            required: true,
        },
        checkedItemValue: {
            type: [Boolean, Number, String],
            default: null,
        }
    },
}

</script>
