import store from "../../Store/store";
import _ from 'lodash';

export default {
    methods: {
        translate(key,variables = []) {
            if(store.getters.translations[key]){
                _.each(variables, (variable,vkey) => {
                    store.getters.translations[key] = store.getters.translations[key].replace(':' + vkey,variable);
                })
            }
            return store.getters.translations[key] || `*${key}*`;
        },

        naturalLanguageJoin(list, conjunction, conjunctionAfter = 1) {
            if (list.length > conjunctionAfter) {
                return list.slice(0, -1).join(', ') + ' ' + conjunction + ' ' + list.slice(-1);
            }
            return list.join(', ');
        }
    }
}
