import {createApp} from 'vue';
import _ from "lodash";
import mainOrganisationCreateFromKvk from "./Vue/MainOrganisationCreateFromKvk.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {mainOrganisationCreateFromKvk: mainOrganisationCreateFromKvk},
    };

    return createApp(_.merge(options, vueOptions));
}