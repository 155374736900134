export default {
    handbook_inventory_and_burden_of_proof_pso_target_group: "https://www.pso-nederland.nl/uploads/pso/handleidingen/Handreiking%20inventarisatie%20en%20bewijslast%20PSO%20doelgroep%2020220401.pdf",
    handbook_letter_more_social_procurement_with_the_pso_quality_mark: "https://www.pso-nederland.nl/uploads/pso/handleidingen/Handreiking%20brief%20Socialer%20inkoopbeleid%20met%20de%20PSO.pdf",
    manual_pso: "https://www.pso-nederland.nl/uploads/pso/handleidingen/202203014_Aangel.PSO%20versie%202.0%20-versie%2028%20febr.pdf",
    example_calculation_guide_step_3b: "https://www.pso-nederland.nl/uploads/pso/handleidingen/Leidraad%20Berekening%20Bedrijfsomvang%20stap%203B.pdf",
    what_does_the_pso_quality_mark_cost: "https://www.pso-nederland.nl/direct-meten-en-aanvragen/direct-meten-of-het-pso-keurmerk-aanvragen-2/wat-kost-het-pso-keurmerk",
    apply_for_pso_quality_mark: "https://www.pso-nederland.nl/direct-meten-en-aanvragen/pso-keurmerk-aanvragen",
    pso_avg: "https://www.pso-nederland.nl/over-de-pso/avg",

}
