<template>
    <div
        v-show="show"
        class="flex p-6 text-green-700 bg-green-500 bg-opacity-20 rounded-lg border-green-500 border"
        role="alert"
    >
        <p
            v-for="(message) in alertMessages"
            :key="message"
            class="text-green-700 mb-8 last-of-type:mb-0 font-normal"
        >
            {{ message }}
        </p>

        <button
            class="ml-auto inline-flex hover:cursor-pointer mt-1.5"
            aria-label="Close"
            @click.prevent="hideAlert()"
        >
            <img
                class="h-3 w-3"
                :src="iconClose"
                alt="close"
            >
        </button>
    </div>
</template>

<script>

export default {
    props: {
        idProp: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            show: false,
            alertMessages: '',
            iconClose: new URL('/resources/images/icon_times.svg', import.meta.url).href
        }
    },
    created() {
        window.mitt.on('showSuccessAlert', (data) => this.toggleAlert(data))
    },
    methods: {
        toggleAlert(data) {
            if (this.idProp === data.id) {
                this.show = !this.show;
                this.alertMessages = data.messages;
                setTimeout(() => this.hideAlert(), 5000);
            } else {
                this.show = false;
            }
        },
        hideAlert() {
            this.show = false;
            window.mitt.emit("updateUploadStatus", { status: '' });
        }
    }
}
</script>
