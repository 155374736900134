<template>
    <div>
        <select
            :id="id"
            :value="modelValue"
            :disabled="disabled"
            :name="name"
            :class="{
                'border-red-500': errors,
                'bg-gray-050 text-pso-dark': disabled
            }"
            class="form-input px-6 py-3 w-full text-pso-dark border-gray-200 text-lg placeholder:lowercase placeholder:text-gray-600 rounded-xl"
            :type="hasDecimals ? 'text' : 'number'"
            :step="stepInterval"
            @change="formatOnInput($event)"
        >
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                :disabled="option.disabled">
                {{ option.label }}
            </option>
        </select>
        <div v-if="errors" class="mb-2">
            <span
                dusk="{{ duskErrorName }}"
                class="text-sm text-red-500"
                role="alert"
                v-text="errors"
            />
        </div>
    </div>
</template>
<script>

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [Number, String],
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        errors: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null
        },
        stepInterval: {
            type: String,
            default: '1',
        },
        hasDecimals: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'update:modelValue': [String]
    },
    methods: {
        formatOnInput($event) {
            if ($event.target.value) {
                this.$emit('update:modelValue', $event.target.value);
            } else {
                this.$emit('update:modelValue', null);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
/* Hide up/down arrows on number input field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
</style>
