<template>
    <div class="page organisation-index">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px]  mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <h2 class="text-center text-pso-dark text-2xl font-bold">
                    {{ translate('organisation.title') }}
                </h2>
                <p
                    class="resources/ mt-10"
                >
                    {{ translate('organisation.index.paragraph_1') }}
                </p>

                <p
                    class="text-pso-dark mt-10"
                >
                    {{ translate('organisation.index.paragraph_2') }}
                </p>

                <p
                    class="text-pso-dark mt-10"
                >
                    {{ translate('organisation.index.paragraph_3') }}
                </p>

                <div class="action-button text-center mt-[80px]">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        @click.prevent="nextStepRoute()"
                    >
                        {{ translate('organisation.index.continue') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CalculationTool from "../../../CalculationTool/CalculationTool";
import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import Organisation from "../../Organisation";
import {getSteps} from "../../../_config/Steps";

export default {
    components: {CalculationToolHeader},
    props: {
        calculationToolIdProp: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {};
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
    },
    methods: {
        nextStepRoute() {
            CalculationTool.updateStep({ calculationTool: this.calculationToolIdProp, step: getSteps().determine_organisation })
                .then((response) => {
                    if (response.status === 200) {
                        window.location = route('organisation.list', {calculationTool: this.calculationToolIdProp});
                    }
                }).catch((error) => {
                    console.log(error.response.data.data.message);
                });
        }
    },
};
</script>
