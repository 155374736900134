import _ from "lodash";
import { createApp } from 'vue'

import RegisterForm from "./Vue/RegisterForm.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {RegisterForm},
    }
    return createApp(_.merge(options, vueOptions));
}
