<template>
    <div class="page register-page">
        <div class="container mx-auto py-8">
            <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto bg-white rounded-xl shadow pt-6 pb-8 pl-8 pr-8 px-8">
                <div class="actions-top">
                    <app-back-button :href="backRoute()" />
                    <div class="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-4">
                        <div
                            class="bg-pso-primary h-1.5 rounded-full"
                            style="width: 100%"
                        />
                    </div>
                </div>

                <h2 class="page-header text-center text-xl mb-12 font-medium text-pso-dark mt-12">
                    {{ translate('auth.register.page_title') }}
                </h2>

                <form
                    id="sign_up_form"
                    class="form"
                    @submit.prevent="registerUser"
                >
                    <slot />

                    <h3 class="page-header mb-12 text-pso-dark font-medium text-center text-lg">
                        {{ translate('auth.register.page_subtitle') }}
                    </h3>

                    <div class="w-full mb-8">
                        <label
                            for="email"
                            class="block mb-2 text-lg text-pso-dark"
                        >
                            {{ translate('auth.register.email_account_holder') }} *
                        </label>

                        <input
                            id="email"
                            v-model="registerForm.email"
                            type="text"
                            class="form-input w-full border-gray-200 rounded-xl px-6 py-3 text-pso-dark text-lg placeholder:lowercase placeholder:text-gray-600"
                            :class="{'border-red-500': errors.email}"
                            name="email"
                            :placeholder="translate('auth.register.email_account_holder')"
                            autocomplete="email"
                        >
                        <span
                            v-if="errors.email"
                            dusk="email-validation"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.email"
                        />
                    </div>

                    <div class="w-full mb-8">
                        <label
                            for="password"
                            class="block mb-2 text-lg text-pso-dark"
                        >
                            {{ translate('auth.register.password') }} *
                        </label>
                        <input
                            id="password"
                            v-model="registerForm.password"
                            type="password"
                            class="form-input w-full border-gray-200 rounded-xl px-6 py-3 text-pso-dark text-lg placeholder:lowercase placeholder:text-gray-600"
                            :class="{'border-red-500': errors.password}"
                            name="password"
                            :placeholder="translate('auth.register.password')"
                            autocomplete="password"
                        >
                        <span
                            v-if="errors.password"
                            dusk="password-validation"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.password"
                        />
                    </div>

                    <div class="w-full mb-8">
                        <label
                            for="password_confirmation"
                            class="form-label block mb-2 text-lg text-pso-dark"
                        >
                            {{ translate('auth.register.confirm_password') }} *
                        </label>

                        <input
                            id="password_confirmation"
                            v-model="registerForm.password_confirmation"
                            type="password"
                            class="form-input w-full border-gray-200 rounded-xl px-6 py-3 text-pso-dark text-lg placeholder:lowercase placeholder:text-gray-600"
                            :class="{'border-red-500': errors.password_confirmation}"
                            name="password_confirmation"
                            :placeholder="translate('auth.register.confirm_password')"
                            autocomplete="password_confirmation"
                        >

                        <span
                            v-if="errors.password_confirmation"
                            dusk="password-confirmation-validation"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.password_confirmation"
                        />
                    </div>

                    <div class="w-full mb-8">
                        <div class="flex">
                            <div
                                class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl h-[48px] mr-4 w-1/2"
                                :class="registerForm.gender == 'M' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                            >
                                <label
                                    for="genderMale"
                                    class="items-center mb-0 py-[14px] pr-10 text-lg text-black text-lg"
                                >{{ translate('auth.register.gender_male') }}</label>
                                <input
                                    id="genderMale"
                                    v-model="registerForm.gender"
                                    type="radio"
                                    class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-3 ml-6 cursor-pointer "
                                    :class="{'border-red-500': errors.gender}"
                                    name="gender"
                                    value="M"
                                >
                            </div>

                            <div
                                class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl h-[48px] w-1/2"
                                :class="registerForm.gender == 'F' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                            >
                                <label
                                    for="genderFemale"
                                    class="items-center mb-0 py-[14px] pr-20 text-lg text-black text-lg"
                                >
                                    {{ translate('auth.register.gender_female') }}
                                </label>
                                <input
                                    id="genderFemale"
                                    v-model="registerForm.gender"
                                    type="radio"
                                    class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-3 ml-6 cursor-pointer"
                                    :class="{'border-red-500': errors.gender}"
                                    name="gender"
                                    value="F"
                                >
                            </div>
                        </div>

                        <span
                            v-if="errors.gender"
                            dusk="gender-validation"
                            class="block text-sm text-red-500"
                            role="alert"
                            v-text="errors.gender"
                        />
                    </div>

                    <div class="w-full mb-8 flex">
                        <div class="w-1/2 mr-4">
                            <label
                                for="initials"
                                class="block mb-2 text-lg text-pso-dark"
                            >
                                {{ translate('auth.register.initials') }} *
                            </label>

                            <input
                                id="initials"
                                v-model="registerForm.initials"
                                type="text"
                                class="form-input px-6 py-3 rounded-xl w-full border-gray-200 placeholder:lowercase text-pso-dark text-lg placeholder:lowercase placeholder:text-gray-600"
                                :class="{'border-red-500': errors.initials}"
                                name="initials"
                                maxlength="10"
                                :placeholder="translate('auth.register.initials')"
                                autocomplete="initials"
                            >

                            <span
                                v-if="errors.initials"
                                dusk="initials-validation"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.initials"
                            />
                        </div>

                        <div class="w-1/2">
                            <label
                                for="first_name"
                                class="block mb-2 text-lg text-pso-dark"
                            >
                                {{ translate('auth.register.first_name') }} *
                            </label>

                            <div class="col-md-6">
                                <input
                                    id="first_name"
                                    v-model="registerForm.first_name"
                                    type="text"
                                    class="form-input px-6 py-3 rounded-xl w-full border-gray-200 placeholder:lowercase text-pso-dark text-lg placeholder:lowercase placeholder:text-gray-600"
                                    :class="{'border-red-500': errors.first_name}"
                                    name="first_name"
                                    :placeholder="translate('auth.register.first_name')"
                                    autocomplete="first_name"
                                >

                                <span
                                    v-if="errors.first_name"
                                    dusk="first-name-validation"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.first_name"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="w-full mb-8 flex">
                        <div class="w-1/2 mr-4">
                            <label
                                for="infix"
                                class="block mb-2 text-lg text-pso-dark"
                            >
                                {{ translate('auth.register.infix') }}
                            </label>

                            <input
                                id="infix"
                                v-model="registerForm.infix"
                                type="text"
                                class="form-input px-6 py-3 rounded-xl w-full border-gray-200 placeholder:lowercase text-pso-dark text-lg placeholder:lowercase placeholder:text-gray-600"
                                :class="{'border-red-500': errors.infix}"
                                name="infix"
                                :placeholder="translate('auth.register.infix')"
                                maxlength="10"
                                autocomplete="infix"
                            >

                            <span
                                v-if="errors.infix"
                                dusk="infix-validation"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.infix"
                            />
                        </div>

                        <div class="w-1/2">
                            <label
                                for="last_name"
                                class="block mb-2 text-lg text-pso-dark"
                            >
                                {{ translate('auth.register.last_name') }} *
                            </label>

                            <div class="col-md-6">
                                <input
                                    id="last_name"
                                    v-model="registerForm.last_name"
                                    type="text"
                                    class="form-input px-6 py-3 rounded-xl w-full border-gray-200 placeholder:lowercase text-pso-dark text-lg placeholder:lowercase placeholder:text-gray-600"
                                    :class="{'border-red-500': errors.last_name}"
                                    name="last_name"
                                    :placeholder="translate('auth.register.last_name')"
                                    autocomplete="last_name"
                                >

                                <span
                                    v-if="errors.last_name"
                                    dusk="last-name-validation"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.last_name"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="w-full mb-6">
                        <div class="w-full flex flex-row-reverse justify-end">
                            <label
                                for="agree_terms_conditions"
                                class="text-md ml-2 text-md leading-4 text-pso-dark"
                            >
                                {{ translate('auth.register.agree_terms_conditions_label') }}
                                <a
                                    href="#"
                                    class="text-pso-primary underline"
                                >{{ translate('auth.register.agree_terms_conditions_link_text') }}</a> *
                            </label>

                            <input
                                id="agree_terms_conditions"
                                v-model="registerForm.agree_terms_conditions"
                                class="border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                :class="{'border-red-500': errors.agree_terms_conditions}"
                                name="agree_terms_conditions"
                                value="1"
                                autocomplete="agree_terms_conditions"
                            >
                        </div>
                        <span
                            v-if="errors.agree_terms_conditions"
                            dusk="agree-terms-conditions-validation"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.agree_terms_conditions"
                        />
                    </div>

                    <div class="w-full mb-6">
                        <div class="w-full flex flex-row-reverse justify-end">
                            <label
                                for="agree_TNO_contact"
                                class="text-md ml-2 text-md leading-4 text-pso-dark"
                            >
                                {{ translate('auth.register.agree_TNO_contact') }}
                            </label>
                            <input
                                id="agree_TNO_contact"
                                v-model="registerForm.agree_TNO_contact"
                                class="border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                :class="{'border-red-500': errors.agree_TNO_contact}"
                                name="agree_TNO_contact"
                                value="1"
                                autocomplete="agree_TNO_contact"
                            >
                        </div>
                    </div>

                    <div class="w-full mb-6">
                        <div class="w-full flex flex-row-reverse justify-end">
                            <label
                                for="agree_newsletter"
                                class="text-md ml-2 text-md leading-4 text-pso-dark"
                            >
                                {{ translate('auth.register.agree_newsletter') }}
                            </label>
                            <input
                                id="agree_newsletter"
                                v-model="registerForm.agree_newsletter"
                                class="border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                name="agree_newsletter"
                                value="1"
                                autocomplete="agree_newsletter"
                            >
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="w-full mt-4 mb-8 px-12 py-4 rounded-xl bg-pso-orange text-white font-medium"
                    >
                        {{ translate('auth.register.create_account') }}
                    </button>

                    <div>
                        <a
                            :href="loginRoute()"
                            class="block text-center text-pso-secondary text-md underline cursor-pointer hover:no-underline"
                        >
                            {{ translate('auth.register.i_have_account') }}
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import User from "../../../../User/User";
import BackLinkButton from "../../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";

export default {
    components: {'app-back-button': BackLinkButton},
    data() {
        return {
            registerForm: {},
            failed: null,
            success: null,
            errors: {}
        };
    },

    methods: {
        registerUser() {
            let formData = User.buildRegisterForm(this.registerForm);
            User.register(formData)
                .then(() => {
                    let dialogData = {
                        header: this.translate('auth.register.registration_completed_title'),
                        body: this.translate('auth.register.registration_completed_body'),
                        buttons: [{
                            text: this.translate('layout.modals.ok_button_text'),
                            redirect: this.loginRoute()
                        }]

                    }

                    window.mitt.emit("dialogMessage", dialogData);
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        this.$setErrorsFromResponse(error.response.data);
                    }
                });
        },
        loginRoute() {
            return route('login.show-login-form');
        },
        backRoute() {
            if (typeof document.referrer !== 'undefined') {
                return document.referrer;
            }
            return route('register.show-registration-second-step');
        }
    }
};
</script>
