import {createApp} from 'vue';
import _ from "lodash";
import childOrganisationList from "./Vue/ChildOrganisationList.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {childOrganisationList: childOrganisationList},
    };

    return createApp(_.merge(options, vueOptions));
}