import {createApp} from 'vue';
import _ from "lodash";
import childOrganisationValidationChecks from "./Vue/ChildOrganisationValidationChecks.vue";
export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {childOrganisationValidationChecks: childOrganisationValidationChecks},
    }

    return createApp(_.merge(options, vueOptions));
}