<template>
    <div class="widget timeline-widget">
        <div class="xl:bg-white text-right -mb-10 xl:mb-0 pt-12 pb-0 xl:py-20 xl:pr-10">
            <div class="relative flex flex-row xl:justify-start xl:flex-col max-w-[90%] xl:max-w-full min-w-[130px] md:min-w-[155px] m-auto text-right">
                <div
                    v-for="(step) in filteredSteps"
                    :key="step.id"
                    class="relative z-10 w-full mb-8"
                >
                    <button
                        class="flex flex-col xl:flex-row justify-start items-center w-full"
                        :class="(calculationToolCurrentStep < step.id) ? 'cursor-default' : 'cursor-pointer'"
                        @click.prevent="changeStep(step)"
                    >
                        <div
                            v-if="(currentStep >= Math.floor(step.last) || step.id < Math.floor(step.last)) && step.id !== step.last"
                            class="absolute z-1 top-4 xl:top-8 left-[55px] xl:left-[22px] w-full xl:w-1 h-1 xl:h-20"
                            :class="(calculationToolCurrentStep > step.id) ? 'bg-pso-secondary' : 'bg-gray-100'"
                        />
                        <div
                            class="relative flex items-center justify-center z-2 rounded-full p-2.5 text-xl font-medium"
                            :class="
                                (currentStep === step.id) ? 'text-white bg-pso-primary' : (calculationToolCurrentStep >= step.id) ? 'text-white group-hover:bg-pso-dark bg-pso-secondary' : 'text-gray-800 bg-gray-100',
                                (step.id % 1 === 0) ? 'w-12 h-12 min-w-[48px]' : 'mt-1 ml-3.5'
                            "
                        >
                            {{ (step.id % 1 === 0) ? step.id : '' }}
                        </div>

                        <span
                            class="mt-3 xl:mt-0 xl:ml-4 text-sm md:text-lg xl:text-left md:text-center w-3/4"
                            :class="(currentStep === step.id) ? 'text-pso-primary' : (calculationToolCurrentStep >= step.id) ? 'group-hover:bg-pso-dark group-hover:font-medium text-pso-secondary' : 'text-gray-800'"
                        >
                            {{ step.name }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getSteps} from "../../../../_config/Steps"
import axios from "axios";
import {router} from "@inertiajs/vue3";

export default {
    props: {
        calculationToolIdProp: {
            type: Number,
            default: null,
        },
        organisationIdProp: {
            type: Number,
            default: null,
        },
        currentStepProp: {
            type: Number,
            default: getSteps().list,
        },
    },
    data() {
        return {
            calculationToolCurrentStep: null,
            currentStep: getSteps.list,
            steps: [],
            initialized: false,
        };
    },
    computed: {
        filteredSteps() {
            // Only show the sub-steps of the main-step we are in now
            return this.steps.filter((item) =>
                !(item.id % 1 !== 0 && Math.floor(item.id) !== Math.floor(this.currentStep))
            );
        }
    },
    watch: {
        currentStepProp(oldValue, newValue) {
            this.currentStep = this.currentStepProp;
        }
    },
    mounted() {
        this.currentStep = this.currentStepProp ?? 1;

        let calculationToolCurrentStep = JSON.parse(sessionStorage.getItem('calculation_tool_current_step')) || null;
        let steps = /* JSON.parse(sessionStorage.getItem('calculation_tool_steps')) || */ [];

        if (steps.length === 0 || calculationToolCurrentStep === null || calculationToolCurrentStep < this.currentStep) {
            axios.get('/api/calculation-tools/get-steps-with-progress', {params: {calculationToolId: this.calculationToolIdProp}})
                .then((response) => {
                    calculationToolCurrentStep = response.data.data.currentStep < this.currentStep
                        ? this.currentStep
                        : response.data.data.currentStep;
                    sessionStorage.setItem('calculation_tool_current_step', JSON.stringify(calculationToolCurrentStep));
                    this.calculationToolCurrentStep = calculationToolCurrentStep;

                    steps = response.data.data.steps;
                    // sessionStorage.setItem('calculation_tool_steps', JSON.stringify(steps));
                    this.steps = steps;
                });
        }

        this.calculationToolCurrentStep = calculationToolCurrentStep;
        this.steps = steps;

        this.initialized = true;
    },
    methods: {
        changeStep(step) {
            if (
                step.id > this.calculationToolCurrentStep &&
                step.id !== 1
            ) {
                return false;
            }

            window.location.href = route(step.route, { calculationTool: this.calculationToolIdProp, organisationId: this.organisationIdProp });
        }
    }
};
</script>
