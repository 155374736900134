const Statuses = {
    PROSPECTS: 1,
    QUOTATION: 2,
    FINAL: 3,
    AUDITOR: 4,
    PSOCHECK: 5,
    ARCHIVE: 10,
    CANCELLED: 11,
}

export function getStatuses() {
    return Statuses;
}
