<template>
    <BaseMultiSelectInput
        :value="modelValue"
        :can-clear="false"
        :name="name"
        :class="{
            '!border-red-500': errors,
        }"
        :options="options"
        :placeholder="placeholder"

        @update:modelValue="(value) => $emit('update:modelValue', value)"

    />
</template>
<script>
import BaseMultiSelectInput from "../../BaseMultiSelectInput/Vue/BaseMultiSelectInput.vue";

export default {
    components: {
        BaseMultiSelectInput,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: [Array, Object, Function],
            required: false,
            default: () => ([])
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
        errors: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
    },
    emits: {
        'update:modelValue': String
    }
}
</script>
