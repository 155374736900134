<template>
    <div class="show-more-element relative">
        <Transition name="slide-fade">
            <div
                v-show="isExpanded"
                class="show-more-element__hidden-section"
            >
                <slot>Hidden content</slot>
            </div>
        </Transition>

        <div
            :class="(buttonPosition === 'absolute') ? 'absolute right-0' : 'action-wrapper flex justify-end'"
            :style="(topPosition !== '') ? 'top: ' + topPosition : ''"
        >
            <button
                class="action-button flex items-center p-0 bg-none"
                :class="(buttonPosition === 'absolute') ? '' : ''"
                :dusk="btnToggleHiddenPartDusk"
                @click="toggle()"
            >
                <span
                    class="mr-2 text-pso-primary text-lg inline-block"
                >
                    {{ toggleMoreInfoText }}
                </span>

                <img
                    :src="iconChevronDown"
                    :class="{'rotate-180': isExpanded}"
                    class="icon-chevron-down transition-transform duration-300"
                    alt="chevron down"
                >
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        buttonPosition: {
            type: String,
            default: ''
        },
        topPosition: {
            type: String,
            default: ''
        },
        btnShowMoreText: {
            type: String,
            default: ''
        },
        btnToggleHiddenPartDuskProp: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isExpanded: false,
            toggleMoreInfoText: this.btnShowMoreText !== '' ? this.btnShowMoreText : this.translate('components.show-more-element.button.show_more'),
            iconChevronDown: new URL('/resources/images/icon_chevron_down.svg', import.meta.url).href,
            btnToggleHiddenPartDusk: this.btnToggleHiddenPartDuskProp ?? 'btn-toggle-hidden-part'
        };
    },
    methods: {
        toggle() {
            this.isExpanded = !this.isExpanded;
            this.toggleMoreInfoText = this.isExpanded
                ? this.translate('components.show-more-element.button.show_less')
                : this.btnShowMoreText !== '' ? this.btnShowMoreText : this.translate('components.show-more-element.button.show_more')
        },
    }
};
</script>
