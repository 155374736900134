    <template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('calculation_tool_data.intro.title') }}
                </h2>
                <p
                    class="text-pso-dark mt-10"
                >
                    <strong class="text-pso-dark">{{ translate('calculation_tool_data.intro.paragraph_1_title') }}</strong>
                    <br />
                    {{ translate('calculation_tool_data.intro.paragraph_1') }}
                </p>

                <p
                    class="text-pso-dark mt-10"
                >
                    <strong class="text-pso-dark">{{ translate('calculation_tool_data.intro.paragraph_2_title') }}</strong>
                    <br />
                    {{ translate('calculation_tool_data.intro.paragraph_2') }}
                </p>

                <p
                    class="text-pso-dark mt-10"
                >
                    {{ translate('calculation_tool_data.intro.paragraph_3') }}
                </p>

                <div class="action-button text-center mt-[80px]">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        @click="nextPageRoute()"
                    >
                        {{ translate('calculation_tool_data.intro.button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import CalculationTool from '../../../CalculationTool/CalculationTool';
import CalculationToolData from "../../../CalculationToolData/CalculationToolData";
import Organisation from "../../../Organisation/Organisation";
import {getSteps} from "../../../_config/Steps"

export default {
    components: {CalculationToolHeader},
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
    },
    methods: {
        nextPageRoute() {
            CalculationTool.updateStep({ calculationTool: this.calculationToolIdProp, step: getSteps().reference_date })
                .then((response) => {
                    if (response.status === 200) {
                        window.location = route('calculation-tool-data.reference-date-cao-hours', {calculationTool: this.calculationToolIdProp});
                    }
                }).catch((error) => {
                    console.log(error.response.data.data.message);
                });
        }
    }
};
</script>
