<template>
    <div class="max-w-[1108px]  mx-auto pt-5 pb-4">
        <span class="text-pso-primary font-medium text-base">
            {{ translate('organisation.current_organisation') }}: {{ organisation.company_name }} | {{ translate('calculation_tool.list.name') }}: {{ calculation_tool.name }}
        </span>
        <a
            v-if="inCalculationToolEditProp"
            :href="step1Route()"
            class="float-right text-pso-primary text-md font-normal underline"
        >
            {{ translate('organisation.change_calculation_tool_auditor') }}
        </a>
        <auditor-switch-button
            v-if="inCalculationToolEditProp"
            :in-score-view="inScoreView"
            :selected-score="selectedScore"
        />
    </div>
</template>

<script>

import AuditorSwitchButton from "../../../../Tools/Parts/AuditorSwitchButton/Vue/AuditorSwitchButton.vue";
import CalculationTool from "../../../CalculationTool";
import {mapGetters} from "vuex";

export default {
    components: {
        AuditorSwitchButton,
    },
    props: {
        inCalculationToolEditProp: {
            type: Boolean,
            default: true,
        },
        inScoreView: {
            type: Boolean,
            default: false,
        },
        selectedScore: {
            type: String,
            default: 'original',
        },
    },
    computed: {
        ...mapGetters("calculation_tool", ["calculation_tool"]),
        ...mapGetters("organisation", ["organisation"]),
    },
    methods: {
        step1Route() {
            return route('dashboard');
        },
    },
};
</script>
