<template>
    <div class="page organisation-manually-add">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button :href="backRoute()" />
                </div>
                <h1
                    v-if="isMainOrganisation"
                    class="text-center text-pso-dark text-2xl font-bold mb-10"
                >
                    {{ translate('organisation.kvk.title') }}
                </h1>
                <h1
                    v-if="!isMainOrganisation"
                    class="text-center text-pso-dark text-2xl font-bold mb-10"
                >
                    {{ translate('organisation.child_organisation.kvk.title') }}
                </h1>
                <div>
                    <p
                        v-if="isMainOrganisation"
                        class="mb-10 text-lg text-pso-dark"
                    >
                        {{ translate('organisation.kvk.paragraph_manually_add') }}
                    </p>
                    <p
                        v-if="!isMainOrganisation"
                        class="mb-10 text-lg text-pso-dark"
                    >
                        {{ translate('organisation.child_organisation.kvk.paragraph_manually_add') }}
                    </p>

                    <form
                        id="kvk_request_form"
                        class="form"
                        @submit.prevent="submitRequest"
                    >
                        <div class="mb-8 bg-pso-light">
                            <div class="px-16 py-8">
                                <div class="flex items-center mb-4">
                                    <div
                                        :class="{'pb-6':errors.kvk_number}"
                                        class="w-1/4"
                                    >
                                        <span class="text-lg text-pso-dark">
                                            {{ translate('organisation.kvk.simple_field_label') }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="!kvkNumber"
                                        class="w-3/4"
                                    >
                                        <input
                                            id="kvk_number"
                                            v-model="requestKvkDataForm.kvk_number"
                                            :class="{'border-red-500': errors.kvk_number}"
                                            :placeholder="translate('organisation.kvk.field_placeholder')"
                                            autocomplete="kvk_number"
                                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                            name="kvk_number"
                                            type="text"
                                        >
                                        <span
                                            v-if="errors.kvk_number"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.kvk_number"
                                        />
                                    </div>
                                    <div
                                        v-if="kvkNumber"
                                        class="w-3/4 flex items-center justify-between"
                                    >
                                        <span class="px-6">{{ kvkNumber }}</span>
                                        <a
                                            :href="backRoute()"
                                            class="-mr-8"
                                            dusk="bin-icon"
                                        >
                                            <img
                                                :src="iconDelete" 
                                                alt="chevron left"
                                                class="h-4"
                                            >
                                        </a>
                                    </div>
                                </div>

                                <div class="flex items-center mb-4">
                                    <div
                                        :class="{'pb-6':errors.company_name}"
                                        class="w-1/4"
                                    >
                                        <span class="text-lg text-pso-dark">
                                            {{ translate('organisation.kvk.company_name') }}*
                                        </span>
                                    </div>
                                    <div class="w-3/4">
                                        <input
                                            id="company_name"
                                            v-model="requestKvkDataForm.company_name"
                                            :class="{'border-red-500': errors.company_name}"
                                            :placeholder="translate('organisation.kvk.company_name')"
                                            autocomplete="company_name"
                                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                            name="company_name"
                                            type="text"
                                        >
                                        <span
                                            v-if="errors.company_name"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.company_name"
                                        />
                                    </div>
                                </div>
                                <div class="flex items-top mb-4">
                                    <div class="w-1/4 pt-3">
                                        <span class="text-lg text-pso-dark">
                                            {{ translate('organisation.kvk.address') }}*
                                        </span>
                                    </div>
                                    <div class="w-3/4">
                                        <div class="mb-4">
                                            <input
                                                id="street_name"
                                                v-model="requestKvkDataForm.street_name"
                                                :class="{'border-red-500': errors.street_name}"
                                                :placeholder="translate('organisation.kvk.street_name_placeholder')"
                                                autocomplete="street_name"
                                                class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                                name="street_name"
                                                type="text"
                                            >
                                            <span
                                                v-if="errors.street_name"
                                                class="text-sm text-red-500"
                                                role="alert"
                                                v-text="errors.street_name"
                                            />
                                        </div>
                                        <div class="flex">
                                            <div class="w-1/2 mb-4 pr-2">
                                                <input
                                                    id="house_number"
                                                    v-model="requestKvkDataForm.house_number"
                                                    :class="{'border-red-500': errors.house_number}"
                                                    :placeholder="translate('organisation.kvk.house_number_placeholder')"
                                                    autocomplete="house_number"
                                                    class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                                    name="house_number"
                                                    type="text"
                                                >
                                                <span
                                                    v-if="errors.house_number"
                                                    class="text-sm text-red-500"
                                                    role="alert"
                                                    v-text="errors.house_number"
                                                />
                                            </div>
                                            <div class="w-1/2 mb-4 pl-2">
                                                <input
                                                    id="addition"
                                                    v-model="requestKvkDataForm.addition"
                                                    :class="{'border-red-500': errors.addition}"
                                                    :placeholder="translate('organisation.kvk.addition_placeholder')"
                                                    autocomplete="addition"
                                                    class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                                    name="addition"
                                                    type="text"
                                                >
                                                <span
                                                    v-if="errors.addition"
                                                    class="text-sm text-red-500"
                                                    role="alert"
                                                    v-text="errors.addition"
                                                />
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="w-1/2 pr-2">
                                                <input
                                                    id="postal_code"
                                                    v-model="requestKvkDataForm.postal_code"
                                                    :class="{'border-red-500': errors.postal_code}"
                                                    :placeholder="translate('organisation.kvk.postal_code_placeholder')"
                                                    autocomplete="postal_code"
                                                    class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                                    name="postal_code"
                                                    type="text"
                                                >
                                                <span
                                                    v-if="errors.postal_code"
                                                    class="text-sm text-red-500"
                                                    role="alert"
                                                    v-text="errors.postal_code"
                                                />
                                            </div>
                                            <div class="w-1/2 pl-2">
                                                <input
                                                    id="city"
                                                    v-model="requestKvkDataForm.city"
                                                    :class="{'border-red-500': errors.city}"
                                                    :placeholder="translate('organisation.kvk.city_placeholder')"
                                                    autocomplete="city"
                                                    class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                                    name="city"
                                                    type="text"
                                                >
                                                <span
                                                    v-if="errors.city"
                                                    class="text-sm text-red-500"
                                                    role="alert"
                                                    v-text="errors.city"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center mb-4">
                                    <div
                                        :class="{'pb-6':errors.sbi_code}"
                                        class="w-1/4"
                                    >
                                        <span class="text-lg text-pso-dark">
                                            {{ translate('organisation.kvk.sbi_code') }}*
                                        </span>
                                    </div>
                                    <div class="w-3/4">
                                        <input
                                            id="sbi_code"
                                            v-model="requestKvkDataForm.sbi_code"
                                            :class="{'border-red-500': errors.sbi_code}"
                                            :placeholder="translate('organisation.kvk.sbi_code')"
                                            autocomplete="sbi_code"
                                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                            name="sbi_code"
                                            type="text"
                                        >
                                        <span
                                            v-if="errors.sbi_code"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.sbi_code"
                                        />
                                    </div>
                                </div>
                                <div class="flex items-center mb-4">
                                    <div
                                        :class="{'pb-6':errors.sbi_description}"
                                        class="w-1/4"
                                    >
                                        <span class="text-lg text-pso-dark">
                                            {{ translate('organisation.kvk.sbi_description') }}
                                        </span>
                                    </div>
                                    <div class="w-3/4">
                                        <input
                                            id="sbi_description"
                                            v-model="requestKvkDataForm.sbi_description"
                                            :class="{'border-red-500': errors.sbi_description}"
                                            :placeholder="translate('organisation.kvk.sbi_description')"
                                            autocomplete="sbi_description"
                                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                                            name="sbi_description"
                                            type="text"
                                        >
                                        <span
                                            v-if="errors.sbi_description"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.sbi_description"
                                        />
                                    </div>
                                </div>
                                <div class="flex items-center mb-4">
                                    <div
                                        :class="{'pb-6':errors.branch}"
                                        class="w-1/4"
                                    >
                                        <span class="text-lg text-pso-dark">
                                            {{ translate('organisation.summary.details.branch') }}
                                        </span>
                                    </div>
                                    <div class="w-3/4">
                                        <SelectInput
                                            id="branch"
                                            v-model="requestKvkDataForm.branch"
                                            :errors="errors.branch"
                                            :name="requestKvkDataForm.branch"
                                            :placeholder="translate('organisation.branch.placeholder')"
                                            :available-options="availableBranches"
                                            :options="availableBranches">
                                        </SelectInput>
                                        <span
                                            v-if="errors.branch"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.branch"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="text-right">
                                <a
                                    v-if="!isMainOrganisation"
                                    :href="backRoute()"
                                    class="text-pso-secondary text-md font-normal underline mr-2"
                                >{{ translate('organisation.child_organisation.kvk.cancel_add_manually') }}</a>
                                <button
                                    class="d-block mb-8 mr-16 px-4 py-2 rounded-xl bg-pso-orange text-white font-medium"
                                    type="submit"
                                >
                                    {{ translate('organisation.kvk.save') }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import Organisation from "../../Organisation";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import SelectInput from "../../../FormInput/SelectInput/Vue/SelectInput.vue";

export default {
    components: {SelectInput, CalculationToolHeader, 'app-back-button': BackLinkButton},
    props: {
        calculationToolIdProp: {
            type: String,
            default: null,
        },
        organisationIdProp: {
            type: String,
            default: null,
        },
        isMainOrganisationProp: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            requestKvkDataForm: {},
            errors: {},
            isMainOrganisation: true,
            kvkNumber: null,
            branchId: null,
            availableBranches: [],
            iconDelete: new URL('/resources/images/icon_delete.svg', import.meta.url).href
        };
    },
    mounted() {
        this.isMainOrganisation = Boolean(this.isMainOrganisationProp);
        CalculationTool.get(this.calculationToolIdProp);
        this.getBranches();

        let getParams = new URLSearchParams(window.location.search);
        this.kvkNumber = getParams.get("kvk_number");
        this.branchId = getParams.get("branch_id");

        if (this.kvkNumber) {
            this.requestKvkDataForm.kvk_number = this.kvkNumber;
        }

        if (this.branchId) {
            this.requestKvkDataForm.branch = this.branchId;
        }
    },
    methods: {
        getBranches() {
            Organisation.getBranches()
                .then((response) => {
                    response.map((branch) => {
                        this.availableBranches.push({
                            value: branch.id,
                            label: branch.name,
                        });
                    });
                });
        },
        submitRequest() {
            let formData = Organisation.buildCreateOrganisationWithManuallyAddedDataForm(this.requestKvkDataForm);
            Organisation.createOrganisation(this.calculationToolIdProp, formData)
                .then((data) => {
                    //get the organisation id that was just created
                    if (typeof data.data.organisation.id !== 'undefined') {
                        if (data.data.is_main) {
                            //and then redirect to the details page of the main organisation
                            window.location.href = route('organisation.detail', {
                                calculationTool: this.calculationToolIdProp,
                                organisationId: data.data.organisation.id
                            });
                        } else {
                            //and then redirect to the details page of the main organisation
                            window.location.href = route('organisation.child-organisation-detail', {
                                calculationTool: this.calculationToolIdProp,
                                organisationId: data.data.organisation.id
                            });
                        }
                    }
                    throw "No organisation ID received!";
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        this.$setErrorsFromResponse(error.response.data);
                    }
                });
        },
        backRoute() {
            return route('organisation.child-organisation-create-from-kvk', {
                calculationTool: this.calculationToolIdProp,
                organisationId: this.organisationIdProp
            });
        },
    }
};
</script>
