<template>
    <div class="page 2fa-setup-step-one">
        <div class="container mx-auto py-8">
            <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto mb-5 mt-5">
                <div class="page-header text-pso-primary font-bold text-3xl">
                    {{ translate('auth.two_factor_auth.setup.page_title') }}
                </div>
            </div>
            <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto bg-white rounded-xl shadow p-12 px-8">
                <form
                    id="which_2fa_form"
                    class="form"
                    @submit.prevent="submit2FASelection"
                >
                    <slot />

                    <div class="w-full mb-8">
                        <p class="text-pso-dark">{{ translate('auth.two_factor_auth.setup.intro_text') }}</p>
                    </div>

                    <div class="w-full mb-8">
                        <p class="mb-3">{{ translate('auth.two_factor_auth.setup.field_info') }}</p>
                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl mb-2"
                            :class="twoFactorSelectForm.type === 'authenticator' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="typeAuthenticator"
                                class="text-md text-pso-dark items-center mb-0 pl-2 pt-3 pb-3 pl-3"
                            >{{ translate('auth.two_factor_auth.setup.type_authenticator') }}</label>
                            <input
                                id="typeAuthenticator"
                                v-model="twoFactorSelectForm.type"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                                :class="{'border-red-500': errors.type}"
                                name="type"
                                value="authenticator"
                                checked="checked"
                                dusk="radio-authenticator"
                            >
                        </div>

                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl"
                            :class="twoFactorSelectForm.type === 'email' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="typeEmail"
                                class="text-md text-pso-dark items-center mb-0 pl-2 pt-3 pb-3 pl-3"
                                dusk="email-validation"
                            >
                                {{ translate('auth.two_factor_auth.setup.type_email') }}
                            </label>
                            <input
                                id="typeEmail"
                                v-model="twoFactorSelectForm.type"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer"
                                :class="{'border-red-500': errors.type}"
                                name="type"
                                value="email"
                                dusk="radio-email"
                            >
                        </div>
                        <span
                            v-if="errors.type"
                            dusk="type-validation"
                            class="block text-sm text-red-500"
                            role="alert"
                            v-text="errors.type"
                        />
                    </div>

                    <div class="w-full text-center">
                        <button
                            type="submit"
                            class="d-inline-block mx-auto mb-8 px-12 py-4 rounded-xl bg-blue-1000 font-medium text-white"
                        >
                            {{ translate('auth.two_factor_auth.setup.button_next') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import User from "../../../../User/User";

export default {
    data() {
        return {
            twoFactorSelectForm: {
                type: "authenticator"
            },
            failed: null,
            success: null,
            errors: {}
        };
    },

    methods: {
        submit2FASelection() {
            let formData = User.buildSelectTwoFactorAuthForm(this.twoFactorSelectForm);
            User.saveSelectedTwoFactorAuth(formData)
                .then(() => {
                    window.location.href = route('two-factor-auth.step-two');
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        this.$setErrorsFromResponse(error.response.data);
                    }
                });
        },
    }
};
</script>
