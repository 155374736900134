<template>
    <div class="bg-blue-200 py-8">
        <div class="page verification-expired">
            <div class="container mx-auto py-8">
                <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto bg-white rounded-xl p-8 shadow">
                    <p
                        class="text-lg text-center"
                        v-html="translate('auth.verification_expired', translateKeys)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            translateKeys: {
                'contact': '<a class="text-blue-1000 underline hover:no-underline" href="javascript:void(0)">' + this.translate('layout.contact') + '</a>',
            },
        };
    },
};
</script>
