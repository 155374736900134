import Base from '../Base';
import calculationToolDataIntro from './CalculationToolDataIntro';
import referenceDateCaoHours from './ReferenceDateCaoHours';
import determineCompanySize from './DetermineCompanySize';
import targetGroupData from './TargetGroupData';
import purchasesPage from "./PurchasesPage";
import store from '../../Store/store';
import {reformatDate} from '@/js/Helpers/Date';

class CalculationToolData extends Base {
    static getComponents() {
        return {
            'calculation-tool-data-intro': calculationToolDataIntro,
            'reference-date-cao-hours': referenceDateCaoHours,
            'determine-company-size': determineCompanySize,
            'target-group-data': targetGroupData,
            'purchases-page': purchasesPage,
        };
    }

    static buildCalculationToolDataForm(data) {
        return {
            reference_date: data.reference_date,
            cao_hours: parseInt(data.cao_hours),
        };
    }

    static buildCompanySizeDataForm(data)
    {
        return {
            employees_a: data.employees_a,
            employees_b: data.employees_b,
            employees_c: data.employees_c,
            employees_d: data.employees_d,
            employees_e: data.employees_e,
            fte_g: data.fte_g,
            fte_h: data.fte_h,
            fte_i: data.fte_i,
            fte_j: data.fte_j,
            fte_k: data.fte_k
        }
    }

    static buildAuditorCalculationToolDataForm(data)
    {
        return {
            reference_date: {
                value: data.reference_date,
                auditor: data.reference_date_auditor,
            },
            cao_hours: {
                value: parseInt(data.cao_hours),
                auditor: data.cao_hours_auditor,
            }
        }
    }

    static buildAuditorCompanySizeDataForm(data) {
        return {
            employees_a: {
                value: data.employees_a,
                auditor: data.employees_a_auditor
            },
            employees_b: {
                value: data.employees_b,
                auditor: data.employees_b_auditor
            },
            employees_c: {
                value: data.employees_c,
                auditor: data.employees_c_auditor
            },
            employees_d: {
                value: data.employees_d,
                auditor: data.employees_d_auditor
            },
            employees_e: data.employees_e,
            fte_g: {
                value: data.fte_g,
                auditor: data.fte_g_auditor
            },
            fte_h: {
                value: data.fte_h,
                auditor: data.fte_h_auditor
            },
            fte_i: {
                value: data.fte_i,
                auditor: data.fte_i_auditor
            },
            fte_j: {
                value: data.fte_j,
                auditor: data.fte_j_auditor
            },
            fte_k: data.fte_k
        };
    }

    static buildAuditorTargetGroupForm(data) {
        let dateInService = reformatDate(data.date_in_service, 'DD-MM-YYYY', 'YYYY-MM-DD');
        let dateOutOfService = data.date_out_of_service ? reformatDate(data.date_out_of_service, 'DD-MM-YYYY', 'YYYY-MM-DD') : null;
        let schoolLeavingDate = data.school_leaving_date ? reformatDate(data.school_leaving_date, 'DD-MM-YYYY', 'YYYY-MM-DD') : null;

        return {
            id: data.targetGroupEmployeeId ?? data.id,
            calculation_tool_id: data.calculation_tool_id,
            personnel_number: {
                value: data.personnel_number,
                auditor: data.personnel_number_auditor
            },
            branch: {
                value: data.branch,
                auditor: data.branch_auditor
            },
            starting_position_id: {
                value: data.starting_position_id,
                auditor: data.starting_position_id_auditor
            },
            contract_type_id: {
                value: data.contract_type_id,
                auditor: data.contract_type_id_auditor
            },
            hours_per_week: {
                value: data.hours_per_week,
                auditor: data.hours_per_week_auditor
            },
            date_in_service: {
                value: dateInService,
                auditor: data.date_in_service_auditor
            },
            date_out_of_service: {
                value: dateOutOfService,
                auditor: data.date_out_of_service_auditor
            },
            sw_group_size: {
                value: data.sw_group_size > 1 ? data.sw_group_size : 1,
                auditor: data.sw_group_size_auditor
            },
            school_leaving_date: {
                value: schoolLeavingDate,
                auditor: data.school_leaving_date_auditor
            }
        };
    }

    static buildPurchasesForm(data) {
        return {
            id: data.id,
            calculation_tool_id: data.calculation_tool_id,
            product_name:  data.product_name,
            supplier_certification: data.supplier_certification,
            organisation_id: data.organisation_id,
            value_of_purchase: data.value_of_purchase,
        };
    }
    static buildAuditorPurchasesForm(data) {
        return {
            id: data.id,
            calculation_tool_id: data.calculation_tool_id,
            product_name: {
                value: data.product_name,
                auditor: data.product_name_auditor ?? null
            },
            kvk: {
                value: data.kvk,
                auditor: data.kvk_auditor ?? null
            },
            supplier_certification: {
                value: data.supplier_certification,
                auditor: data.supplier_certification_auditor ?? null
            },
            value_of_purchase: {
                value: data.value_of_purchase,
                auditor: data.value_of_purchase_auditor ?? null,
            },
            organisation_id: {
                value: data.organisation_id,
                auditor: data.organisation_id_auditor ?? null,
            },
        };
    }

    static create(calculationToolId, payload) {
        return store.dispatch('calculation_tool_data/create', {
            payload: payload,
            calculationToolId: calculationToolId
        }).then((response) => {
            return response.data;
        });
    }

    static get(id) {
        return store.dispatch('calculation_tool_data/get', {id});
    }

    static original(id) {
        return store.dispatch('calculation_tool_data/original', {id});
    }

    static auditor(id) {
        return store.dispatch('calculation_tool_data/auditor', {id});
    }


    static update(calculationToolId, payload) {
        return store.dispatch('calculation_tool_data/update', {
            payload: payload,
            calculationToolId: calculationToolId
        }).then((response) => {
            return response.data;
        });
    }

    static getTargetGroupContractType(calculationToolId) {
        return store.dispatch('target_group/getContractType', calculationToolId);
    }

    static getTargetGroupStartingPosition(calculationToolId) {
        return store.dispatch('target_group/getStartingPosition', calculationToolId);
    }

    static getTargetGroupEmployeeList(calculationToolId) {
        return store.dispatch('target_group/getTargetGroupEmployeeList', calculationToolId);
    }

    static getOriginalTargetGroupEmployeeList(calculationToolId) {
        return store.dispatch('target_group/getOriginalTargetGroupEmployeeList', calculationToolId);
    }

    static getTargetGroupEmployeeSamplesList(calculationToolId) {
        return store.dispatch('target_group/getTargetGroupEmployeeSamplesList', calculationToolId);
    }

    static getPurchaseList(calculationToolId) {
        return store.dispatch('purchase/index', calculationToolId);
    }

    static getOriginalPurchaseList(calculationToolId) {
        return store.dispatch('purchase/original', calculationToolId);
    }

    static createTargetGroupEmployee(calculationToolId, payload) {
        return store.dispatch('target_group/create', {
            payload: payload,
            calculationToolId: calculationToolId
        });
    }

    static createPurchase(calculationToolId, payload) {
        return store.dispatch('purchase/createPurchase', {
            payload: payload,
            calculationToolId: calculationToolId
        });
    }

    static updateTargetGroupEmployee(payload) {
        return store.dispatch('target_group/update', {
            payload: payload,
            calculationToolId: payload.calculation_tool_id,
            targetGroupEmployeeId: payload.id
        });
    }

    static updateTargetGroupEmployeeModelInEdit(payload, isSample = false) {
        if(isSample === true) {
            return this.updateTargetGroupEmployeeSample(payload);
        } else {
            return this.updateTargetGroupEmployee(payload);
        }
    }

    static updateTargetGroupEmployeeSample(payload)
    {
        console.log(payload);
        return store.dispatch('target_group/update_sample', {
            payload: payload,
            calculationToolId: payload.calculation_tool_id,
            targetGroupEmployeeId: payload.id
        });
    }

    static updatePurchase(payload) {
        return store.dispatch('purchase/update', {
            payload: payload,
            calculationToolId: payload.calculation_tool_id,
            purchaseId: payload.id
        });
    }

    static uploadTargetGroup(calculationToolId, data, isAuditor) {
        return store.dispatch('target_group/upload', {
            payload: data,
            calculationToolId: calculationToolId,
            isAuditor: isAuditor
        });
    }

    static uploadPurchase(calculationToolId, data) {
        return store.dispatch('purchase/upload', {
            payload: data,
            calculationToolId: calculationToolId
        });
    }

    static deleteMultiple(calculationToolId, data) {
        return store.dispatch('target_group/deleteMultiple', {
            payload: data,
            calculationToolId: calculationToolId
        });
    }
    static deleteMultiplePurchases(calculationToolId, data) {
        return store.dispatch('purchase/deleteMultiple', {
            payload: data,
            calculationToolId: calculationToolId
        });
    }

    static auditorDeleteMultipleTargetGroups(calculationToolId, data, auditorMessage) {
        return store.dispatch('target_group/auditorDeleteMultiple', {
            payload: data,
            calculationToolId: calculationToolId,
            auditorMessage: auditorMessage
        });
    }

    static auditorDeleteMultiplePurchases(calculationToolId, data, auditorMessage) {
        return store.dispatch('purchase/auditorDeleteMultiple', {
            payload: data,
            calculationToolId: calculationToolId,
            auditorMessage: auditorMessage
        });
    }

    static auditorStoreTargetGroupSamples(calculationToolId, data) {
        return store.dispatch('target_group/auditorStoreSamples', {
            payload: data,
            calculationToolId: calculationToolId,
        });
    }

    static auditorDeleteMultipleTargetGroupsSamples(calculationToolId, data) {
        return store.dispatch('target_group/auditorDeleteMultipleSamples', {
            payload: data,
            calculationToolId: calculationToolId,
        });
    }
}

export default CalculationToolData;
