import _ from "lodash";
import { createApp } from 'vue/dist/vue.esm-bundler'

import VerificationExpired from "./Vue/VerificationExpired.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {VerificationExpired},
    }
    return createApp(_.merge(options, vueOptions));
}
