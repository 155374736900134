<template>
    <div class="score-bar-container relative bg-white">
        <div
            class="score-bar border-solid border border-pso-primary flex z-10 relative"
            :class="[{'px-16 py-2' : (!smallWidthProp)}, {'px-6 py-[7px]' : (smallWidthProp)}, borderRadiusProp]"
        >
            <div
                v-if="showTooltipProp"
                class="top-round-percentage border-pso-dark border-2 bg-pso-secondary absolute w-12 h-12 translate-x-[-50%] top-[-58px]"
                :class="borderRadiusProp"
                :style="{left: roundPercentage}"
            >
                <span class="text-white text-sm mt-[14px] font-medium block text-center">
                    {{
                        percentageProp.toLocaleString('nl', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: false
                        })
                    }}%
                </span>
            </div>
            <div
                class="score-bar-loaded"
                :style="{width: barPercentage}"
                :class="[{'rounded-l-0' : (percentageProp < norms.norm_trede_3)}, borderRadiusProp, {'h-[46px]' : !smallWidthProp}, {'h-[38px]': smallWidthProp}]"
            />
            <div
                class="flex-auto text-left  relative z-10"
                :class="!smallWidthProp ? 'lg:w-18 text-2xl' : 'sm:w-10 text-xl'"
            >
                <span
                    :class="[
                        {
                            'font-bold': (percentageProp < norms.basis_norm) && !smallWidthProp,
                            'text-white': percentageProp >= (0.28 * norms.basis_norm),
                        },
                        {
                            'leading-[21px] mt-[1px] block' : smallWidthProp,
                        }
                    ]"
                >
                    A
                </span>
            </div>
            <div
                class="flex-auto text-center relative z-10"
                :class="!smallWidthProp ? 'xl:w-32 lg:w-24 md:w-24 sm:w-14 text-2xl' : 'sm:w-20 text-lg'"
            >
                <span
                    :class="[
                        {
                            'font-bold': (percentageProp >= norms.basis_norm && percentageProp < norms.norm_trede_1) && !smallWidthProp,
                            'text-white': percentageProp >= norms.basis_norm,
                        },
                        {
                            'leading-[21px] mt-[1px] block' : smallWidthProp,
                        }
                    ]"
                >
                    B
                </span>
                <span
                    v-if="showBottomTextProp"
                    class="absolute top-12 text-gray-800 text-sm left-1/2 -translate-x-1/2 text-center"
                >
                    {{ translate('calculation_tool_data.result.basis') }} {{ norms.basis_norm }}%
                </span>
            </div>
            <div
                class="flex-auto text-center relative z-10"
                :class="!smallWidthProp ? 'xl:w-32 lg:w-24 md:w-24 sm:w-14 text-2xl' : 'sm:w-20 text-lg'"
            >
                <span
                    :class="[
                        {
                            'font-bold': (percentageProp >= norms.norm_trede_1 && percentageProp < norms.norm_trede_2) && !smallWidthProp,
                            'text-white': percentageProp >= norms.norm_trede_1,
                        },
                        {
                            'leading-[21px] mt-[1px] block' : smallWidthProp,
                        },
                    ]"
                >
                    1
                </span>
                <span
                    v-if="showBottomTextProp"
                    class="absolute top-12 text-gray-800 text-sm left-1/2 -translate-x-1/2 text-center"
                >
                    {{ translate('calculation_tool_data.result.step') }} 1: {{ norms.norm_trede_1 }}%
                </span>
            </div>
            <div
                class="flex-auto text-center  relative z-10"
                :class="!smallWidthProp ? 'xl:w-32 lg:w-24 md:w-24 sm:w-14 text-2xl' : 'sm:w-20 text-lg'"
            >
                <span
                    :class="[
                        {
                            'font-bold': (percentageProp >= norms.norm_trede_2 && percentageProp < norms.norm_trede_3) && !smallWidthProp,
                            'text-white': percentageProp >= norms.norm_trede_2,
                        },
                        {
                            'leading-[21px] mt-[1px] block' : smallWidthProp,
                        },
                    ]"
                >
                    2
                </span>
                <span
                    v-if="showBottomTextProp"
                    class="absolute top-12 text-gray-800 text-sm left-1/2 -translate-x-1/2 text-center"
                >
                    {{ translate('calculation_tool_data.result.step') }} 2: {{ norms.norm_trede_2 }}%
                </span>
            </div>
            <div
                class="flex-auto text-right relative z-10"
                :class="!smallWidthProp ? 'lg:w-18 text-2xl': 'sm:w-20 text-lg'"
            >
                <span
                    :class="[
                        {
                            'font-bold': (percentageProp >= norms.norm_trede_3) && !smallWidthProp,
                            'text-white': percentageProp >= 0.92 * norms.norm_trede_3,
                        },
                        {
                            'leading-[21px] mt-[1px] block' : smallWidthProp,
                        },
                    ]"
                >
                    3
                </span>
                <span
                    v-if="showBottomTextProp"
                    class="absolute top-12 text-gray-800 text-sm left-full min-w-full -translate-x-1/2 text-center pr-2"
                >
                    {{ translate('calculation_tool_data.result.step') }} 3: {{ norms.norm_trede_3 }}%
                </span>
            </div>
        </div>
    </div>
</template>
<script>

import {mapGetters} from "vuex";
import Score from "../../../../Score/Score";

export default {
    props: {
        percentageProp: {
            type: Number,
            default: 0
        },
        showTooltipProp: {
            type: Boolean,
            default: true
        },
        showBottomTextProp: {
            type: Boolean,
            default: true
        },
        smallWidthProp: {
            type: Boolean,
            default: false
        },
        borderRadiusProp: {
            type: String,
            default: "rounded-full"
        },
    },
    data() {
        return {
            barPercentage: 0,
            roundPercentage: 0,
        }
    },
    computed: {
        ...mapGetters('calculation_tool', ['calculation_tool']),
        ...mapGetters('calculation_tool_data', ['calculation_tool_data']),
        ...mapGetters('score', ['norms']),
    },
    watch: {
        percentageProp: function(val) {
            if(val === 0){
                this.roundPercentage = '0.2%';
                this.barPercentage = 0;
            } else {
                this.roundPercentage = this.calculatePercentage(val);
                this.barPercentage = this.calculatePercentage(val);
            }
        }
    },
    mounted() {
        if (this.norms === undefined) {
            Score.getNorms(this.calculation_tool.id);
        }

        if (this.percentageProp) {
            this.roundPercentage = this.calculatePercentage(this.percentageProp);
            this.barPercentage = this.calculatePercentage(this.percentageProp);
        }
    },
    methods: {
        calculatePercentage(val) {
            const {basis_norm, norm_trede_1, norm_trede_2, norm_trede_3} = this.norms;

            const basis_norm_percentage = 28;
            const norm_trede_1_percentage = 50.5;
            const norm_trede_2_percentage = 73;
            const norm_trede_3_percentage = 90.5;


            let percentage;
            let relativePercentage;

            if (val > norm_trede_3) {
                relativePercentage = this.calculateRelativePercentage(norm_trede_3, norm_trede_3 * 100 / 90.5, val);

                percentage = relativePercentage / 100 * (100 - norm_trede_3_percentage);

                const finalPercentage = norm_trede_3_percentage + percentage;
                return finalPercentage > 100 ? '100%' : finalPercentage + '%';
            }
            else if (val > norm_trede_2) {
                relativePercentage = this.calculateRelativePercentage(norm_trede_2, norm_trede_3, val);

                percentage = relativePercentage / 100 * (norm_trede_3_percentage - norm_trede_2_percentage);
                return norm_trede_2_percentage + percentage + '%';
            }
            else if (val > norm_trede_1) {
                relativePercentage = this.calculateRelativePercentage(norm_trede_1, norm_trede_2, val);

                percentage = relativePercentage / 100 * (norm_trede_2_percentage - norm_trede_1_percentage);
                return norm_trede_1_percentage + percentage + '%';
            }
            else if (val > basis_norm) {
                relativePercentage = this.calculateRelativePercentage(basis_norm, norm_trede_1, val);

                percentage = relativePercentage / 100 * (norm_trede_1_percentage - basis_norm_percentage);
                return basis_norm_percentage + percentage + '%';
            }
            else {
                relativePercentage = this.calculateRelativePercentage(0.1, basis_norm, val);

                percentage = relativePercentage / 100 * (basis_norm_percentage - 0.1);
                return percentage < 0 ? '0%' : percentage + '%';
            }
        },
        calculateRelativePercentage(startValue, endValue, val) {
            // Calculate percentage change of val between startValue and endValue
            let percentageChangeVal = ((val - startValue) / startValue) * 100;

            // Calculate percentage change between startValue and endValue
            let percentageChange = ((endValue - startValue) / startValue) * 100;

            // Calculate relative percentage of val between startValue and endValue
            let relativePercentage = (percentageChangeVal / percentageChange) * 100;

            return relativePercentage.toFixed(2);
        }
    }
}
</script>
<style lang="scss" scoped>
.top-round-percentage {
    transform: translate(-26px, 0);
}
.top-round-percentage:before {
    content: '';
    position: absolute;
    height: 26px;
    top: 100%;
    width: 2px;
    background: #56C2DD;
    left: 50%;
    z-index: -1;
    border-radius: 0 0 50% 0;
}
.score-bar:before {
    content: '';
    position: absolute;
    width: 99.8%;
    height: 100%;
    background: #fff;
    z-index: 1;
    left: 0.2%;
    top: 0;
    border-radius: 100%;
}
.score-bar-loaded {
    content: '';
    position: absolute;
    background: #015D9F;
    z-index: 2;
    left: 0%;
    top: -1px;
    /*border-radius: 40px 0 0 40px;*/
}
.rounded-l-0 {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
</style>
