<template>
    <MultiSelect
        :value="modelValue"
        :can-clear="false"
        :name="name"
        :class="{
            '!border-red-500': errors,
        }"
        :classes="{
            caret: 'icon_chevron_down bg-no-repeat w-[22px] h-4 z-10 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
            placeholder: 'flex items-center h-full absolute left-2.5 italic font-[system-ui] top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-pso-dark rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
        }"
        :options="options"
        :placeholder="placeholder"

        @update:modelValue="(value) => $emit('update:modelValue', value)"

    />
</template>
<script>
import MultiSelect from "@vueform/multiselect/src/Multiselect.vue";

export default {
    components: {
        MultiSelect,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: [Array, Object, Function],
            required: false,
            default: () => ([])
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
        errors: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
    },
    emits: {
        'update:modelValue': String
    }
}
</script>

<style scoped lang="scss">
    :deep(.multiselect-single-label) {
        padding-left: 0;
    }
</style>
