import _ from "lodash";
import { createApp } from 'vue/dist/vue.esm-bundler'

import LoginForm from "./Vue/LoginForm.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {LoginForm},
    }

    return createApp(_.merge(options, vueOptions));
}
