<template>
    <div>
        <DatePickerInput
            :id="mainId"
            :model-value="mainValue"
            :name="mainName"
            :errors="mainErrors"
            :placeholder="placeholder"
            :format="format"
            :disabled="disabled"
            :min-date="minDate"
            :max-date="maxDate"
            :month-only="monthOnly"
            :class="{
                '!rounded-none  !rounded-t-xl !border-x-[1px]': !seperated && auditorFieldEnabled,
                '!rounded-xl': seperated && auditorFieldEnabled,
                '!border-yellow-600 border-[1px] rounded-xl': auditorFieldEnabled,
                '!rounded-xl !border-gray-200': !auditorFieldEnabled
            }"

            @update:modelValue="(value) => $emit('update:mainValue', value)"
        />
        <div v-if="auditorFieldEnabled">
            <span
                v-if="seperated"
                class="text-lg text-pso-dark inline-block mt-4 mb-2"
            >
                {{ translate('auditor.input.explanation') }}*
            </span>

            <AuditorComment
                :id="auditorId"
                :model-value="auditorValue"
                :name="auditorName"
                :errors="auditorErrors"
                class="border-yellow-600"
                :class="{
                    'rounded-b-lg': !seperated,
                    'rounded-lg': seperated
                }"

                @update:modelValue="(value) => $emit('update:auditorValue', value)"
            />
            <slot />
        </div>
    </div>
</template>
<script>

import AuditorComment from "../../AuditorComment/Vue/AuditorComment.vue";
import DatePickerInput from "../../../DatePickerInput/Vue/DatePickerInput.vue";

export default {
    components: {
        DatePickerInput,
        AuditorComment,
    },
    props: {
        mainId: {
            type: String,
            required: true,
        },
        auditorId: {
            type: String,
            required: true,
        },
        mainName: {
            type: String,
            required: true,
        },
        auditorName: {
            type: String,
            required: true,
        },
        mainValue: {
            type: [Date, String],
            default: null
        },
        auditorValue: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null,
        },
        mainErrors: {
            type: String,
            default: null,
        },
        auditorErrors: {
            type: String,
            default: null,
        },
        format: {
            type: String,
            default: 'dd-MM-yyyy',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        minDate: {
            type: Date,
            default: null,
        },
        maxDate: {
            type: Date,
            default: null,
        },
        monthOnly: {
            type: Boolean,
            default: false,
        },
        seperated: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'update:mainValue': String,
        'update:auditorValue': String,
    },
    data() {
        return {
            initialValue: this.mainValue,
            auditorFieldEnabled: false,
        }
    },
    watch: {
        mainValue: {
            handler(data) {
                this.auditorFieldEnabled = (data !== this.initialValue);
            }
        }
    },
    mounted() {
        this.auditorFieldEnabled = (this.auditorValue);
    },
}
</script>
