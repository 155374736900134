import {createApp} from 'vue';
import _ from "lodash";
import mainOrganisationList from "./Vue/MainOrganisationList.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {mainOrganisationList: mainOrganisationList},
    };

    return createApp(_.merge(options, vueOptions));
}