<template>
    <Datepicker
        auto-apply
        hide-offset-dates
        locale="nl"
        :text-input="textInput"
        :enable-time-picker="false"
        :disabled="disabled"
        :format="format"
        :max-date="maxDate"
        :min-date="minDate"
        :model-value="modelValue"
        :month-picker="monthOnly"
        :placeholder="placeholder"
        :uid="id"

        @update:modelValue="(value) => $emit('update:modelValue', value)"
    />
</template>

<script>

import Datepicker from '@vuepic/vue-datepicker';
import {nl} from "date-fns/locale";

export default {
    setup() {
        return {
            nl,
        };
    },
    components: {
        Datepicker
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [Date, String],
            default: null,
        },
        textInput: {
            type: Boolean,
            default: false,
        },
        format: {
            type: String,
            default: 'dd-MM-yyyy',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: String,
            default: null,
        },
        minDate: {
            type: Date,
            default: null,
        },
        maxDate: {
            type: Date,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        monthOnly: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'update:modelValue': String
    }
}
</script>
