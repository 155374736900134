<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button @click.prevent="previousStep()" />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('calculation_tool_data.target_group_data.title_1') }}
                    <br>
                    {{ translate('calculation_tool_data.target_group_data.title_2') }}
                </h2>

                <h5 class="text-lg text-pso-dark font-medium">
                    {{ translate('calculation_tool_data.target_group_data.paragraph_1_title') }}
                </h5>
                <p
                    class="text-pso-dark text-lg mb-8"
                >
                    {{ translate('calculation_tool_data.target_group_data.paragraph_1') }}
                </p>

                <app-show-more>
                    <h5 class="text-lg text-pso-dark font-medium">
                        {{ translate('calculation_tool_data.target_group_data.paragraph_2_title') }}
                    </h5>
                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.target_group_data.paragraph_2') }}
                    </p>


                    <h5 class="text-lg text-pso-dark font-medium">
                        {{ translate('calculation_tool_data.target_group_data.paragraph_3_title') }}
                    </h5>
                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.target_group_data.paragraph_3') }}
                    </p>
                    <ul class="list-disc pl-6 text-pso-dark text-lg mb-8">
                        <li>{{ translate('calculation_tool_data.target_group_data.paragraph_3_bullet_1') }}</li>
                        <li>
                            {{ translate('calculation_tool_data.target_group_data.paragraph_3_bullet_2') }}
                            <a
                                class="text-pso-primary"
                                :href="links.handbook_inventory_and_burden_of_proof_pso_target_group"
                                target="_blank"
                            >
                                {{
                                    translate('calculation_tool_data.target_group_data.paragraph_3_bullet_2_link_text')
                                }}
                            </a>
                        </li>
                        <li>
                            {{ translate('calculation_tool_data.target_group_data.paragraph_3_bullet_3_1') }}
                            <a
                                class="text-pso-primary"
                                :href="links.manual_pso"
                                target="_blank"
                            >
                                {{
                                    translate('calculation_tool_data.target_group_data.paragraph_3_bullet_3_1_link_text')
                                }}
                            </a>.

                            {{ translate('calculation_tool_data.target_group_data.paragraph_3_bullet_3_2') }}
                            <a
                                class="text-pso-primary"
                                :href="links.manual_pso"
                                target="_blank"
                            >
                                {{
                                    translate('calculation_tool_data.target_group_data.paragraph_3_bullet_3_2_link_text')
                                }}
                            </a>.
                        </li>
                    </ul>

                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        <strong class="text-lg font-medium inline-block">
                            {{
                                translate('calculation_tool_data.target_group_data.paragraph_4_title')
                            }}
                        </strong>
                        {{ translate('calculation_tool_data.target_group_data.paragraph_4') }}
                    </p>
                </app-show-more>

                <div class="blue-separator h-0.5 bg-blue-400 my-[44px]" />

                <FilledInDetailsBlock :calculation-tool-data-prop="calculationToolData" />

                <div class="mt-12 mb-8">
                    <div class="w-full flex">
                        <div class="w-2/3 text-lg">
                            {{ translate('calculation_tool_data.target_group_data.switch_tabs') }}
                        </div>
                        <div class="w-1/3 text-right">
                            <div class="tabs-select bg-pso-dark rounded-lg inline-block">
                                <button
                                    :class="showTab === 'manual-input' ? 'bg-white text-pso-dark font-medium border rounded-lg': 'text-white'"
                                    class="py-2 min-w-[106px] text-center"
                                    @click.prevent="switchTabs('manual-input')"
                                >
                                    {{ translate('calculation_tool_data.target_group_data.manual_input_btn') }}
                                </button>
                                <button
                                    :class="showTab === 'upload-excel' ? 'bg-white text-pso-dark font-medium border rounded-lg' : 'text-white'"
                                    class="py-2 min-w-[106px] text-center"
                                    dusk="excel-tab-btn"
                                    @click.prevent="switchTabs('upload-excel')"
                                >
                                    {{ translate('calculation_tool_data.target_group_data.excel_btn') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showTab === 'manual-input'"
                    class="manual-input mb-8"
                >
                    <h4 class="heading mb-6 text-pso-dark text-xl font-medium">
                        {{ translate('calculation_tool_data.target_group_data.add_manually_title') }}
                    </h4>
                    <div class="w-full border border-gray-200 rounded-xl p-6">
                        <div class="mb-6">
                            <label
                                class="block mb-2 text-lg text-pso-dark"
                                dusk="label-personnel-number"
                                for="personnel_number_label"
                            >
                                {{ translate('calculation_tool_data.target_group_data.personnel_number_label') }}
                                <Popper
                                    :arrow="true"
                                    placement="top"
                                >
                                    <div class="ml-1">
                                        <img
                                            :src="iconInfo"
                                            alt="info"
                                        >
                                    </div>
                                    <template #content>
                                        {{
                                            translate('calculation_tool_data.target_group_data.personnel_number_info')
                                        }}
                                    </template>
                                </Popper>
                            </label>

                            <TextInput
                                id="personnel_number"
                                v-model="form.personnel_number"
                                name="personnel_number"
                                :errors="errors.personnel_number"
                                :placeholder="translate('calculation_tool_data.target_group_data.personnel_number_placeholder')"
                                class="rounded-xl border-gray-200 w-1/2"
                            />

                            <span
                                v-if="errors.personnel_number"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.personnel_number"
                            />
                        </div>
                        <div class="mb-6">
                            <label
                                class="block mb-2 text-lg text-pso-dark"
                                for="branch_label"
                            >
                                {{ translate('calculation_tool_data.target_group_data.branch_label') }}
                            </label>

                            <TextInput
                                id="branch"
                                v-model="form.branch"
                                name="branch"
                                :errors="errors.branch"
                                :placeholder="translate('calculation_tool_data.target_group_data.branch_placeholder')"
                                class="rounded-xl border-gray-200 w-1/2"
                            />

                            <span
                                v-if="errors.branch"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.branch"
                            />
                        </div>
                        <div class="mb-6">
                            <div
                                :class="{'pb-6':errors.starting_position_id}"
                                class="pb-2"
                                dusk="label-starting-position"
                            >
                                <span class="text-lg text-pso-dark inline-block">
                                    {{
                                        translate('calculation_tool_data.target_group_data.starting_position_label')
                                    }}
                                </span>
                                <Popper
                                    :arrow="true"
                                    placement="top"
                                >
                                    <div class="ml-1">
                                        <img
                                            :src="iconInfo"
                                            alt="info"
                                        >
                                    </div>
                                    <template #content>
                                        {{
                                            translate('calculation_tool_data.target_group_data.starting_position_info')
                                        }}
                                        <a
                                            class="text-blue-1000"
                                            :href="links.manual_pso"
                                            target="_blank"
                                        >
                                            {{
                                                translate('calculation_tool_data.target_group_data.starting_position_info_link_text')
                                            }}
                                        </a>
                                    </template>
                                </Popper>
                            </div>
                            <div
                                class="w-1/2"
                            >
                                <MultiSelectInput
                                    id="starting_position_id"
                                    v-model="form.starting_position_id"
                                    :options="startingPositionOptions"
                                    name="starting_position_id"
                                    :errors="errors.starting_position_id"
                                    :placeholder="translate('calculation_tool_data.target_group_data.starting_position_placeholder')"
                                    class="!rounded-xl !border-gray-200"
                                    dusk="btn-multiselect-starting-position"
                                />

                                <span
                                    v-if="errors.starting_position_id"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.starting_position_id"
                                />
                            </div>
                        </div>
                        <div
                            v-show="showSwGroupSizeInput"
                            class="mb-6"
                        >
                            <label
                                class="block mb-2 text-lg text-pso-dark"
                                for="sw_group_size"
                            >
                                {{ translate('calculation_tool_data.target_group_data.sw_group_size_label') }}
                            </label>

                            <TextInput
                                id="sw_group_size"
                                v-model="form.sw_group_size"
                                disabled
                                name="sw_group_size"
                                :errors="errors.sw_group_size"
                                :placeholder="translate('calculation_tool_data.target_group_data.sw_group_size_placeholder')"
                                class="w-1/2 rounded-xl border-gray-200"
                            />

                            <span
                                v-if="errors.sw_group_size"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.sw_group_size"
                            />
                        </div>
                        <div class="mb-6">
                            <div
                                :class="{'pb-6':errors.contract_type_id}"
                                class="pb-2"
                                dusk="label-contract"
                            >
                                <span class="text-lg text-pso-dark inline-block">
                                    {{ translate('calculation_tool_data.target_group_data.contract_label') }}
                                </span>
                                <Popper
                                    :arrow="true"
                                    placement="top"
                                >
                                    <div class="ml-1">
                                        <img
                                            :src="iconInfo"
                                            alt="info"
                                        >
                                    </div>
                                    <template #content>
                                        {{ translate('calculation_tool_data.target_group_data.contract_info') }}
                                        <a
                                            class="text-blue-1000"
                                            :href="links.manual_pso"
                                            target="_blank"
                                        >
                                            {{
                                                translate('calculation_tool_data.target_group_data.contract_info_link_text')
                                            }}
                                        </a>
                                    </template>
                                </Popper>
                            </div>
                            <div
                                class="w-1/2"
                            >
                                <MultiSelectInput
                                    id="contract_type_id"
                                    v-model="form.contract_type_id"
                                    :options="contractTypeOptions"
                                    name="contract_type_id_auditor"
                                    :errors="errors.contract_type_id"
                                    :placeholder="translate('calculation_tool_data.target_group_data.contract_placeholder')"
                                    class="!rounded-xl !border-gray-200"
                                    dusk="btn-multiselect-contract-type"
                                />

                                <span
                                    v-if="errors.contract_type_id"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.contract_type_id"
                                />
                            </div>
                        </div>
                        <div class="mb-6">
                            <label
                                class="block mb-2 text-lg text-pso-dark"
                                dusk="label-hours-per-week"
                                for="hours_per_week_label"
                            >
                                {{ translate('calculation_tool_data.target_group_data.hours_per_week_label') }}
                                <Popper
                                    :arrow="true"
                                    placement="top"
                                >
                                    <div class="ml-1">
                                        <img
                                            :src="iconInfo"
                                            alt="info"
                                        >
                                    </div>
                                    <template #content>
                                        <ul class="list-disc">
                                            <li>
                                                {{
                                                    translate('calculation_tool_data.target_group_data.hours_per_week_info_1')
                                                }}
                                            </li>
                                            <li>
                                                {{
                                                    translate('calculation_tool_data.target_group_data.hours_per_week_info_2')
                                                }}
                                            </li>
                                            <li>
                                                {{
                                                    translate('calculation_tool_data.target_group_data.hours_per_week_info_3')
                                                }}
                                            </li>
                                        </ul>
                                    </template>
                                </Popper>
                            </label>

                            <NumberInput
                                id="hours_per_week"
                                v-model="form.hours_per_week"
                                name="hours_per_week"
                                :errors="errors.hours_per_week"
                                :placeholder="translate('calculation_tool_data.target_group_data.hours_per_week_placeholder')"
                                class="w-1/2 rounded-xl border-gray-200"
                            />

                            <span
                                v-if="errors.hours_per_week"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.hours_per_week"
                            />
                        </div>
                        <div class="mb-6">
                            <div
                                :class="{'pb-6':errors.date_in_service}"
                                class="pb-2"
                                dusk="label-date-in-service"
                            >
                                <span class="text-lg text-pso-dark">
                                    {{ translate('calculation_tool_data.target_group_data.date_in_service_label') }}
                                </span>
                                <Popper
                                    :arrow="true"
                                    placement="top"
                                >
                                    <div class="ml-1">
                                        <img
                                            :src="iconInfo"
                                            alt="info"
                                        >
                                    </div>
                                    <template #content>
                                        <div>
                                            {{
                                                translate('calculation_tool_data.target_group_data.date_in_service_info')
                                            }}
                                        </div>
                                    </template>
                                </Popper>
                            </div>
                            <div
                                class="w-1/2"
                            >
                                <DatePickerInput
                                    id="date_in_service"
                                    v-model="form.date_in_service"
                                    name="date_in_service"
                                    :errors="errors.date_in_service"
                                    :max-date="new Date()"
                                    class="!rounded-xl !border-gray-200"
                                    :placeholder="translate('calculation_tool_data.target_group_data.date_in_service_placeholder')"
                                    dusk="modal-edit-tge-btn-datepicker-date-in-service"
                                    @update:model-value="resetDateOutService()"
                                />

                                <span
                                    v-if="errors.date_in_service"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.date_in_service"
                                />
                            </div>
                        </div>
                        <div class="mb-6">
                            <div
                                :class="{'pb-6':errors.date_out_of_service}"
                                class="pb-2"
                                dusk="label-date-out-of-service"
                            >
                                <span class="text-lg text-pso-dark">
                                    {{
                                        translate('calculation_tool_data.target_group_data.date_out_of_service_label')
                                    }}
                                </span>
                                <Popper
                                    :arrow="true"
                                    placement="top"
                                >
                                    <div class="ml-1">
                                        <img
                                            :src="iconInfo"
                                            alt="info"
                                        >
                                    </div>
                                    <template #content>
                                        <div>
                                            {{
                                                translate('calculation_tool_data.target_group_data.date_out_of_service_info')
                                            }}
                                        </div>
                                    </template>
                                </Popper>
                            </div>
                            <div
                                class="w-1/2"
                            >
                                <DatePickerInput
                                    id="date_out_of_service"
                                    v-model="form.date_out_of_service"
                                    name="date_out_of_service"
                                    :errors="errors.date_out_of_service"
                                    :min-date="formatDate(new Date(form.date_in_service))"
                                    :placeholder="translate('calculation_tool_data.target_group_data.date_out_of_service_placeholder')"
                                    dusk="modal-edit-tge-btn-datepicker-date-out-of-service"
                                />

                                <span
                                    v-if="errors.date_out_of_service"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.date_out_of_service"
                                />
                            </div>
                        </div>
                        <div
                            v-show="showSchoolLeavingInput"
                            class="mb-6"
                        >
                            <div
                                :class="{'pb-6':errors.date_out_of_service}"
                                class="pb-2"
                                dusk="label-date-out-of-service"
                            >
                                <span class="text-lg text-pso-dark">
                                    {{
                                        translate('calculation_tool_data.target_group_data.school_leaving_date_label')
                                    }}
                                </span>
                            </div>
                            <div
                                class="w-1/2"
                            >
                                <DatePickerInput
                                    id="school_leaving_date"
                                    v-model="form.school_leaving_date"
                                    name="school_leaving_date"
                                    :errors="errors.school_leaving_date"
                                    :max-date="new Date()"
                                    :placeholder="translate('calculation_tool_data.target_group_data.school_leaving_date_placeholder')"
                                    dusk="btn-datepicker-school-leaving-date"
                                />

                                <span
                                    v-if="errors.school_leaving_date"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.school_leaving_date"
                                />
                            </div>
                        </div>
                        <div
                            v-if="isAuditor"
                            class="mb-6"
                        >
                            <div
                                :class="{'pb-6':errors.auditor}"
                                class="pb-2"
                                dusk="label-auditor"
                            >
                                <span class="text-lg text-pso-dark">
                                    {{
                                        translate('auditor.input.explanation')
                                    }}
                                </span>
                            </div>
                            <div
                                class="w-1/2"
                            >
                                <AuditorComment
                                    id="auditor"
                                    v-model="form.auditor"
                                    name="auditor"
                                    :errors="errors.auditor"

                                    class="border-yellow-600 rounded-xl"
                                />
                            </div>
                        </div>
                        <div class="mb-6 mt-8">
                            <button
                                :class="allRequiredAreFilled ? '' : 'opacity-50 cursor-not-allowed'"
                                class="flex items-center border-1 rounded-xl p-4 pl-5 text-white bg-pso-dark"
                                dusk="btn-manually-save-target-group"
                                @click="saveEmployee"
                            >
                                <img
                                    :src="iconPlusWhite"
                                    alt="open"
                                    class="w-4 mr-2"
                                >
                                <span
                                    class="text-white text-lg font-medium"
                                >
                                    {{
                                        translate('calculation_tool_data.target_group_data.collaborator_save_button')
                                    }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showTab === 'upload-excel'"
                    class="upload-excel mb-8"
                >
                    <h2 class="font-medium text-xl mb-6">
                        {{ translate('calculation_tool_data.target_group_data.excel_upload_title') }}
                    </h2>
                    <p class="mb-2">
                        {{
                            translate('calculation_tool_data.target_group_data.excel_upload_description')
                        }}
                    </p>
                    <a
                        :href="demoExcelFile"
                        class="inline-flex mx-auto py-4 px-6 rounded-xl text-white bg-pso-dark"
                    >
                        <img
                            :src="iconDownload"
                            alt=""
                            class="mr-2"
                        >
                        {{ translate('calculation_tool_data.target_group_data.excel_download') }}
                    </a>
                    <div
                        v-if="uploadStatus === ''"
                        class="mt-6 bg-gray-200 text-center text-gray-600 rounded-xl drop-zone"
                    >
                        <div>
                            <div v-bind="getRootProps()">
                                <input v-bind="getInputProps()">
                                <p
                                    v-if="isDragActive"
                                    class="text-gray-600 pt-6 pl-6 pr-6"
                                >
                                    {{ translate('calculation_tool_data.target_group_data.excel_drag_and_drop_here') }}
                                </p>
                                <p
                                    v-else
                                    class="text-gray-600 pt-6 pl-6 pr-6"
                                >
                                    {{ translate('calculation_tool_data.target_group_data.excel_drag_and_drop') }}
                                </p>
                                <img
                                    :src="iconUploadRound"
                                    alt=""
                                    class="pt-4 pb-6 pl-6 pr-6 m-auto cursor-pointer"
                                >
                            </div>
                        </div>
                    </div>

                    <success-alert
                        :id-prop="'upload-excel'"
                        class="mt-6"
                    />

                    <error-alert
                        :id-prop="'upload-excel'"
                        class="mt-6"
                    />
                </div>

                <section class="target-employees-table-section pb-[22px] mb-10">
                    <div class="search-box-wapper flex justify-end items-center mb-2 relative">
                        <input
                            v-model="tableSearchValue"
                            :style="{ backgroundImage: 'url(' + iconMagnifier + ')' }"
                            class="border-gray-200 rounded-xl py-4 px-10 bg-no-repeat bg-[center_left_1rem] text-lg focus:outline-0 focus:border-gray-200 focus:shadow-none focus:ring-0"
                            type="text"
                        >
                        <button
                            v-if="tableSearchValue"
                            class="absolute right-4"
                            @click="clearSearchBox()"
                        >
                            <img
                                :src="iconClose"
                                alt="clear"
                            >
                        </button>
                    </div>
                    <EasyDataTable
                        :header-background-color="tableThemeColor"
                        :headers="tableHeaders"
                        :items="targetGroupEmployeeList"
                        v-model:items-selected="tableItemsSelected"
                        :rows-items="[10, 25, 50]"
                        :rows-per-page="10"
                        :search-field="tableSearchFields"
                        :search-value="tableSearchValue"
                        :theme-color="tableThemeColor"
                        buttons-pagination

                        header-font-color="white"
                        rows-per-page-message=""
                    >
                        <template #loading>
                            <img
                                :src="loadingImage"
                                alt=""
                            >
                        </template>
                    </EasyDataTable>
                    <div class="table-actions absolute -mt-7 flex">
                        <button
                            class="inline-flex items-center mb-2 border-pso-dark bg-white border rounded-xl p-4 pl-5  mr-2"
                            dusk="btn-edit-target-group-data"
                            @click.prevent="showEditModal()"
                        >
                            <img
                                :src="iconPencil"
                                alt="edit"
                                class="mr-2"
                            >
                            <span
                                class="text-pso-dark text-md font-medium"
                            >
                                {{ translate('calculation_tool_data.target_group_data.edit.edit_button') }}
                            </span>
                        </button>

                        <button
                            class="inline-flex items-center mb-2 border-pso-dark border rounded-xl p-4 pl-5 bg-white"
                            dusk="btn-delete-target-group-data"
                            @click.prevent="confirmDeleteAction()"
                        >
                            <img
                                :src="iconDelete"
                                alt="delete"
                                class="mr-2"
                            >
                            <span
                                class="text-pso-dark text-md font-medium"
                            >
                                {{ translate('calculation_tool_data.target_group_data.delete.delete_button') }}
                            </span>
                        </button>
                    </div>
                </section>
                <div
                    v-if="!isAuditor"
                    class="mb-10"
                >
                    <div class="pb-2 pt-2 flex items-center">
                        <input
                            id="check_box_1"
                            v-model="checkBoxes.first"
                            class="inline-block align-middle mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                            name="check_box_1"
                            type="checkbox"
                            value="1"
                            @change="checkIfNextStepPossible()"
                        >
                        <label
                            for="check_box_1"
                        >
                            {{
                                translate('calculation_tool_data.target_group_data.bottom_checkbox_1')
                            }}
                        </label>
                    </div>
                    <div class="pb-2 pt-2 flex items-center">
                        <input
                            id="check_box_2"
                            v-model="checkBoxes.second"
                            class="align-text-bottom mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                            name="check_box_2"
                            type="checkbox"
                            value="1"
                            @change="checkIfNextStepPossible()"
                        >
                        <label
                            for="check_box_2"
                        >
                            {{
                                translate('calculation_tool_data.target_group_data.bottom_checkbox_2')
                            }}
                        </label>
                    </div>
                    <div class="pb-2 pt-2 flex items-center">
                        <input
                            id="check_box_3"
                            v-model="checkBoxes.third"
                            class="align-text-bottom mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                            name="check_box_3"
                            type="checkbox"
                            value="1"
                            @change="checkIfNextStepPossible()"
                        >
                        <label
                            for="check_box_3"
                        >
                            {{
                                translate('calculation_tool_data.target_group_data.bottom_checkbox_3')
                            }}
                        </label>
                    </div>
                </div>
                <div
                    v-if="isAuditor"
                    class="mb-10"
                >
                    <AuditorConfirmChanges
                        v-model:confirmCorrectlyFilledInValue="auditorExtraForm.confirm_correctly_filled_in"
                        v-model:firstExplanationValue="auditorExtraForm.first_explanation"
                        v-model:choiceValue.number="auditorExtraForm.choice"
                        v-model:secondExplanationValue="auditorExtraForm.second_explanation"
                        :errors="errors"
                    />
                </div>
                <div class="actions-bottom">
                    <div class="action-button text-center">
                        <button
                            :class="checkBoxes.all || isAuditor ? '' : 'opacity-50 cursor-not-allowed'"
                            :disabled="(!checkBoxes.all && !isAuditor)"
                            class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                            dusk="next-page-button"
                            @click="goNextPage()"
                        >
                            {{ translate('calculation_tool_data.target_group_data.button_go_next_step') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <edit-employee-modal />
    </div>
</template>

<script>

// @TODO: Found bug, dates give invalid error, when date is correct.

import {mapGetters} from 'vuex';
import CalculationTool from '../../../CalculationTool/CalculationTool';
import CalculationToolData from '../../CalculationToolData';
import CalculationToolHeader from '../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue';
import BackLinkButton from '../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue';
import ShowMoreElement from '../../../Templates/Parts/ShowMoreElement/Vue/ShowMoreElement.vue';
import EditEmployeeModal from '../../Parts/EditEmployeeModal/Vue/EditEmployeeModal.vue';
import Popper from 'vue3-popper';
import {nl} from 'date-fns/locale';
import dayjs from 'dayjs';
import EasyDataTable from 'vue3-easy-data-table';
import {useDropzone} from "vue3-dropzone";
import _ from "lodash";
import ErrorAlert from '../../../Tools/Parts/Alert/Vue/ErrorAlert.vue';
import SuccessAlert from "../../../Tools/Parts/Alert/Vue/SuccessAlert.vue";
import Links from "../../../_config/Links.js";
import NumberInput from "../../../FormInput/NumberInput/Vue/NumberInput.vue";
import AuditorConfirmChanges from "../../../Tools/Parts/AuditorConfirmChanges/Vue/AuditorConfirmChanges.vue";
import User from "../../../User/User";
import TextInput from "../../../FormInput/TextInput/Vue/TextInput.vue";
import MultiSelectInput from "../../../FormInput/MultiSelectInput/Vue/MultiSelectInput.vue";
import DatePickerInput from "../../../FormInput/DatePickerInput/Vue/DatePickerInput.vue";
import {getUserRoles} from "../../../_config/Roles";
import * as Views from "../../../_config/Views";
import Auditor from "../../../Auditor/Auditor";
import AuditorComment from "../../../FormInput/Auditor/AuditorComment/Vue/AuditorComment.vue";
import {getSteps} from "../../../_config/Steps"
import FilledInDetailsBlock from "../../Parts/FilledInDetailsBlock.vue";
import TargetGroupUpload from '../../../../Services/TargetGroupUpload';

const DEFAULT_FORM_VALUES = {
    personnel_number: null,
    branch: '',
    starting_position_id: null,
    contract_type_id: null,
    hours_per_week: null,
    date_in_service: '',
    date_out_of_service: '',
    sw_group_size: 1,
    school_leaving_date: null,
};

export default {
    components: {
        FilledInDetailsBlock,
        AuditorComment,
        DatePickerInput,
        MultiSelectInput,
        TextInput,
        AuditorConfirmChanges,
        NumberInput,
        CalculationToolHeader,
        'app-back-button': BackLinkButton,
        'app-show-more': ShowMoreElement,
        Popper,
        EasyDataTable,
        SuccessAlert,
        ErrorAlert,
        EditEmployeeModal,
    },

    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        },
    },
    setup(props) {
        const saveFiles = (files) => {
            TargetGroupUpload.uploadTargetGroupFile(files, props.calculationToolIdProp);
        };

        function onDrop(acceptFiles, rejectReasons) {
            if (acceptFiles.length > 0) {
                saveFiles(acceptFiles);
            }
            if (rejectReasons.length > 0) {
                window.mitt.emit('updateUploadStatus', {status: 'error_headings_or_extension'});
            }
        }

        const {getRootProps, getInputProps, ...rest} = useDropzone({
            onDrop,
            accept: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        return {
            nl,
            getRootProps,
            getInputProps,
            ...rest,
        };
    },
    data() {
        return {
            links: Links,
            form: Object.assign({}, DEFAULT_FORM_VALUES),
            calculationToolData: {
                startDate: 'N/A',
                referenceDate: 'N/A',
                cao: 'N/A',
                employee: 'N/A',
                fte: 'N/A',
                showSwInputForStartingPositionId: 0,
                bblBolLevel1Id: 0,
                bblBolLevel2Id: 0,
                bblBolLevel3or4Id: 0,
                regularEmploymentContractId: 0
            },
            showSwGroupSizeInput: false,
            showSchoolLeavingInput: false,
            errors: {},
            checkBoxes: {
                first: false,
                second: false,
                third: false,
                all: false,
            },
            auditorExtraForm: {
                confirm_correctly_filled_in: null,
                first_explanation: null,
                choice: null,
                second_explanation: null,
                view: Views.getViews().target_group_date
            },
            contractTypeOptions: {},
            contractTypeOptionsFullList: {},
            allRequiredAreFilled: false,
            showTab: 'manual-input',
            uploadStatus: '',
            tableSearchValue: '',
            tableThemeColor: '#3dbbf2',
            tableHeaders: [],
            tableItemsSelected: [],
            tableSearchFields: ['group_size', 'personnel_number', 'starting_position', 'contract_type', 'hours_per_week', 'date_in_service', 'date_out_of_service'],
            demoExcelFile: new URL('/resources/files/doelgroep_excel.xlsx', import.meta.url).href,
            iconUploadRound: new URL('/resources/images/icon_upload_circled.svg', import.meta.url).href,
            iconClose: new URL('/resources/images/icon_times.svg', import.meta.url).href,
            iconDownload: new URL('/resources/images/icon_download.svg', import.meta.url).href,
            iconCalendar: new URL('/resources/images/icon_calendar.svg', import.meta.url).href,
            iconInfo: new URL('/resources/images/icon_info.svg', import.meta.url).href,
            iconPlusWhite: new URL('/resources/images/icon_plus_white.svg', import.meta.url).href,
            iconMagnifier: new URL('/resources/images/icon_magnifier.svg', import.meta.url).href,
            loadingImage: new URL('/resources/images/loading.svg', import.meta.url).href,
            iconDelete: new URL('/resources/images/icon_delete.svg', import.meta.url).href,
            iconPencil: new URL('/resources/images/icon_pencil.svg', import.meta.url).href,
            isAuditor: false,
            initialized: false,
        };
    },

    computed: {
        ...mapGetters('target_group', ['contract_type']),
        ...mapGetters('target_group', {startingPositionOptions: 'starting_position'}),
        ...mapGetters('target_group', {targetGroupEmployeeList: 'target_group_employee_list'}),
        ...mapGetters('calculation_tool_data', ['calculation_tool_data']),
        ...mapGetters('auditor', ['extra_inputs']),
        ...mapGetters('user', ['roles']),
    },
    watch: {
        form: {
            deep: true,
            handler() {
                this.validateStartingPosition();
                this.validateRequiredFields();
            }
        },
        roles: {
            handler(data) {
                if (data.roles.includes(getUserRoles().auditor)) {
                    this.isAuditor = true;
                }
            },
        },
    },
    created() {
        window.mitt.on('updateUploadStatus', (data = {}) => {
            this.uploadStatus = data.status;
            if (data.status === 'success') {
                this.errors = [];
                window.mitt.emit('showSuccessAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.target_group_data.excel_upload_success'),
                        ],
                        'id': 'upload-excel',
                    });
            } else if (data.status === 'error_headings_or_extension') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.invalid_extension_1'),
                            this.translate('calculation_tool_data.excel_upload_validations.invalid_extension_2'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                    });
            } else if (data.status === 'server_error') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.server_error'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                    });
            } else if (data.status === 'error_duplicates') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.error_duplicates_target_group'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                        'tableData': data.error,
                        'tableShow': 'duplicates'
                    });
            } else if (data.status === 'error_duplicates_and_rows') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.error_rows'),
                        ],
                        'secondMessages': [
                            this.translate('calculation_tool_data.excel_upload_validations.error_duplicates_target_group'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                        'tableData': data.error,
                        'tableShow': 'duplicates_and_rows'
                    });
            } else if (data.status === 'error_rows') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.error_rows'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                        'tableData': data.error,
                        'tableShow': 'rows'
                    });
            }
        });

        User.getRoles();
    },
    mounted() {
        CalculationToolData.get(this.calculationToolIdProp).then(() => {
            this.calculationToolData.referenceDate = dayjs(this.calculation_tool_data.calculated.reference_date).format('DD-MM-YYYY');
            this.calculationToolData.startDate = dayjs(this.calculation_tool_data.calculated.start_date).format('DD-MM-YYYY');
            this.calculationToolData.cao = this.calculation_tool_data.calculated.cao_hours;
            this.calculationToolData.fte = this.calculation_tool_data.calculated.total_fte;
            this.calculationToolData.employee = this.calculation_tool_data.calculated.total_employee;
            this.calculationToolData.showSwInputForStartingPositionId = this.calculation_tool_data.calculated.show_sw_input_for_starting_position_id;
            this.calculationToolData.showSchoolLeavingInputForStartingPositionIds = this.calculation_tool_data.calculated.show_school_leaving_input_for_starting_position_ids;
            this.calculationToolData.showSchoolLeavingInputMandatory = this.calculation_tool_data.calculated.show_school_leaving_input_mandatory;
            this.calculationToolData.schoolLeavingInputMandatoryStartingPositionIds = this.calculation_tool_data.calculated.school_leaving_input_mandatory_starting_position_ids,
            this.calculationToolData.bblBolLevel1Id = this.calculation_tool_data.calculated.show_bbl_level_1;
            this.calculationToolData.bblBolLevel2Id = this.calculation_tool_data.calculated.show_bbl_level_2;
            this.calculationToolData.bblBolLevel3or4Id = this.calculation_tool_data.calculated.show_bbl_level_3;
            this.calculationToolData.regularEmploymentContractId = this.calculation_tool_data.calculated.show_regular_employment_contract;
            this.calculationToolData.stage = this.calculation_tool_data.calculated.stage;
        });

        CalculationToolData.getTargetGroupContractType(this.calculationToolIdProp).then(() => {
            this.contractTypeOptions = Object.assign({}, this.contract_type);
            this.contractTypeOptionsFullList = Object.assign({}, this.contract_type);
        });
        CalculationToolData.getTargetGroupStartingPosition(this.calculationToolIdProp);
        CalculationToolData.getTargetGroupEmployeeList(this.calculationToolIdProp);
        CalculationTool.get(this.calculationToolIdProp);
        Auditor.getExtraInputs(this.calculationToolIdProp, Views.getViews().target_group_date).then(() => {
            this.extra_inputs.forEach((item) => {
                switch (item.input_name) {
                case 'confirm_correctly_filled_in':
                    this.auditorExtraForm.confirm_correctly_filled_in = item.input_value;
                    break;
                case 'first_explanation':
                    this.auditorExtraForm.first_explanation = item.input_value;
                    break;
                case 'choice':
                    this.auditorExtraForm.choice = item.input_value;
                    break;
                case 'second_explanation':
                    this.auditorExtraForm.second_explanation = item.input_value;
                    break;
                }
            });

        });
        User.getRoles();


        this.tableHeaders = [
            {
                text: this.translate('calculation_tool_data.target_group_data.employee_table.group_size'),
                value: 'sw_group_size',
                sortable: true,
                fixed: true,
                width: 40
            },
            {
                text: this.translate('calculation_tool_data.target_group_data.employee_table.personnel_number'),
                value: 'personnel_number',
                sortable: true,
                fixed: true,
                width: 60
            },
            {
                text: this.translate('calculation_tool_data.target_group_data.employee_table.starting_position'),
                value: 'starting_position.short_name',
                sortable: true,
                fixed: true,
                width: 60
            },
            {
                text: this.translate('calculation_tool_data.target_group_data.employee_table.contract_type'),
                value: 'contract_type.short_name',
                sortable: true,
                fixed: true,
                width: 60
            },
            {
                text: this.translate('calculation_tool_data.target_group_data.employee_table.hours_per_week'),
                value: 'hours_per_week',
                sortable: true,
                fixed: true,
                width: 60
            },
            {
                text: this.translate('calculation_tool_data.target_group_data.employee_table.date_in_service'),
                value: 'date_in_service',
                sortable: true,
                fixed: true,
                width: 60
            },
            {
                text: this.translate('calculation_tool_data.target_group_data.employee_table.date_out_of_service'),
                value: 'date_out_of_service',
                sortable: true,
                width: 60
            },
        ];

        this.initialized = true;

        window.mitt.on('actionEditEmployee', () => {
            this.showEditModal();
        });
    },
    methods: {
        previousStep() {
            window.location = route('calculation-tool-data.determine-company-size', { calculationTool: this.calculationToolIdProp });
        },
        clearForm() {
            this.errors = [];
            this.form = Object.assign({}, DEFAULT_FORM_VALUES);
        },
        saveEmployee() {
            if (this.allRequiredAreFilled === true) {
                console.log(this.form);
                CalculationToolData.createTargetGroupEmployee(this.calculationToolIdProp, this.form)
                    .then(() => {
                        this.clearForm();
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            let personnelNumberErrors = ((((error || {}).response || {}).data || {}).errors || {}).personnel_number;

                            if (personnelNumberErrors && personnelNumberErrors.includes('target_group_check_if_exists_in_db')) {
                                delete error.response.data.errors.personnel_number;

                                let dialogData = {
                                    header: this.translate('calculation_tool_data.target_group_data.modal_unique_personnel_number_title'),
                                    body: this.translate('calculation_tool_data.target_group_data.modal_unique_personnel_number_body'),
                                    buttons: [{
                                        text: this.translate('calculation_tool_data.target_group_data.modal_unique_personnel_number_button'),
                                        redirect: '#personnel_number_label',
                                    }],
                                    closable: true,
                                }

                                window.mitt.emit('dialogMessage', dialogData);
                            }
                        }
                        if (error.response) {
                            this.$setErrorsFromResponse(error.response.data);
                        }
                    });
            }
        },
        validateRequiredFields() {
            let requiredKeys = ['personnel_number', 'starting_position_id', 'contract_type_id', 'date_in_service', 'hours_per_week'];
            this.showSwGroupSizeInput = false;
            this.showSchoolLeavingInput = false;

            if (this.calculationToolData.showSwInputForStartingPositionId === parseInt(this.form.starting_position_id)) {
                requiredKeys.push('sw_group_size');
                this.showSwGroupSizeInput = true;
            }
            if (this.calculationToolData.showSchoolLeavingInputMandatory === parseInt(this.form.starting_position_id)) {
                requiredKeys.push('school_leaving_date');
            }

            if (Array.isArray(this.calculationToolData.showSchoolLeavingInputForStartingPositionIds)
                && this.calculationToolData.showSchoolLeavingInputForStartingPositionIds.includes(parseInt(this.form.starting_position_id))) {
                this.showSchoolLeavingInput = true;
                if (
                    Array.isArray(this.calculationToolData.schoolLeavingInputMandatoryStartingPositionIds) &&
                    this.calculationToolData.schoolLeavingInputMandatoryStartingPositionIds.includes(parseInt(this.form.starting_position_id))
                ) {
                    requiredKeys.push('school_leaving_date');
                }
            }
            this.allRequiredAreFilled = !requiredKeys.some(function (key) {
                return !Boolean(this.form[key]);
            }.bind(this));
        },
        validateStartingPosition() {
            this.validateRequiredFields();
            this.contractTypeOptionsFullList = Object.assign({}, this.contract_type);
        },
        switchTabs(tab) {
            this.showTab = tab;
            this.resetUpload();
        },
        clearSearchBox() {
            this.tableSearchValue = '';
        },
        showEditModal() {
            if (this.tableItemsSelected.length === 0) {
                return;
            }
            let modalData = {
                selectedItemsToEdit: this.tableItemsSelected,
                calculationToolId: this.calculationToolIdProp,
                calculationToolData: this.calculationToolData,
            };

            window.mitt.emit('showEmployeeEditModal', modalData);
        },
        confirmDeleteAction() {
            let itemsToDelete = _.map(this.tableItemsSelected, 'id');

            if (itemsToDelete.length === 0) {
                return;
            }

            this.$confirm.require({
                header: this.translate('calculation_tool_data.target_group_data.delete.confirmation_dialog.title'),
                message: this.translate('calculation_tool_data.target_group_data.delete.confirmation_dialog.description'),
                acceptLabel: this.translate('calculation_tool_data.target_group_data.delete.confirmation_dialog.confirm_button_text'),
                rejectLabel: this.translate('calculation_tool_data.target_group_data.delete.confirmation_dialog.cancel_button_text'),
                defaultFocus: false,
                accept: () => {
                    this.deleteTargetGroup({
                        actionType: 'delete',
                        entityData: itemsToDelete,
                    });
                },
            });
        },
        deleteTargetGroup(actionData) {
            let itemsToDelete = _.map(this.tableItemsSelected, 'id');

            if (actionData.actionType !== 'delete' || !_.isEqual(Object.assign({}, itemsToDelete), Object.assign({}, actionData.entityData))) {
                return;
            }

            CalculationToolData.deleteMultiple(this.calculationToolIdProp, itemsToDelete)
                .then(() => {
                    this.errors = '';
                    this.tableItemsSelected = [];
                })
                .catch((error) => {
                    if (error) {
                        this.errors = error.response.data.message ?? error;
                    }
                });
        },
        checkIfNextStepPossible() {
            this.checkBoxes.all = this.checkBoxes.first && this.checkBoxes.second && this.checkBoxes.third;
        },
        goNextPage() {
            if (this.isAuditor) {
                this.postAuditorDataForm();
            }

            if (this.checkBoxes.all || this.isAuditor) {
                CalculationTool.updateStep({ calculationTool: this.calculationToolIdProp, step: getSteps().purchases })
                    .then((response) => {
                        if (response.status === 200) {
                            window.location = route('calculation-tool-data.purchase-data', { calculationTool: this.calculationToolIdProp });
                        }
                    }).catch((error) => {
                        console.log(error.response.data.data.message);
                    });
            }
        },
        resetUpload() {
            window.mitt.emit('showErrorAlert',
                {
                    'id': 'upload-excel',
                });
            this.uploadStatus = '';
        },
        postAuditorDataForm() {
            let formExtra = Auditor.cleanExtraInputsForm(this.auditorExtraForm);

            Auditor.storeExtraInputs(this.calculationToolIdProp, formExtra)
                .then(() => {
                    window.location.href = route('calculation-tool-data.target-group-data', { calculationTool: this.calculationToolIdProp });
                })
                .catch((error) => {
                    if (error.response) {
                        this.$setErrorsFromResponse(error.response.data);
                    }
                })

            // @TODO here is also the part where the auditor history should be saved.
        },
        resetDateOutService() {
            this.form.date_out_of_service = null;
        },
        formatDate(date) {
            // Ensure the date is a valid Date object
            if (!(date instanceof Date) || isNaN(date)) {
                return '';
            }

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const year = date.getFullYear();

            return `${day}-${month}-${year}`;
        }
    }
};
</script>

<style lang="scss">
@import "resources/sass/modules/data-tables";
</style>
