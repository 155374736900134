import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export function reformatDate(date, fromFormat, toFormat) {
    return dayjs(date, fromFormat).format(toFormat);
}

export function certificationStartDate(date, fromFormat, toFormat) {
    if (date) {
        return reformatDate(dayjs(date, fromFormat).set('date', 1).set('month', dayjs(date).get('month')).set('year', dayjs(date).get('year') - 2), fromFormat, toFormat);
    }

    return null;
}

export function certificationEndDate(date, fromFormat, toFormat)
{
    if (date) {
        return reformatDate(dayjs(date, fromFormat).set('date', 1).set('month', dayjs(date).get('month')), fromFormat, toFormat)
    }

    return null;
}

export function signatureStartDate(date, fromFormat, toFormat) {
    if (date) {
        // Calculate the start date as the first day of the next month
        const startDate = dayjs(date, fromFormat).add(1, 'month').startOf('month');
        return reformatDate(startDate, fromFormat, toFormat);
    }
    return null;
}

export function signatureEndDate(date, fromFormat, toFormat) {
    if (date) {
        // Calculate the start date first
        const startDate = dayjs(date, fromFormat).add(1, 'month').startOf('month');
        // Calculate the end date as 2 years after the start date
        const endDate = startDate.add(2, 'years');
        return reformatDate(endDate, fromFormat, toFormat);
    }
    return null;
}
