<script setup>
import {ref} from "vue";

const icon = ref(new URL('/resources/images/icon_pencil_blue.svg', import.meta.url).href);
defineProps({
    altText: {
        type: String,
        default: '',
    },
    clickable: {
        type: Boolean,
        default: true,
    },
});
</script>

<template>
    <img
        :src="icon"
        :alt="altText"
        class="mr-2 icon"
        :class="{
            'cursor-pointer': clickable,
        }"
    >
</template>
