<template>
    <a
        class="flex items-center  text-lg hover:cursor-pointer"
        dusk="app-back-btn"
    >
        <img
            :src="iconChevronLeft"
            class="mr-2"
            alt=""
        >
        <span class="text-pso-primary">{{ translate('layout.wizard.back_button_text') }}</span>
    </a>
</template>

<script>

export default {
    data() {
        return {
            iconChevronLeft: new URL('/resources/images/icon_chevron_left.svg', import.meta.url).href,
        };
    }
};
</script>
