const Roles = {
    user: 1,
    auditor: 2,
    pso: 3,
    advisor: 4,
    admin: 5,
}

export function getUserRoles() {
    return Roles;
}
