import axios from 'axios';

export default {
    namespaced: true,
    state: {
        purchase_list: [],
    },
    getters: {
        purchase_list(state) {
            return state.purchase_list;
        },
    },
    mutations: {
        purchase_list(state, data) {
            state.purchase_list = data;
        },
    },
    actions: {
        index({commit}, calculationToolId) {
            return axios.get('/api/calculation-tools/' + calculationToolId + '/purchases')
                .then((response) => commit('purchase_list', response.data.data));
        },
        original({commit}, calculationToolId) {
            return axios.get('/api/calculation-tools/' + calculationToolId + '/purchases/original')
                .then((response) => commit('purchase_list', response.data.data));
        },
        update({}, data) {
            return axios.patch('/api/calculation-tools/' + data.calculationToolId + '/purchases/' + data.purchaseId, data.payload)
                .then((response) => response);
        },
        createPurchase({dispatch}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + '/purchases', data.payload)
                .then((response) => dispatch("addPurchaseToList", response.data.data));
        },
        addPurchaseToList({commit, state}, purchase) {
            let purchase_list = state.purchase_list;
            purchase_list[state.purchase_list.length] = purchase;
            commit('purchase_list', purchase_list);
        },
        upload({commit}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + '/purchases/upload', data.payload)
                .then((response) => commit('purchase_list', response.data.data));
        },
        deleteMultiple({commit}, data) {
            return axios.delete('/api/calculation-tools/' + data.calculationToolId + '/purchases/delete-multiple', {
                data : {
                    ids: data.payload
                }
            }).then((response) => commit('purchase_list', response.data.data));
        },
        auditorDeleteMultiple({commit}, data) {
            return axios.delete('/api/calculation-tools/' + data.calculationToolId + '/purchases/delete-multiple', {
                data: {
                    ids: data.payload,
                    auditorMessage: data.auditorMessage
                },
            }).then((response) => commit('purchase_list', response.data.data));
        },
    }
};
