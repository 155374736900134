const Views = {
    organisation: 0,
    determine_company_size: 1,
    reference_date_cao_hours: 2,
    target_group_date: 3,
    purchases: 4,
    checklist_aspirant: 5,
    checklist_non_aspirant: 6,
    score: 7,
    additional_information: 8,
    report: 9,
}

export function getViews() {
    return Views;
}
