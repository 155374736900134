import Base from '../Base';
import resultPage from '../Score/ResultPage';
import store from '../../Store/store';

class Score extends Base {
    static getComponents() {
        return {
            'result-page': resultPage,
        };
    }

    static getScoringStats(calculationToolId, version = 'original'){
        return store.dispatch('score/get', {
            'calculationToolId': calculationToolId,
            'version': version,
        }).then((response) => {
            return response.data;
        });
    }

    static getNorms(calculationToolId, version = 'original') {
        return store.dispatch('score/getNorms', { calculationToolId, version }).then((response) => {
            return response;
        });
    }

    static createResult(calculationToolId) {
        return store.dispatch('score/create', calculationToolId).then((response) => {
            return response.data;
        });
    }
}

export default Score;
