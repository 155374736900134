<template>
    <div class="max-w-[1108px]  mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
        <h2 class="text-left text-pso-dark text-2xl font-bold mb-10">
            {{ translate('dashboard.customer.pending_request') }}
        </h2>
        <div class="horizontal-timeline pb-20 relative max-w-[900] flex border-b-2 border-blue-200">
            <div class="w-25">
                <span class="text-pso-dark text-md"> {{ translate('dashboard.customer.calculation_tool' ) }}: </span>
                <p> {{ calculationTool.name }} </p>
            </div>
            <div
                v-if="calculationTool.status == statuses.CANCELLED"
                class="w-full flex relative z-10 justify-center items-center font-bold"
            >
                {{ translate('calculation_tool.status.cancelled') }}
            </div>
            <div
                v-else
                class="w-full flex relative"
            >
                <div class="w-1/4 relative z-10">
                    <div
                        class="w-12 h-12 m-auto mb-4 p-2.5 rounded-full  text-xl leading-6 font-medium text-center"
                        :class="calculationTool.status == 3 ? 'bg-pso-primary text-white' : 'bg-pso-light'"
                    >
                        1
                    </div>
                    <span
                        class="text-pso-dark lg:text-lg md:text-lg sm:text-sm text-sm sm:text-sm text-sm absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                    >
                        {{ translate('dashboard.customer.final_request_auditors') }}
                    </span>
                </div>
                <div class="w-1/4 relative z-10">
                    <div
                        class="w-12 h-12 m-auto rounded-full text-pso-dark text-xl leading-6 mb-4 font-medium p-2.5 text-center"
                        :class="calculationTool.status == 4 ? 'bg-pso-primary text-white' : 'bg-pso-light'"
                    >
                        2
                    </div>
                    <span
                        class="text-pso-dark lg:text-lg md:text-lg sm:text-sm text-sm sm:text-sm text-sm absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                    >
                        {{ translate('dashboard.customer.auditor') }}
                    </span>
                </div>
                <div class="w-1/4 relative z-10">
                    <div
                        class="w-12 h-12 m-auto rounded-full text-pso-dark text-xl leading-6 mb-4 font-medium p-2.5 text-center"
                        :class="calculationTool.status == 5 ? 'bg-pso-primary text-white' : 'bg-pso-light'"
                    >
                        3
                    </div>
                    <span
                        class="text-pso-dark lg:text-lg md:text-lg sm:text-sm text-sm sm:text-sm text-sm absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                    >
                        {{ translate('dashboard.customer.pso') }}
                    </span>
                </div>
                <div class="w-1/4 relative z-10">
                    <div
                        class="w-12 h-12 m-auto rounded-full text-pso-dark text-xl leading-6 mb-4 font-medium p-2.5 text-center"
                        :class="calculationTool.status == 6 ? 'bg-pso-primary text-white' : 'bg-pso-light'"
                    >
                        4
                    </div>
                    <span
                        class="text-pso-dark lg:text-lg md:text-lg sm:text-sm text-sm sm:text-sm text-sm absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                    >
                        {{ translate('dashboard.customer.certificate') }}
                    </span>
                </div>
                <div class="h-[4px] bg-blue-200 w-9/12 absolute z-0 top-6 left-[15%]" />
            </div>
        </div>
        <div class="pt-6 mb-10">
            <div class="text-left text-pso-dark text-xl mb-6">
                <h3> {{ translate('dashboard.customer.overview_application') }} </h3>
            </div>
            <div class="grid grid-cols-2">
                <div>
                    <div class="grid grid-cols-2 mb-4">
                        <span class="text-pso-dark text-md self-center">
                            {{ translate('dashboard.customer.main_organisation') }}
                        </span>
                        <p>
                            {{ mainOrganisation?.data?.company_name }}
                        </p>
                    </div>
                    <div class="grid grid-cols-2">
                        <span class="text-pso-dark text-md self-center">
                            {{ translate('dashboard.customer.underlying_entities') }}
                        </span>
                        <div>
                            <div v-for="(organisation, index) in childOrganisations">
                                <span> {{ organisation.data.company_name }} </span><span v-if="index + 1 < childOrganisations.length"> / </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="grid grid-cols-2 mb-4">
                        <span class="text-pso-dark text-md self-center">
                            {{ translate('dashboard.customer.calculated_score') }}
                        </span>
                        <score-bar
                            :percentage-prop="scoringStats.scorePercentage"
                            :show-tooltip-prop="false"
                            :show-bottom-text-prop="false"
                            :border-radius-prop="'rounded'"
                            :small-width-prop="true"
                        />
                    </div>
                    <div class="grid grid-cols-2 mb-4">
                        <span class="text-pso-dark text-md self-center">
                            {{ translate('dashboard.customer.30plus') }}
                        </span>
                        <p>
                            {{ scoringStats.isThirtyPlusCertified ? 'Ja' : 'Nee' }}
                        </p>
                    </div>
                    <div class="grid grid-cols-2 mb-4">
                        <span class="text-pso-dark text-md self-center">
                            {{ translate('dashboard.customer.reference_year') }}
                        </span>
                        <p>
                            {{ calculationToolData.startDate }} {{ translate('dashboard.customer.till') }} {{ calculationToolData.referenceDate }}
                        </p>
                    </div>
                    <div class="grid grid-cols-2">
                        <span class="text-pso-dark text-md self-center">
                            {{ translate('dashboard.customer.requested_date') }}
                        </span>
                        <p>
                            {{ calculationToolData.requestedDate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Organisation from "../../../../Organisation/Organisation";
import ScoreBar from "../../../../Tools/Parts/ScoreBar/Vue/ScoreBar.vue";
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import {mapGetters} from "vuex";
import CalculationToolData from "../../../../CalculationToolData/CalculationToolData";
import dayjs from 'dayjs';
import _ from "lodash";
import Score from "../../../../Score/Score";
import {getSteps} from "../../../../_config/Steps"
import {getStatuses} from "@_config/Statuses.js";

export default {
    components: {
        ScoreBar
    },
    props: {
        calculationToolProp: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            iconPencil: new URL('/resources/images/icon_pencil.svg', import.meta.url).href,
            scoringStats: [],
            calculationTool: {},
            calculationToolData: {},
            mainOrganisation: [],
            childOrganisations: [],
            statuses: getStatuses(),
        }
    },
    computed: {
        ...mapGetters('calculation_tool', ['organisations_list']),
        ...mapGetters('calculation_tool_data', ['calculation_tool_data']),
    },
    mounted() {
        this.calculationTool = this.calculationToolProp;
        Score.getNorms(this.calculationTool.id);
        Score.getScoringStats(this.calculationTool.id)
            .then((response) => {
                this.scoringStats = response;
            });

        CalculationToolData.get(this.calculationTool.id).then(() => {
            this.calculationToolData.requestedDate = dayjs(this.calculation_tool_data.calculated.requested_date).format('DD-MM-YYYY');
            this.calculationToolData.referenceDate = dayjs(this.calculation_tool_data.calculated.reference_date).format('DD-MM-YYYY');
            this.calculationToolData.startDate = dayjs(this.calculation_tool_data.calculated.start_date).format('DD-MM-YYYY');
        });

        Organisation.index({filterOrganisations: this.calculationTool.id});
        CalculationTool.getOrganisations(this.calculationTool.id).then(() => {
            let mainOrganisationFiltered = _.filter(this.organisations_list, function (item) {
                return item.data.is_main === true;
            });

            let childOrganisationFiltered = _.filter(this.organisations_list, function (item) {
                return item.data.is_main === false;
            });

            this.mainOrganisation = _.first(mainOrganisationFiltered);
            this.childOrganisations = _.map(childOrganisationFiltered);
        });
    },
    methods: {
        goToTheOrganisationStep(calculationToolId) {
            CalculationTool.updateStep({calculationTool: calculationToolId, step: getSteps().organisation})
                .then((response) => {
                    if (response.status === 200) {
                        window.location = route('organisation.index', {calculationTool: calculationToolId});
                    }
                }).catch((error) => {
                    console.log(error.response.data.data.message);
                });
        },
    }
}
</script>
