import axios from 'axios';

const BASE_URL = '/ovs/user-groups/';

export default {
    namespaced: true,
    state: {
        user_group: [],
    },
    getters: {
        user_group(state) {
            return state.user_group;
        },
    },
    mutations: {
        user_group(state, data) {
            state.user_group = data;
        },
    },
    actions: {
        store({}, payload) {
            return axios.post(BASE_URL, payload)
                .then((response) => response);
        },
        update({}, payload) {
            return axios.patch(BASE_URL + payload.id, payload)
                .then((response) => response);
        },
        delete({}, payload) {
            return axios.delete(BASE_URL + payload.id, payload)
                .then((response) => response);
        },
        addUser({}, payload) {
            return axios.post(BASE_URL + payload.id + '/add_user', payload)
                .then((response) => response);
        }
    }
};
