<template>
    <div class="page organisation-index">
        <div class="container mx-auto">
            <calculation-tool-header />

            <div class="max-w-[1108px]  mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button :href="backRoute()" />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold">
                    {{ translate('calculation_tool_data.title') }}
                </h2>
                <p class="text-pso-dark mt-10">
                    {{ translate('calculation_tool_data.paragraph_1') }}
                </p>
                <p class="text-pso-dark">
                    {{ translate('calculation_tool_data.paragraph_2') }}
                </p>

                <form
                    v-if="initialized"
                    id="kvk_request_form"
                    class="form"
                    @submit.prevent="nextStep"
                >
                    <div class="mt-10 reference-date">
                        <div :class="['pb-[8px]', { 'pb-6': errors.reference_date }]">
                            <span class="text-lg text-pso-dark">
                                {{ translate('calculation_tool_data.reference_date.label') }}
                            </span>
                        </div>
                        <div class="w-[406px] relative">
                            <DatePickerInput
                                :model-value="reformatDate(dataForm.reference_date, 'YYYY-MM-DD', 'DD-MM-YYYY')"
                                @update:modelValue="value => dataForm.reference_date = reformatDate(value, 'DD-MM-YYYY', 'YYYY-MM-DD')"
                                id="reference_date"
                                name="reference_date"
                                :min-date="new Date(new Date().setMonth(new Date().getMonth() - 3))"
                                :max-date="new Date()"
                                :placeholder="translate('calculation_tool_data.reference_date.placeholder')"
                            />

                            <span
                                v-if="errors.reference_date"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.reference_date"
                            />
                        </div>
                    </div>

                    <div
                        v-if="showTimeline"
                        class="mt-10 mb-10 reference-date-timeline"
                    >
                        <certification-timeline :timeline-dates-prop="timelineDates" />
                    </div>

                    <div class="mt-10 cao-hours">
                        <div :class="['pb-[8px]', { 'pb-6': errors.cao_hours }]">
                            <span class="text-lg text-pso-dark inline-block">
                                {{ translate('calculation_tool_data.cao_hours.label') }}
                            </span>
                            <Popper
                                :arrow="true"
                                placement="top"
                                class="hover:cursor-pointer"
                            >
                                <div class="ml-1">
                                    <img
                                        :src="iconInfo"
                                        alt="info"
                                    >
                                </div>
                                <template #content>
                                    {{ translate('calculation_tool_data.tooltip_message') }}
                                </template>
                            </Popper>
                        </div>
                        <div class="w-[406px] relative">
                            <MultiSelectInput
                                id="cao_hours"
                                v-model:modelValue="dataForm.cao_hours"
                                name="cao_hours"
                                :options="options"
                                :errors="errors.cao_hours"
                                :placeholder="translate('calculation_tool_data.cao_hours.placeholder')"
                            />

                            <span
                                v-if="errors.cao_hours"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.cao_hours"
                            />
                        </div>
                    </div>
                </form>

                <div class="action-button text-center mt-4">
                    <a
                        :class="['inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange', canContinue ? '' : 'opacity-50 cursor-not-allowed']"
                        @click="nextStep()"
                    >
                        {{ translate('calculation_tool_data.button_next_step') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import CalculationTool from "../../../CalculationTool/CalculationTool";
import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import '@vuepic/vue-datepicker/dist/main.css';
import CertificationTimeline from '../../Parts/CertificationTimeline/Vue/CertificationTimeline.vue';
import CalculationToolData from "../../../CalculationToolData/CalculationToolData";
import dayjs from 'dayjs';
import Popper from 'vue3-popper';
import User from "../../../User/User";
import {getSteps} from "../../../_config/Steps"
import DatePickerInput from "../../../FormInput/DatePickerInput/Vue/DatePickerInput.vue";
import MultiSelectInput from "../../../FormInput/MultiSelectInput/Vue/MultiSelectInput.vue";
import {getCaoHours} from "@_config/CaoHours";
import iconInfo from '@images/icon_info.svg';
import {reformatDate} from '@/js/Helpers/Date';

export default {
    components: {
        MultiSelectInput,
        DatePickerInput,
        CalculationToolHeader,
        Popper,
        CertificationTimeline,
        'app-back-button': BackLinkButton,
    },
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            showTimeline: false,
            meetConditions: false,
            canContinue: false,
            errors: {},
            dataForm: {
                reference_date: null,
                cao_hours: null,
            },
            timelineDates: {
                start: null,
                reference: null,
                today: null,
                certificateStart: null,
                certificateEnd: null,
            },
            date: null,
            options: getCaoHours(),
            initialized: false,
            iconInfo
        };
    },
    computed: {
        ...mapGetters("calculation_tool", ["calculation_tool"]),
        ...mapGetters("calculation_tool_data", ["calculation_tool_data"]),
        ...mapGetters("user", ["roles"]),
    },
    watch: {
        'dataForm.reference_date': {
            handler() {
                this.displayTimeline();
            }
        },
        dataForm: {
            deep: true,
            handler() {
                this.fieldChanged();
            }
        },
    },
    created() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationToolData.get(this.calculationToolIdProp).then(() => {
            if (typeof this.calculation_tool_data !== 'undefined') {
                if (this.calculation_tool_data.cao_hours) {
                    this.dataForm.cao_hours = this.calculation_tool_data.cao_hours;
                }
                if (this.calculation_tool_data.reference_date) {
                    this.dataForm.reference_date = this.calculation_tool_data.reference_date;
                    this.displayTimeline();
                }
            }
            this.initialized = true;
        });
        User.getRoles();
    },
    mounted() {
        this.fieldChanged();
    },

    methods: {
        reformatDate,
        backRoute() {
            return route('calculation-tool-data.index', {
                calculationTool: this.calculationToolIdProp,
            });
        },
        nextStep() {
            if (this.meetConditions === true) {
                this.postCalculationToolDataForm();

                CalculationTool.updateStep({ calculationTool: this.calculationToolIdProp, step: getSteps().company_size })
                    .then((response) => {
                        if (response.status === 200) {
                            window.location.href = route('calculation-tool-data.determine-company-size', { calculationTool: this.calculationToolIdProp });
                        }
                    }).catch((error) => {
                        console.log(error.response.data.data.message);
                    });
            }
        },
        displayTimeline() {
            const referenceDate = dayjs(this.dataForm.reference_date);
            this.dataForm.reference_date = referenceDate.format('YYYY-MM-DD');
            const todayDate = dayjs();

            this.timelineDates = {
                start: referenceDate.subtract(1, 'year').format('DD-MM-YYYY'),
                reference: referenceDate.format('DD-MM-YYYY'),
                today: todayDate.format('DD-MM-YYYY'),
                certificateStart: todayDate.startOf('month').add(1, 'month').format('DD-MM-YYYY'),
                certificateEnd: todayDate.startOf('month').add(1, 'month').add(2, 'year').format('DD-MM-YYYY'),
            };
            this.showTimeline = true;
        },
        fieldChanged() {
            this.meetConditions = this.dataForm.reference_date !== null && this.dataForm.cao_hours !== null;

            this.checkCanContinue();
        },
        checkCanContinue() {
            if (this.meetConditions) {
                this.canContinue = true;
            }
        },
        postCalculationToolDataForm() {
            let formData = CalculationToolData.buildCalculationToolDataForm(this.dataForm);
            CalculationToolData.create(this.calculationToolIdProp, formData)
                .then((data) => {
                    if (typeof data.data.calculation_tool_data === 'undefined') {
                        throw new Error('Calculation Tool Data ID is missing');
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.errors = error.response.data.message;
                    }
                });
        },
    },
}
</script>

<style>
@import '@vueform/multiselect/themes/default.css';

:root {
    --ms-option-bg-selected-pointed: #009bde;
    --ms-option-color-selected-pointed: #FFFFFF;
    --ms-option-color-selected: #FFFFFF;
    --ms-option-bg-selected: #009bde;
    --ms-ring-width: 0;
}

.multiselect-option.is-selected span {
    color: #ffffff;
}



.multiselect {
    padding: 0.75rem 18px 0.75rem 1.5rem;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-color: rgb(235 235 235);
    height: 50px;
}

.multiselect-single-label {
    padding: 0.75rem 40px 0.75rem 1.5rem;
}

.icon_chevron_down {
    background-color: #393c3f;
    -webkit-mask-image: url('/resources/images/icon_chevron_down.svg');
    mask-image: url('/resources/images/icon_chevron_down.svg');
}
</style>
