import _ from 'lodash';

export default {
    methods: {
        showErrorMessage(summary = null, detail = null)
        {
            this.$toast.add({
                severity: 'error',
                summary: summary ?? this.translate('rules.application_error.summary'),
                detail: detail ?? this.translate('rules.application_error.detail'),
                life: 5000
            });
        }
    }
}
