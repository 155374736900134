<template>
    <div class="checkbox-row">
        <input
            :id="id"
            type="checkbox"
            :checked="modelValue"
            class="align-text-bottom mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"

            @change="($event) => $emit('update:modelValue', $event.target.checked)"
        >
        <label
            v-if="label"
            :for="id"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            default: null,
        }
    }
}

</script>
