import Base from "../Base";
import vueCalculationToolsListIndex from "./CalculationToolsList";
import store from "../../Store/store";

class CalculationTool extends Base {
    static getComponents() {
        return {
            "calculation-tools-list-index": vueCalculationToolsListIndex,
        };
    }

    static getCalculationTools() {
        return store.dispatch('calculation_tool/index').then((response) => {
            return response;
        });
    }

    static getAuditorDashboardData() {
        return store.dispatch('calculation_tool/getAuditorDashboardData').then((response) => {
            return response;
        });
    }

    static getPsoDashboardData() {
        return store.dispatch('calculation_tool/getPsoDashboardData').then((response) => {
            return response;
        });
    }

    static buildCreateCalculationToolForm(data) {
        return {
            name: data.name,
        };
    }

    static buildAdditionalInformationForm(data) {
        return {
            invitations_email: data.invitations_email,
            communications_email: data.communications_email,
            advisor_id: data.advisor_id,
        };
    }

    static buildInvoiceDetailsForm(data) {
        return {
            company_name: data.company_name,
            street_name: data.street_name,
            house_number: data.house_number,
            addition: data.addition,
            postal_code: data.postal_code,
            place_name: data.place_name,
            receiver_name: data.receiver_name,
            invoice_email: data.invoice_email,
            mailbox: data.mailbox,
            mailbox_postal_code: data.mailbox_postal_code,
            mailbox_place: data.mailbox_place,
            payment_reference: data.payment_reference
        };
    }

    static createCalculationTool(data) {
        return store.dispatch('calculation_tool/store', data).then((response) => {
            return response.data;
        });
    }

    static buildUpdateStatusForm(data) {
        let formData = {};

        formData.id = data.id;
        formData.status = data.status;

        return formData;
    }

    static buildUpdateCalculationToolForm(data) {
        var formData = {};
        formData.id = data.id;

        if(typeof data.name !== 'undefined') {
            formData.name = data.name;
        }
        if(typeof data.signature !== 'undefined') {
            formData.signature = data.signature;
        }
        if(typeof data.recertification !== 'undefined') {
            formData.recertification = data.recertification;
        }

        if(typeof data.status !== 'undefined') {
            formData.status = data.status;
        }

        return formData;
    }

    static updateCalculationToolForm(data) {
        return store.dispatch('calculation_tool/update', data).then((response) => {
            return response.data;
        });
    }

    static notify(payload) {
        return store.dispatch('calculation_tool/notify', payload).then((response) => {
            return response;
        });
    }

    static deleteCalculationToolForm(data) {
        return store.dispatch('calculation_tool/delete', data).then((response) => {
            return response.data;
        });
    }

    static copyCalculationToolForm(data) {
        return store.dispatch('calculation_tool/copy', data).then((response) => {
            return response.data;
        });
    }

    static get(id) {
        return store.dispatch('calculation_tool/get', {id: id});
    }

    static activate(id) {
        return store.dispatch('calculation_tool/activate', {id: id});
    }

    static decline(id) {
        return store.dispatch('calculation_tool/decline', {id: id});
    }
    static startAudit(data) {
        return store.dispatch('calculation_tool/startAudit', data);
    }

    static getScore(id) {
        return store.dispatch('calculation_tool/getScore', {id: id});
    }

    static getOrganisations(calculationToolId, payload) {
        return store.dispatch('calculation_tool/getOrganisations', {calculationToolId: calculationToolId, data: payload});
    }

    static getCertifiedOrganisations(calculationToolId) {
        return store.dispatch('calculation_tool/getCertifiedOrganisations', {calculationToolId: calculationToolId});
    }

    static addOrganisationToStore(organisation) {
        return store.dispatch('calculation_tool/addOrganisationToStore', organisation)
    }

    static removeOrganisationFromStore(organisation_id) {
        return store.dispatch('calculation_tool/removeOrganisationFromStore', organisation_id)
    }

    static updateStep(payload) {
        return store.dispatch('calculation_tool/updateStep', payload).then((response) => {
            return response;
        });
    }

    static updateAdditionalInformation(calculationToolId, payload) {
        return store.dispatch('calculation_tool/updateAdditionalInformation', {calculationToolId: calculationToolId, data: payload});
    }

    static updateInvoiceDetails(calculationToolId, payload) {
        return store.dispatch('calculation_tool/updateInvoiceDetails', {calculationToolId: calculationToolId, data: payload});
    }

    static getAdditionalInformation(calculationToolId) {
        return store.dispatch('calculation_tool/getAdditionalInformation', {calculationTool: calculationToolId});
    }

    static getInvoiceDetails(calculationToolId, payload) {
        return store.dispatch('calculation_tool/getInvoiceDetails', {calculationTool: calculationToolId, data: payload});
    }

    static getAuditors(calculationToolId) {
        return store.dispatch('calculation_tool/getAuditors', {calculationTool: calculationToolId});
    }

    static getRequestedAuditors(calculationToolId) {
        return store.dispatch('calculation_tool/getRequestedAuditors', {calculationTool: calculationToolId});
    }

    static addAuditorCompany(calculationToolId, payload) {
        return store.dispatch('calculation_tool/addAuditorCompany', {calculationToolId: calculationToolId, data: payload});
    }
    static sendAuditorQuotes(calculationToolId, payload) {
        return store.dispatch('calculation_tool/sendAuditorQuotes', {calculationToolId: calculationToolId, data: payload});
    }
    static sendQuotationToAuditors(calculationToolId, payload) {
        return store.dispatch('calculation_tool/sendQuotationToAuditors', {calculationToolId: calculationToolId, data: payload});
    }

    static validate(calculationToolId) {
        return store.dispatch('calculation_tool/validate', {calculationTool: calculationToolId}).then((response) => {
            return response.data;
        });
    }
}

export default CalculationTool;
