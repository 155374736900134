<template>
    <div
        class="pending-application container mx-auto py-12"
    >
        <div class="max-w-[1108px] mx-auto">
            <div class="page-header text-pso-dark font-bold text-3xl mb-3">
                {{ translate('dashboard.customer.title') }}
            </div>
            <div class="page-sub-header text-pso-dark">
                {{ translate('dashboard.customer.subtitle') }}
            </div>
        </div>
    </div>

    <div class="container mx-auto">
        <div
            v-for="calculationToolInReview in calculationToolsInReview"
            :key="calculationToolInReview.id"
            class="container mx-auto mb-12"
        >
            <pending-application
                :calculation-tool-prop="calculationToolInReview"
            />
        </div>
    </div>

    <div class="container mx-auto mb-12">
        <div class="max-w-[1108px]  mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
            <h2 class="text-left text-pso-dark text-2xl font-bold mb-10">
                {{ translate('dashboard.customer.other_calculation_tools') }}
            </h2>
            <ul
                v-if="Object.keys(calculationToolsList).length > 0"
                class="calculation-tools-list list-none m-0 p-0"
            >
                <li
                    v-for="(calculationTool, index) in calculationToolsList"
                    :key="index"
                    class="rounded-xl mb-10 p-8 bg-gray-050 hover:bg-pso-light"
                >
                    <calculation-tool-item
                        :calculation-tool-prop="calculationTool"
                    />
                </li>
            </ul>
            <new-calculation-tool-form />
        </div>
    </div>
</template>

<script>
import User from "../../../../User/User";
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import CalculationToolItem from "../../../../CalculationTool/Parts/CalculationToolItem/Vue/CalculationToolItem.vue";
import PendingApplication from "../../PendingApplication/Vue/PendingApplication.vue";
import NewCalculationToolForm from "../../../../CalculationTool/Parts/NewCalculationToolForm/Vue/NewCalculationToolForm.vue";
import {mapGetters} from "vuex";
import _ from "lodash";
import {getStatuses} from "../../../../_config/Statuses";

export default {
    components: {
        PendingApplication,
        CalculationToolItem,
        NewCalculationToolForm,
    },
    data() {
        return {
            calculationToolsList: [],
            calculationToolsInReview: [],
            calculationToolData: {},
            statuses: getStatuses()
        };
    },
    computed: {
        ...mapGetters("calculation_tool", ["calculation_tools_list"]),
    },
    watch: {
        calculation_tools_list() {
            this.calculationToolsList = this.calculation_tools_list;

            this.calculationToolsInReview = _.filter(this.calculationToolsList, _.bind(function(item) {
                return Boolean(item.status !== this.statuses.PROSPECTS) && Boolean(item.status !== this.statuses.QUOTATION);;
            }, this));

            this.calculationToolsList = _.filter(this.calculationToolsList, _.bind(function(item) {
                return !Boolean(item.status !== this.statuses.PROSPECTS) || !Boolean(item.status !== this.statuses.QUOTATION);;
            }, this));
        },
        calculationToolsList() {
            let calculationToolsCount = Object.keys(this.calculationToolsList).length;
            this.newCalculationToolFormOpen = calculationToolsCount === 0;
            this.newCalculationToolButtonIsShown = calculationToolsCount >= 1;
        }
    },
    mounted() {
        CalculationTool.getCalculationTools();
        window.mitt.on('removeFromCalculationToolList', (id) => {
            delete this.calculation_tools_list[id];
            this.calculationToolsList = this.calculation_tools_list;
        });
    },
    methods: {
        stepOneRoute() {
            return route('dashboard');
        },
    }
};
</script>
