<template>
    <div class="enter-code-form">
        <div class="container mx-auto py-8">
            <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto my-5">
                <div class="page-header text-pso-primary font-bold font-black text-3xl">
                    {{ translate('auth.two_factor_auth.enter_code.page_title') }}
                </div>
            </div>
            <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto rounded-xl py-12 px-8 bg-white shadow">
                <div class="mb-8">
                    <p class="font-bold mb-4 text-pso-dark">
                        {{ translate('auth.two_factor_auth.enter_code.page_sub_title') }}
                    </p>
                    <p class="mb-4 text-pso-dark">
                        {{ translate('auth.two_factor_auth.enter_code.info_text') }}
                    </p>
                    <p class="mb-4 text-pso-dark">
                        {{ translate('auth.two_factor_auth.enter_code.your_code') }}
                    </p>
                </div>

                <form
                    id="tfa_enter_code_form"
                    class="form"
                    @submit.prevent="submitCode"
                >
                    <div class="mb-5">
                        <div class="flex justify-around items-center max-w-[400px] mx-auto">
                            <div class="digit mr-3">
                                <input
                                    id="digit_one"
                                    ref="input_1"
                                    v-model="enterCodeForm.digit_one"
                                    :class="{'border-red-500': errors}"
                                    autofocus
                                    class="form-input rounded-l max-w-[45px] w-full border-pso-primary text-center font-medium text-lg p-2 py-3"
                                    maxlength="1"
                                    name="digit_one"
                                    step="1"
                                    tabindex="1"
                                    type="number"
                                    @input="incrementIndex"
                                >
                            </div>
                            <div class="digit mr-3">
                                <input
                                    id="digit_two"
                                    ref="input_2"
                                    v-model="enterCodeForm.digit_two"
                                    :class="{'border-red-500': errors}"
                                    class="form-input rounded-l max-w-[45px] w-full border-pso-primary text-center font-medium text-lg p-2 py-3"
                                    disabled
                                    maxlength="1"
                                    name="digit_two"
                                    step="1"
                                    tabindex="2"
                                    type="number"
                                    @input="incrementIndex"
                                >
                            </div>
                            <div class="digit mr-3">
                                <input
                                    id="digit_three"
                                    ref="input_3"
                                    v-model="enterCodeForm.digit_three"
                                    :class="{'border-red-500': errors}"
                                    class="form-input rounded-l max-w-[45px] w-full border-pso-primary text-center font-medium text-lg p-2 py-3"
                                    disabled
                                    maxlength="1"
                                    name="digit_three"
                                    step="1"
                                    tabindex="3"
                                    type="number"
                                    @input="incrementIndex"
                                >
                            </div>
                            <div class="digit mr-3">
                                <input
                                    id="digit_four"
                                    ref="input_4"
                                    v-model="enterCodeForm.digit_four"
                                    :class="{'border-red-500': errors}"
                                    class="form-input rounded-l max-w-[45px] w-full border-pso-primary text-center font-medium text-lg p-2 py-3"
                                    disabled
                                    maxlength="1"
                                    name="digit_four"
                                    step="1"
                                    tabindex="4"
                                    type="number"
                                    @input="incrementIndex"
                                >
                            </div>
                            <div class="digit mr-3">
                                <input
                                    id="digit_five"
                                    ref="input_5"
                                    v-model="enterCodeForm.digit_five"
                                    :class="{'border-red-500': errors}"
                                    class="form-input rounded-l max-w-[45px] w-full border-pso-primary text-center font-medium text-lg p-2 py-3"
                                    disabled
                                    maxlength="1"
                                    name="digit_five"
                                    step="1"
                                    tabindex="5"
                                    type="number"
                                    @input="incrementIndex"
                                >
                            </div>
                            <div class="digit">
                                <input
                                    id="digit_six"
                                    ref="input_6"
                                    v-model="enterCodeForm.digit_six"
                                    :class="{'border-red-500': errors}"
                                    class="form-input rounded-l max-w-[45px] w-full border-pso-primary text-center font-medium text-lg p-2 py-3"
                                    disabled
                                    maxlength="1"
                                    name="digit_six"
                                    step="1"
                                    tabindex="6"
                                    type="number"
                                    @input="incrementIndex"
                                >
                            </div>
                        </div>

                        <div
                            v-if="errors"
                            role="alert"
                        >
                            <span
                                class="text-sm font-normal text-red-500"
                                v-text="errors"
                            />
                        </div>
                    </div>

                    <div class="mb-5">
                        <p class="reset-two-factor text-pso-dark">
                            <a
                                class="text-pso-secondary text-md font-regular cursor-pointer"
                                @click="sendResetMail()"
                            >
                                {{ translate('auth.two_factor_auth.enter_code.reset_link_text') }}
                            </a>
                        </p>
                    </div>

                    <div class="mb-5">
                        <div class="w-full flex flex-row-reverse justify-end">
                            <label
                                class="text-md ml-2 text-sm leading-4 text-pso-dark"
                                for="remember_me"
                            >
                                {{ translate('auth.two_factor_auth.enter_code.remember_me_checkbox') }}
                            </label>
                            <input
                                id="remember_me"
                                v-model="remember_me"
                                class="border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                name="remember_me"
                                type="checkbox"
                                value="1"
                            >
                        </div>
                    </div>

                    <div class="row mb-0">
                        <div class="col-md-8 offset-md-4 text-center">
                            <button
                                :class="formDisabled ? 'opacity-50 cursor-not-allowed' : ''"
                                :disabled="formDisabled"
                                class="d-inline-block mx-auto mt-4 mb-3 rounded-xl px-12 py-4 font-medium text-lg text-white bg-pso-orange"
                                type="submit"
                            >
                                {{ translate('auth.two_factor_auth.enter_code.submit_button') }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import User from "../../../../User/User";

const DEFAULT_FORM_VALUES = {
    digit_one: null,
    digit_two: null,
    digit_three: null,
    digit_four: null,
    digit_five: null,
    digit_six: null,
    remember_me: null,
};

export default {
    data() {
        return {
            enterCodeForm: Object.assign({}, DEFAULT_FORM_VALUES),
            remember_me: null,
            failed: null,
            success: null,
            errors: null,
            formDisabled: true,
            digit_index: 1
        };
    },
    watch: {
        'enterCodeForm': {
            handler: function (inputValues) {
                if (Object.values(inputValues).indexOf('') > -1 || Object.values(inputValues).indexOf(null) < 6) {
                    this.disableFormSubmission();
                } else {
                    this.enableFormSubmission();
                }
            },
            deep: true
        }
    },

    methods: {
        submitCode() {
            this.errors = null;
            this.enterCodeForm.remember_me = this.remember_me;
            let formData = User.buildEnterTwoFactorAuthCodeForm(this.enterCodeForm);
            User.checkTwoFactorAuthCode(formData)
                .then((data) => {
                    if (data.codeAccepted === true) {
                        window.location.href = '/';
                    }
                })
                .catch((error) => {
                    if (error) {
                        this.enterCodeForm = Object.assign({}, DEFAULT_FORM_VALUES);

                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        this.errors = error.response.data.message;
                    }
                });
        },
        moveToNextField() {
            let next_element = "input_" + this.digit_index;
            if (this.$refs[next_element]) {
                this.$refs[next_element].removeAttribute('disabled');
                this.$refs[next_element].focus();
            }
        },
        enableFormSubmission() {
            this.formDisabled = false;
        },
        disableFormSubmission() {
            this.formDisabled = true;
        },
        sendResetMail() {
            window.location.href = route('two-factor-auth.send-reset-mail');
        },
        incrementIndex(event) {
            if (event.target.value == '') {
                this.digit_index = event.target.tabIndex;
            } else {
                this.digit_index = event.target.tabIndex + 1;
            }
            this.moveToNextField();
        }
    }
};
</script>

<style lang="scss" scoped>
/* Hide up/down arrows on number input field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
</style>
