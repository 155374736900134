<template>
    <div class="widget certification-timeline flex items-center justify-center h-[150px]">
        <div class="event relative">
            <div class="event-top absolute -left-10 -top-6 w-[160px] text-md text-pso-dark">
                {{ translate('calculation_tool_data.certification_timeline.start_of_reference_year') }}
            </div>
            <div class="event-dot w-3 h-3 bg-white border-2 border-pso-dark rounded-full" />
            <div class="event-date absolute w-[72px] mt-7 -ml-5 text-md">
                {{ timelineDatesProp.start }}
            </div>
        </div>

        <div class="line-text-wrapper relative">
            <div class="line-text absolute left-0 top-1 w-[140px] text-center text-pso-primary text-md ">
                {{ translate('calculation_tool_data.certification_timeline.reference_year_line_text') }}
            </div>
        </div>

        <div class="line-between-events h-1 w-[140px] bg-pso-dark" />

        <div class="event relative">
            <div class="event-top flex items-center absolute -left-5 -top-6 w-[100px] text-md text-pso-primary">
                {{ translate('calculation_tool_data.certification_timeline.reference_date') }}
                <Popper
                    :arrow="true"
                    placement="top"
                    class="hover:cursor-pointer"
                >
                    <div class="ml-1">
                        <img
                            :src="iconInfo"
                            alt="info"
                        >
                    </div>
                    <template #content>
                        {{ translate('calculation_tool_data.certification_timeline.reference_date_tooltip') }}
                    </template>
                </Popper>
            </div>
            <div class="event-dot w-3 h-3 bg-white border-2 border-pso-dark rounded-full" />
            <div class="event-date absolute w-[72px] mt-7 -ml-5 text-md">
                {{ timelineDatesProp.reference }}
            </div>
        </div>

        <div class="line-between-events h-1 w-[100px] bg-pso-dark" />

        <div class="event relative">
            <div class="event-top absolute -left-5 -top-6 w-[100px] text-md text-grey-800">
                {{ translate('calculation_tool_data.certification_timeline.today') }}
            </div>
            <div class="event-dot w-3 h-3 bg-white border-2 border-pso-dark rounded-full" />
            <div class="event-date absolute w-[72px] mt-7 -ml-5 text-md">
                {{ timelineDatesProp.today }}
            </div>
        </div>

        <div class="line-between-events h-1 w-[100px] bg-pso-dark" />

        <div class="event relative">
            <div class="event-top flex items-center absolute -left-10 -top-6 w-[160px] text-md text-grey-800">
                {{ translate('calculation_tool_data.certification_timeline.certificate_start_date') }}
                <Popper
                    :arrow="true"
                    placement="top"
                    class="hover:cursor-pointer"
                >
                    <div class="ml-1">
                        <img
                            :src="iconInfo"
                            alt="info"
                        >
                    </div>
                    <template #content>
                        {{ translate('calculation_tool_data.certification_timeline.certificate_start_date_tooltip') }}
                    </template>
                </Popper>
            </div>
            <div class="event-dot w-3 h-3 bg-white border-2 border-pso-dark rounded-full" />
            <div class="event-date absolute w-[72px] mt-7 -ml-5 text-md">
                {{ timelineDatesProp.certificateStart }}
            </div>
        </div>

        <div class="line-text-wrapper relative">
            <div class="line-text absolute left-0 top-1 w-[225px] text-center text-pso-primary text-md">
                {{ translate('calculation_tool_data.certification_timeline.certificate_duration_line_text') }}
            </div>
        </div>

        <div class="line-between-events h-1 w-[225px] bg-pso-dark" />

        <div class="event relative">
            <div class="event-top absolute -left-10 -top-6 w-[100px] text-md text-grey-800">
                {{ translate('calculation_tool_data.certification_timeline.certificate_end_date') }}
            </div>
            <div class="event-dot w-3 h-3 bg-white border-2 border-pso-dark rounded-full" />
            <div class="event-date absolute w-[72px] mt-7 -ml-5 text-md">
                {{ timelineDatesProp.certificateEnd }}
            </div>
        </div>
    </div>
</template>

<script>

import Popper from 'vue3-popper';

export default {
    components: {Popper},
    props: {
        timelineDatesProp: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            iconInfo: new URL('/resources/images/icon_info.svg', import.meta.url).href,
        };
    },
};
</script>
<style>
    .popper {
        min-width: 300px;
    }
</style>
