<template>
    <div class="page auditor-dashboard">
        <div class="container mx-auto">
            <div class="max-w-[1108px]  mx-auto pb-12">
                <div class="page-header text-pso-dark font-bold text-3xl">
                    {{ translate('dashboard.pso.dashboard.title') }}
                </div>
            </div>
            <div class="max-w-[1108px]  mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <h2 class="text-center text-pso-dark text-2xl font-bold">
                    {{ translate('dashboard.auditor.dashboard.subtitle') }}
                </h2>
                <section class="audit-reports-table-section pb-[22px] my-10">
                    <div class="search-box-wapper flex justify-end items-center mb-2 relative">
                        <input v-model="tableSearchValue" :style="{ backgroundImage: 'url(' + iconMagnifier + ')' }"
                            class="border-gray-200 rounded-xl py-4 px-10 bg-no-repeat bg-[center_left_1rem] text-lg focus:outline-0 focus:border-gray-200 focus:shadow-none focus:ring-0"
                            type="text">
                        <button v-if="tableSearchValue" class="absolute right-4" @click="clearSearchBox()">
                            <img :src="iconClose" alt="clear">
                        </button>
                    </div>
                    <EasyDataTable id="easy-data-table" :header-background-color="tableThemeColor"
                        :headers="tableHeaders" :items="calculationToolsList" :items-selected="tableItemsSelected"
                        :rows-items="[5, 10, 25, 50]" :rows-per-page="10" :search-field="tableSearchFields"
                        :search-value="tableSearchValue" :theme-color="tableThemeColor" buttons-pagination
                        header-font-color="white" rows-per-page-message="">
                        <template #loading>
                            <img :src="loadingImage" alt="">
                        </template>
                    </EasyDataTable>
                </section>


                <div class="action-button text-center">
                    <button :class="selectedCalculationToolId === null ? 'opacity-50 cursor-not-allowed' : ''"
                        :disabled="selectedCalculationToolId === null"
                        class="bg-pso-orange text-white py-3 px-8 rounded-xl font-medium inline-block"
                        dusk="btn-auditor-dashboard-continue" @click="nextStep()">
                        {{ translate('dashboard.auditor.dashboard.continue') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import EasyDataTable from "vue3-easy-data-table";
import CalculationTool from "../../../../CalculationTool/CalculationTool";

export default {
    components: {
        EasyDataTable,
    },
    data() {
        return {
            tableThemeColor: '#015D9F',
            tableSearchValue: '',
            tableHeaders: [],
            tableItemsSelected: [],
            selectedCalculationToolId: null,
            selectedCalculationToolOrganisationId: null,
            tableSearchFields: ['organisations.0.company_name', 'audit_date', 'audit_report_submitted_at', 'recertification'],
            loadingImage: new URL('/resources/images/loading.svg', import.meta.url).href,
            iconMagnifier: new URL('/resources/images/icon_magnifier.svg', import.meta.url).href,
            iconClose: new URL('/resources/images/icon_times.svg', import.meta.url).href,
        }
    },
    computed: {
        ...mapGetters('calculation_tool', { calculationToolsListRaw: 'auditor_dashboard_data' }),

        // New computed property that converts audit_date strings to Date objects
        calculationToolsList() {
            return this.calculationToolsListRaw.map(item => {
                return {
                    ...item,
                    // Format audit_date and audit_report_submitted_at fields
                    audit_date: this.formatDate(item.audit_date),
                    audit_report_submitted_at: item.audit_report_submitted_at
                        ? this.formatDate(item.audit_report_submitted_at)
                        : "-",
                };
            });
        },
    },
    watch: {
        tableItemsSelected: {
            deep: true,
            handler(value) {
                if (value.length > 1) {
                    this.tableItemsSelected.pop();
                }

                this.selectedCalculationToolOrganisationId = this.tableItemsSelected[0].organisations[0].id;
                this.selectedCalculationToolId = this.tableItemsSelected[0].id;
            }
        },
    },
    mounted() {
        CalculationTool.getPsoDashboardData();
        this.setTableHeaders();
    },
    methods: {
        formatDate(date) {
            // Convert the date string to a Date object
            const d = new Date(date.split('-').reverse().join('-'));

            // Extract day, month, and year
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const year = d.getFullYear();
            if (!year || !month || !day) {
                return "-"; // Handle cases where date is not provided
            } else {
                return `${year}-${month}-${day}`; // Format as DD-MM-YYYY
            }
        },
        nextStep() {
            return window.location = route('pso.organisation.summary', this.selectedCalculationToolId);
        },
        clearSearchBox() {
            this.tableSearchValue = '';
        },
        setTableHeaders() {
            this.tableHeaders = [
                {
                    text: this.translate('dashboard.pso.dashboard.reports_table.company_name'),
                    value: "organisations.0.company_name",
                    sortable: true,
                },
                {
                    text: this.translate('dashboard.pso.dashboard.reports_table.auditor_name'),
                    value: "auditors.0.full_name",
                    sortable: true,
                },
                {
                    text: this.translate('dashboard.pso.dashboard.reports_table.audit_date'),
                    value: "audit_date",
                    sortable: true,
                },
                {
                    text: this.translate('dashboard.pso.dashboard.reports_table.date_sent_by_auditor'),
                    value: "audit_report_submitted_at",
                    sortable: true,
                },
                {
                    text: this.translate('dashboard.pso.dashboard.reports_table.recertification'),
                    value: "recertification",
                    sortable: true,
                },
            ];
        },
    }
};
</script>

<style lang="scss" scoped>
#easy-data-table {
    :deep(th:first-child) div {
        display: none;
    }
}
</style>
