import axios from 'axios';

export default {
    namespaced: true,
    state: {
        advisors: [],
        advisor: []
    },
    getters: {
        advisor(state) {
            return state.advisor;
        },
        advisors(state) {
            return state.advisors;
        }
    },
    mutations: {
        advisor(state, data) {
            state.advisor = data;
        },
        advisors(state, data) {
            state.advisors = data;
        }
    },
    actions: {
        index({commit}) {
            return axios.get('/api/advisors/')
                .then((response) => commit('advisors', response.data.data));
        },
        show({commit}, advisorId) {
            return axios.get('/api/advisors/' + advisorId)
                .then((response) => commit('advisor', response.data.data));
        },
        create({}, payload) {
            return axios.post('/api/advisors/', payload.data)
                .then((response) => response);
        },
        update({}, payload) {
            return axios.patch('/api/advisors/' + payload.id, payload.data)
                .then((response) => response);
        },
        delete({}, payload) {
            return axios.delete('/api/advisors/' + payload.id, payload.data)
                .then((response) => response);
        },
    }
};
