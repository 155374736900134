import axios from 'axios';

export default {
    namespaced: true,
    state: {
        calculation_tool_data: [],
        calculation_tool_data_auditor: []
    },
    getters: {
        calculation_tool_data(state) {
            return state.calculation_tool_data;
        },
        calculation_tool_data_auditor(state) {
            return state.calculation_tool_data_auditor;
        }
    },
    mutations: {
        calculation_tool_data(state, data) {
            state.calculation_tool_data = data;
        },
        calculation_tool_data_auditor(state, data) {
            state.calculation_tool_data_auditor = data;
        }
    },
    actions: {
        create({}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + '/data', data.payload)
                .then((response) => response);
        },
        original({commit}, payload) {
            return axios.get('/api/calculation-tools/' + payload.id + '/data/original')
                .then((response) => response.data)
                .then((response) => commit('calculation_tool_data', response.data));
        },
        auditor({commit}, payload) {
            return axios.get('/api/calculation-tools/' + payload.id + '/data/auditor')
                .then((response) => response.data)
                .then((response) => commit('calculation_tool_data_auditor', response.data));
        },
        get({commit}, payload) {
            return axios.get('/api/calculation-tools/' + payload.id + '/data/')
                .then((response) => response.data)
                .then((response) => commit('calculation_tool_data', response.data));
        },
        update({}, data) {
            return axios.patch('/api/calculation-tools/' + data.calculationToolId + '/data', data.payload)
                .then((response) => response);
        },
    }
};
