<template>
    <div class="new-calculation-tool-form">
        <div
            v-if="newCalculationToolButtonIsShown === true"
        >
            <a
                class="flex items-center w-[316px] border-gray-400 border-[1px] rounded-l p-4 pl-5 bg-white"
                href="#"
                dusk="btn-open-calculation-tool-form"
                @click.prevent="toggleNewCalculationToolForm"
            >
                <img
                    :src="iconPlusBlue"
                    alt="delete"
                    class="mr-2"
                >

                <span class="text-pso-primary text-lg font-medium">
                    {{ translate('calculation_tool.list.index.create_button_text') }}
                </span>
            </a>
        </div>

        <div
            v-if="newCalculationToolFormOpen === true"
            class="rounded-xl mb-10 p-8 bg-pso-light"
        >
            <form
                id="new_calculation_tool_form"
                class="form"
                @submit.prevent="createCalculationTool"
            >
                <slot />

                <div class="mb-4 w-1/2">
                    <label
                        for="calculation_tool_name"
                        class="block mb-2 text-lg text-pso-dark text-italic"
                    >
                        {{ translate('calculation_tool.list.create_form.name_field_label') }}
                    </label>
                    <input
                        id="calculation_tool_name"
                        v-model="newCalculationToolForm.name"
                        type="text"
                        class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                        :class="{'border-red-500': errorMessage}"
                        name="calculation_tool_name"
                        :placeholder="translate('calculation_tool.list.create_form.name_field_placeholder')"
                        autocomplete="calculation_tool_name"
                    >
                    <span
                        v-if="errorMessage"
                        class="text-sm text-red-500"
                        role="alert"
                        v-text="errorMessage"
                    />
                </div>

                <button
                    type="submit"
                    class="d-block mb-8 px-4 py-2 rounded-xl bg-pso-orange text-white font-medium"
                    dusk="btn-create-calculation-tool"
                >
                    {{ translate('calculation_tool.list.create_form.button_text') }}
                </button>
            </form>
        </div>
    </div>
</template>


<script>
import CalculationTool from "../../../../CalculationTool/CalculationTool";

export default {
    data() {
        return {
            iconPlusBlue: new URL('/resources/images/icon_plus_blue.svg', import.meta.url).href,
            newCalculationToolButtonIsShown: true,
            newCalculationToolFormOpen: false,
            newCalculationToolForm: {
                'name': null,
                'user_type': 1, // @todo: We need to pass this inside vue from php
            },
            calculationToolNewName: null,
            actionConfirmed: false,
            errorMessage: '',
        };
    },
    methods: {
        toggleNewCalculationToolForm() {
            this.newCalculationToolFormOpen = !this.newCalculationToolFormOpen;
            this.newCalculationToolButtonIsShown = !this.newCalculationToolButtonIsShown;
        },

        createCalculationTool() {
            let formData = CalculationTool.buildCreateCalculationToolForm(this.newCalculationToolForm);
            CalculationTool.createCalculationTool(formData)
                .then((data) => {
                    this.errorMessage = '';
                    this.clearNewCalculationToolForm();
                    this.toggleNewCalculationToolForm();

                    window.location = route('organisation.index', {calculationTool: data.data.id});
                    //CalculationTool.getCalculationTools();
                })
                .catch((error) => {
                    if (error) {
                        this.errorMessage = error.response.data.message;
                    }
                });
        },

        clearNewCalculationToolForm() {
            this.newCalculationToolForm.name = null;
        },
    }
};
</script>

