import * as Sentry from '@sentry/vue';

export default class {
    static initialized = false;

    static init(app) {
        if (this.initialized) {
            app.mixin(Sentry.createTracingMixins({}));
            Sentry.attachErrorHandler(app);
        } else {
            if (!import.meta.env.VITE_SENTRY_DSN_PUBLIC || !parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)) {
                console.warn('Sentry init: dsn or sample rate not set.');
            } else {
                console.info('Sentry init: dsn and sample rate set.');
            }

            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                integrations: [
                    Sentry.browserTracingIntegration(),
                ],
                tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 0,
                release: import.meta.env.VITE_SENTRY_RELEASE || null,
                environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'unknown',
            });

            this.initialized = true;
        }
    }
}
