import axios from 'axios';

export default {
    namespaced: true,
    state: {
        norms: [],
    },
    getters: {
        norms(state) {
            return state.norms;
        },
    },
    mutations: {
        norms(state, data) {
            state.norms = data;
        },
    },

    actions: {
        create({}, calculationToolId) {
            return axios.post('/api/calculation-tools/' + calculationToolId + '/result')
                .then((response) => response);
        },
        get({commit}, payload) {
            return axios.get('/api/calculation-tools/' + payload.calculationToolId + '/scoring-stats/' + payload.version ?? 'original')
                .then((response) => response.data);
        },
        getNorms({commit}, payload) {
            return axios.get('/api/calculation-tools/' + payload.calculationToolId + '/score/score-norms/' + payload.version ?? 'original')
                .then((response) => response.data)
                .then((response) => commit('norms', response.data));
        },
    }
};
