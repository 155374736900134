<template>
    <div class="page calculation-tools-list pt-10">
        <div class="container mx-auto">
            <div class="mx-auto bg-white rounded-xl shadow p-12">
                <h2 class="mb-10 text-center text-pso-dark text-2xl font-bold">
                    {{ translate('calculation_tool.list.title') }}
                </h2>

                <p class="text-pso-dark mb-10">
                    {{ translate('calculation_tool.list.index.paragraph_1') }}
                </p>

                <p class="text-pso-dark mb-10">
                    {{ translate('calculation_tool.list.index.paragraph_2') }}
                </p>

                <p class="text-pso-dark mb-10">
                    {{ translate('calculation_tool.list.index.paragraph_3') }}
                </p>

                <ul
                    v-if="Object.keys(calculationToolsList).length > 0"
                    class="calculation-tools-list list-none m-0 p-0"
                >
                    <li
                        v-for="(calculationTool, index) in calculationToolsList"
                        :key="index"
                        class="rounded-xl mb-10 p-8 bg-gray-050 hover:bg-blue-200"
                    >
                        <calculation-tool-item
                            :calculation-tool-prop="calculationTool"
                        />
                    </li>
                </ul>

                <new-calculation-tool-form />
            </div>
        </div>
    </div>
</template>

<script>
import CalculationTool from "../../../CalculationTool/CalculationTool";
import CalculationToolItem from "../../../CalculationTool/Parts/CalculationToolItem/Vue/CalculationToolItem.vue";
import NewCalculationToolForm from "../../Parts/NewCalculationToolForm/Vue/NewCalculationToolForm.vue";
import {mapGetters} from 'vuex';

export default {
    components: {
        CalculationToolItem,
        NewCalculationToolForm,
    },
    data() {
        return {
            calculationToolsList: [],
        };
    },

    computed: {
        ...mapGetters("calculation_tool", {receivedList: "calculation_tools_list"}),
    },

    watch: {
        receivedList() {
            this.calculationToolsList = this.receivedList;
        },
        calculationToolsList() {
            let calculationToolsCount = Object.keys(this.calculationToolsList).length;
            this.newCalculationToolFormOpen = calculationToolsCount === 0;
            this.newCalculationToolButtonIsShown = calculationToolsCount >= 1;
        }
    },

    mounted() {
        CalculationTool.getCalculationTools();

    },
};
</script>
