<template>
    <div class="page welcome">
        <div class="container mx-auto">
            <calculation-tool-auditor-header
                :in-calculation-tool-edit-prop="false"
            />
            <div class="max-w-[1108px]  mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <h2 class="text-center text-pso-dark text-2xl font-bold">
                    {{ translate('dashboard.welcome.welcome') }} {{ fullNameProp }}
                </h2>
                <p
                    class="text-pso-dark mt-10"
                    v-html="translate('dashboard.auditor.welcome.message_1', translateKeys)"
                />
                <p
                    class="text-pso-dark mt-0"
                    v-html="translate('dashboard.auditor.welcome.message_2')"
                />
                <div class="horizontal-timeline mt-10 relative max-w-[900px] m-auto flex justify-center">
                    <div class="w-1/6 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            1
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.organisation') }}
                        </span>
                    </div>
                    <div class="w-1/5 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            2
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.fill') }}
                        </span>
                    </div>
                    <div class="w-1/5 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            3
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.score') }}
                        </span>
                    </div>
                    <div class="w-1/5 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            4
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.checklist') }}
                        </span>
                    </div>
                    <div class="w-1/5 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            5
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 translate-x-[-50%] min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.application') }}
                        </span>
                    </div>
                    <div class="h-1 bg-blue-200 w-4/5 absolute z-0 top-6" />
                </div>
                <div class="mt-[100px] text-center">
                    <p>{{ translate('dashboard.auditor.welcome.view_original_or_edited') }}</p>
                    <div class="questions inline-flex mt-6 m-auto">
                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl mr-4"
                            :class="showOriginalChoice == '1' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="confirmationYes"
                                class="text-md text-black items-center mb-0 pt-3 pb-3 pl-3 pr-6 hover:cursor-pointer"
                            >
                                {{ translate('dashboard.auditor.welcome.view_original') }}
                            </label>
                            <input
                                id="confirmationYes"
                                v-model="showOriginalChoice"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                                name="confirmation"
                                value="1"
                                dusk="showOriginalChoice-yes"
                                @change="showDatePicker()"
                            >
                        </div>

                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl"
                            :class="showOriginalChoice == '0' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="confirmationNo"
                                class="text-md text-black items-center mb-0 pt-3 pb-3 pl-3 pr-20 hover:cursor-pointer"
                            >
                                {{ translate('dashboard.auditor.welcome.start_auditing') }}
                            </label>
                            <input
                                id="confirmationNo"
                                v-model="showOriginalChoice"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer"
                                name="confirmation"
                                value="0"
                                dusk="showOriginalChoice-no"
                                @change="showDatePicker()"
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-show="showOriginalChoice==='0'"
                    class="mt-6"
                >
                    <div class="w-[406px] m-auto">
                        {{ translate('dashboard.auditor.welcome.audit_date') }}

                        <Popper
                            :arrow="true"
                            placement="top"
                        >
                            <div class="ml-1">
                                <img
                                    :src="iconInfo"
                                    alt="info"
                                    class="hover:cursor-pointer"
                                >
                            </div>
                            <template #content>
                                {{ translate('dashboard.auditor.welcome.audit_date_message') }}
                            </template>
                        </Popper>

                        <div
                            class="mt-2"
                        >
                            <Datepicker
                                v-model="auditDate"
                                lang="nl"
                                locale="nl-NL"
                                format="dd-MM-yyyy"
                                :format-locale="nl"
                                :enable-time-picker="false"
                                :clearable="false"
                                :placeholder="translate('dashboard.auditor.welcome.audit_date_placeholder')"
                                :text-input="true"
                                dusk="btn-datepicker"
                                auto-apply
                                @update:modelValue="showDatePicker()"
                            >
                                <template #calendar-icon>
                                    <img
                                        :src="iconCalendar"
                                        alt="Calendar icon"
                                    >
                                </template>
                                <template #input-icon>
                                    <img
                                        :src="iconCalendar"
                                        alt="Calendar icon"
                                    >
                                </template>
                            </Datepicker>

                            <span
                                v-if="errors.audit_date"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.audit_date"
                            />
                        </div>
                    </div>
                </div>
                <div class="action-button text-center mt-10">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-blue-1000"
                        :class="viewOriginalOrEdited ? 'bg-blue-1000 cursor-pointer' : 'bg-grayish-blue cursor-default'"
                        :disabled="!viewOriginalOrEdited"
                        @click.prevent="stepOneRoute()"
                    >
                        {{ translate('dashboard.auditor.welcome.button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CalculationToolAuditorHeader from "../../../../CalculationTool/Parts/CalculationToolAuditorHeader/Vue/CalculationToolAuditorHeader.vue";
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import Organisation from "../../../../Organisation/Organisation";
import Popper from "vue3-popper";
import Datepicker from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css';
import {nl} from "date-fns/locale";
import CalculationToolData from "../../../../CalculationToolData/CalculationToolData";
import {mapGetters} from "vuex";
import dayjs from 'dayjs';

export default {
    components: {
        CalculationToolAuditorHeader,
        Popper,
        Datepicker
    },
    props: {
        fullNameProp: {
            type: String,
            default: ''
        },
        calculationToolIdProp: {
            type: Number,
            default: null,
        },
        organisationIdProp: {
            type: Number,
            default: null,
        },
    },
    setup() {
        return {
            nl,
        };
    },
    data() {
        return {
            translateKeys: {
                'faq': '<a class="text-blue-1000 underline hover:no-underline" href="/faq" target="_blank">' + this.translate('layout.faq') + '</a>',
                'contact': '<a class="text-blue-1000 underline hover:no-underline" href="/contact" target="_blank">' + this.translate('layout.contact') + '</a>'
            },
            errors: {},
            showOriginalChoice: null,
            viewOriginalOrEdited: null,
            auditDate: null,
            iconInfo: new URL('/resources/images/icon_info.svg', import.meta.url).href,
            iconCalendar: new URL('/resources/images/icon_calendar.svg', import.meta.url).href,
        };
    },
    computed: {
        ...mapGetters("calculation_tool", ["calculation_tool"])
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp).then(() => {
            this.auditDate = this.calculation_tool.audit_date;
        });
        CalculationToolData.get();
        Organisation.get(this.organisationIdProp);
    },
    methods: {
        stepOneRoute() {
            if(this.showOriginalChoice === '0') {
                if (this.auditDate) {
                    this.auditDate = dayjs(this.auditDate).format('YYYY-MM-DD HH:mm:ss');
                    CalculationTool.startAudit({id: this.calculationToolIdProp, 'audit_date': this.auditDate})
                        .then((response) => {
                            if (typeof response.data.data.name !== 'undefined') {
                                window.location = this.startAuditRoute();
                            } else {
                                throw new Error('Calculation Tool Data ID is missing');
                            }
                        })
                        .catch((error) => {
                            if (error) {
                                this.$setErrorsFromResponse(error.response.data);
                            }
                        });
                }
            } else {
                window.location = this.viewOriginalOrganisationSummary();
            }
        },
        viewOriginalOrganisationSummary() {
            return route('auditor.organisation.summary.original', this.calculationToolIdProp);
        },
        startAuditRoute() {
            return route('auditor.organisation.summary.edit', this.calculationToolIdProp);
        },
        showDatePicker() {
            this.viewOriginalOrEdited = null;
            if(this.showOriginalChoice === '0') {
                if(this.auditDate) {
                    this.viewOriginalOrEdited = true;
                }
            } else {
                this.viewOriginalOrEdited = true;
            }
        }
    }
};
</script>
<style>
.dp__theme_light {
    --dp-primary-color: #009bde;
    --dp-border-color: rgb(235 235 235);
    --dp-border-color-hover: rgb(235 235 235);
    --dp-success-color: #009bde;

}

.dp__input_icon {
    position: absolute;
    right: 22px;
    left: auto;
}

.dp__input {
    padding: 0.75rem 40px 0.75rem 1.5rem;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
}
</style>
