<template>
    <div class="max-w-[1108px]  mx-auto pt-5 pb-4">
        <span class="text-pso-dark font-medium text-base">
            {{ translate('organisation.current_calculation_tool') }} {{ calculation_tool.name }}
        </span>
        <a
            :href="step1Route()"
            class="float-right text-pso-primary text-md font-normal underline"
        >
            {{ translate('organisation.change_calculation_tool') }}
        </a>
    </div>
</template>

<script>

import CalculationTool from "../../../CalculationTool";
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters("calculation_tool", ["calculation_tool"]),
    },
    methods: {
        step1Route() {
            return route('dashboard');
        },
    },
};
</script>
