import {createStore} from 'vuex';
import user from './user';
import organisation from './organisation';
import calculation_tool from './calculation_tool';
import calculation_tool_data from './calculation_tool_data';
import target_group from './target_group';
import purchase from './purchase';
import checklist from './checklist'
import advisor from './advisor'
import auditor from './auditor'
import pso from './pso';
import score from './score'
import user_group from "./user_group";

const store = createStore({
    state: {
        translations: {},
    },
    getters: {
        translations(state) {
            return state.translations;
        },
    },
    mutations: {
        translations(state, translations) {
            state.translations = translations;
        },
    },
    modules: {
        user,
        organisation,
        calculation_tool,
        calculation_tool_data,
        target_group,
        purchase,
        checklist,
        advisor,
        auditor,
        pso,
        score,
        user_group,
    }
});

export default store;
