<template>
    <div
        v-if="visible"
        class="card flex justify-content-center"
    >
        <Dialog
            v-model:visible="visible"
            modal
            :header="header"
            :closable="closable"
            class="max-w-[35%]"
        >
            <p>
                {{ body }}
            </p>
            <template #footer>
                <div class="text-center action-button flex">
                    <a
                        v-for="button in buttons"
                        :key="button"

                        :href="button.redirect"
                        class="inline-block mx-auto mr-4 text-white font-medium bg-pso-orange py-4 px-10 rounded-xl w-1/2"
                        :class="button.style"
                        dusk="dialog-message-action-button"
                        @click="visible = false"
                    >
                        {{ button.text }}
                    </a>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
    components: {
        Dialog,
    },
    props: {
        data: {
            type: Object,
            default: function () {
                return {
                    header: null,
                    body: null,
                    buttons: [{
                        text: '',
                        redirect: '',
                        style: ''
                    }],
                    closable: false,
                }
            }
        },
    },
    data() {
        return {
            visible: false,
            header: null,
            body: null,
            buttons: [],
            closable: false,
        };
    },
    created() {
        window.mitt.on('dialogMessage', (data) => this.dialogMessage(data))
    },
    methods: {
        dialogMessage(data) {
            this.buttons = [];

            this.visible = true;
            this.header = data.header ?? '';
            this.body = data.body;

            data.buttons.forEach(button => {
                this.buttons.push({
                    text: button.text ?? this.translate('layout.modals.ok_button_text'),
                    redirect: button.redirect ?? "#",
                    style: button.style ?? null
                });
            });

            this.closable = data.closable ?? false;
        },
    }
}
</script>
