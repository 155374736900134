<template>
    <BaseInput
        :id="id"
        type="text"
        :errors="errors"
        class="form-input px-6 py-3 w-full text-pso-dark text-lg placeholder:lowercase placeholder:text-gray-600"
        :name="name"
        :placeholder="placeholder"
        :model-value="modelValue"

        @update:modelValue="(value) => $emit('update:modelValue', value)"
    />
</template>
<script>
import BaseInput from "../../BaseInput/Vue/BaseInput.vue";

export default {
    components: {BaseInput},
    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        errors: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null
        },
    },
    emits: {
        'update:modelValue': String
    }
}
</script>
