<template>
    <div class="additional-information">
        <h2 class="text-xl font-medium text-pso-dark mt-9 mb-4">
            {{ translate('organisation.summary.details.other.other_information') }}
        </h2>

        <div class="detail-info flex align-items-center mb-4">
            <div>
                <span class="text-pso-dark text-lg">
                    <span class="block text-md text-pso-dark font-light mb-1">
                        {{ translate('organisation.summary.details.other.email_address_pso_communication') }}
                    </span>
                    <TextInputWithAuditor
                        v-if="editing"
                        v-model:main-value="additionalInformationData.communications_email"
                        v-model:auditor-value="additionalInformationAuditor.communications_email"
                        auditor-name="communications_email_auditor"
                        main-name="communications_email"
                        auditor-id="communications_email_auditor"
                        main-id="communications_email"
                    />
                    <SpanWithAuditor
                        v-else
                        class="block mb-1"
                        :comment="additionalInformationAuditor?.communications_email"
                        :original="organisation.original"
                    >
                        {{ additionalInformationData?.communications_email ?? '-' }}
                    </SpanWithAuditor>
                </span>
            </div>
        </div>

        <div class="detail-info flex align-items-center mb-4">
            <div>
                <span class="text-pso-dark text-lg">
                    <span class="block text-md text-pso-dark font-light mb-1">
                        {{ translate('organisation.summary.details.other.second_contact_invitations') }}
                    </span>
                    <TextInputWithAuditor
                        v-if="editing"
                        v-model:main-value="additionalInformationData.invitations_email"
                        v-model:auditor-value="additionalInformationAuditor.invitations_email"
                        auditor-name="invitations_email_auditor"
                        main-name="invitations_email"
                        auditor-id="invitations_email_auditor"
                        main-id="invitations_email"
                    />
                    <SpanWithAuditor
                        v-else
                        class="block mb-1"
                        :comment="additionalInformationAuditor?.invitations_email"
                        :original="organisation.original"
                    >
                        {{ additionalInformationData?.invitations_email ?? '-' }}
                    </SpanWithAuditor>
                </span>
            </div>
        </div>

        <div class="detail-info flex align-items-center mb-4">
            <div>
                <span class="text-pso-dark text-lg">
                    <span class="block text-md text-pso-dark font-light mb-1">
                        {{ translate('organisation.summary.details.other.pso_advisor') }}
                    </span>
                    <span class="block mb-1 text-lg text-pso-dark">
                        {{ advisor.name ?? translate('application.summary.no_advisor') }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Advisor from '../../../../Advisor/Advisor';
import CalculationTool from "../../../../CalculationTool/CalculationTool";
import {mapGetters} from "vuex";
import TextInputWithAuditor from "../../../../FormInput/Auditor/TextInputWithAuditor/Vue/TextInputWithAuditor.vue";
import SpanWithAuditor from "../../../../../Inertia/Components/auditor/Partials/SpanWithAuditor.vue";

export default {
    components: {SpanWithAuditor, TextInputWithAuditor},
    inject: ['organisation','additionalInformationData', 'additionalInformationAuditor'],
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        },
        editing: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters("calculation_tool", { additionalInformation: "additional_information" }),
        ...mapGetters('advisor', ['advisor']),
    },
    watch: {
        additionalInformation() {
            if (this.additionalInformation !== null) {
                // Set current data
                this.additionalInformationData = this.additionalInformation.data ?? this.additionalInformation;
                this.additionalInformationAuditor = this.additionalInformation.auditor ?? {};

                // If this is the first time the data is being fetched, store the original values
                if (!this.originalAdditionalInformationData) {
                    this.originalAdditionalInformationData = JSON.parse(JSON.stringify(this.additionalInformationData));
                }
                if (!this.originalAdditionalInformationAuditor) {
                    this.originalAdditionalInformationAuditor = JSON.parse(JSON.stringify(this.additionalInformationAuditor));
                }

                // Show advisor if the advisor_id exists
                if (this.additionalInformation.advisor_id !== null) {
                    Advisor.show(this.additionalInformation.advisor_id);
                }
            }
        },
    },
    created() {
        CalculationTool.getAdditionalInformation(this.calculationToolIdProp);
        window.mitt.on('reset-changes', () => {
            this.additionalInformationData = { ...this.originalAdditionalInformationData };
            this.additionalInformationAuditor = { ...this.originalAdditionalInformationAuditor };
            console.log('reset-changes', { ...this.originalAdditionalInformationData })
        });
        window.mitt.on('saved-changes', () => {
            this.originalAdditionalInformationData = { ...this.additionalInformationData };
            this.originalAdditionalInformationAuditor  = { ...this.additionalInformationAuditor };
        });
    },
};
</script>
