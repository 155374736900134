<template>
    <div class="non-aspirant-intro">
        <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
            {{ translate('checklist.non-aspirant.intro.title') }}
        </h2>
        <p
            class="text-pso-dark mt-10"
        >
            {{ translate('checklist.non-aspirant.intro.paragraph') }}
        </p>
        <div class="action-button text-center mt-20">
            <a
                :href="nextPageUrl()"
                class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                dusk="intro-non-aspirant-button"
            >
                {{ translate('checklist.non-aspirant.intro.button') }}
            </a>
        </div>
    </div>
</template>
<script>

import CalculationTool from '../../../CalculationTool/CalculationTool';
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters("calculation_tool", ["calculation_tool"]),
    },
    methods: {
        nextPageUrl() {
            return route('calculation-tool-checklist.form', { calculationTool: this.calculation_tool.id });
        },
    },
};
</script>
